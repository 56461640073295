import Index from './container/home/';
import React, {useEffect} from 'react';
import About from './container/about/';
import Career from './container/career/';
import CareerApply from './container/career/apply';
import Concern from './container/concern';
import Construction from './container/services/construction';
import Furniture from './container/services/furniture';
import Interior from './container/services/interior';
import Consultancy from './container/services/consultancy';
import StyledGlobal from './globalStyle';
import Landowners from './container/relations/landowners';
import Buyers from './container/relations/buyers';
import Footer from './components/Footer1';
import Contact from './container/contact';
import News from './container/news';
import Error from './container/error';
import {Route, Switch,useLocation} from 'react-router-dom';
import {AnimatePresence } from 'framer-motion';
import Menu from "./components/OpenedMenu";
import Projects from './container/projects';
import ProjectDetail from './container/projects/ProjectDetail';
import newsDetail from './container/news/details';
import Partners from './container/partners';



function App() {
    const location = useLocation()
    return (
        <React.Fragment>
            <StyledGlobal/>
            <Menu />

            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname} >
                    <Route exact path="/" component={Index}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/concern" component={Concern}/>
                    <Route exact path="/projects" component={Projects}/>
                    <Route exact path="/projects/:slug" component={Projects}/>
                    <Route exact path="/projects/:slug/:project_slug" component={ProjectDetail}/>
                    <Route exact path="/career" component={Career}/>
                    <Route exact path="/career/apply/:slug" component={CareerApply}/>
                    <Route exact path="/construction" component={Construction}/>
                    <Route exact path="/interior" component={Interior}/>
                    <Route exact path="/furniture" component={Furniture}/>
                    <Route exact path="/landowner" component={Landowners}/>
                    <Route exact path="/buyers" component={Buyers}/>
                    <Route exact path="/partners" component={Partners}/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route exact path="/news" component={News}/>
                    <Route exact path="/news/:slug" component={newsDetail}/>
                    <Route component={Error}/>
                </Switch>
            </AnimatePresence>
            <Footer/>
        </React.Fragment>
    );
}


export default App;
