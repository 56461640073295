import React, {Component, useEffect, useState} from 'react';
import {Container, Row, Col, Form} from "react-bootstrap";
import styled from 'styled-components';
import imgs from '../../../assets/images/beside-map-image.jpg';
import SubTitle from '../../../components/SubTitle';
import Select from 'react-select';
import ButtonSubmit from '../../../components/ButtonSubmit';
import $ from "jquery";
import {connect} from "react-redux";
import {ApiParams} from "../../../constants/apiParams";
import Message from '../../../components/PopupMessage';
import {fetchFormData, postFormSubmit} from '../../../redux/actions/form';
import {emailValidation, emptyValidation} from "../../../config/validator";
import {Loading} from "../../../components/Loading";


const options = [
    {value: 'Construction', label: 'Construction'},
    {value: 'Consultancy', label: 'Design consultancy'},
    {value: 'Furniture', label: 'Furniture'},
    {value: 'Interior', label: 'Interior design'}
];


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? '#41A843' : '#FFF',
        margin: 0
    }),
    menu: (provided, state) => ({
        ...provided,
        color: 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin: 0
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
    }),

};


const ContactForm = (props) => {

    useEffect(() => {
        let getOffset = $('.container').offset().left + 15;
        if ($(window).width() > 992) {
            setTimeout(function () {
                $('.contact-from-left').css('padding-left', getOffset);
            })
        }


        let mainParam = {
            [ApiParams.form_slug]: 'services-from'

        };
        props.getFormData(mainParam);


    }, []);


    let {formFields, submitForm, formSubmitErr, popup} = props;
    let [state, setState] = useState({
        name: '',
        number: '',
        email: '',
        address: '',
        service: '',
        message: '',
    });

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    };

    let handleSelect = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }

    let handleInit = () => {
        setState({
            name: '',
            number: '',
            email: '',
            address: '',
            service: '',
            message: '',
        })
    }


    let handleSubmit = (e) => {
        e.preventDefault()

        e.preventDefault()
        let formField = {
            form_id: 'services-from'
        }
        let len = props.formFields.length;

        for (let i = 0; i < len; i++) {
            formField[props.formFields[i]["field_key"]] = ""
        }

        formField["name"] = state.name;
        formField["number"] = state.number;
        formField["email"] = state.email;
        formField["address"] = state.address;
        formField["service"] = state.service;
        formField["message"] = state.message;
        formField["spam_protector"] = '';

        let bodyFormData = new FormData();

        for (let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        props.postFormSubmit(bodyFormData);
        //postFormSubmit(bodyFormData);
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleEmptyNum();
        handleLoaderBeforeSubmit();

        console.log(state)

    };

    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let handleEmptyNum = () => {
        let isEmptyNum = emptyValidation(state.number);
        isEmptyNum ? setEmptyNum(true) : setEmptyNum(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };

    return (
        <StyledContactForm>
            {!popup ? loading ? <Loading/> : '' : null}
            <Message/>

            <Container fluid>
                <Row>
                    <Col md={6} className='contact-from-left '>
                        <SubTitle text={'keep talking'} margin={'0 0 40px 0'}/>
                        <Form onSubmit={handleSubmit}>


                            <Form.Group>
                                <Form.Control className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                              value={state.name} onChange={handleChange} name='name' type="text"
                                              placeholder="Full name"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Control className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                              value={state.number} onChange={handleChange} name='number' type="number"
                                              placeholder="Phone number"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Control className={!emailErr ? 'has-error' : ''} onBlur={handleEmailBlur}
                                              value={state.email} onChange={handleChange} name='email' type="email"
                                              placeholder="Email address"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Control value={state.address} onChange={handleChange} name='address' type="text"
                                              placeholder="Address"/>
                            </Form.Group>

                            <Form.Group>
                                <Select onChange={op => handleSelect('service', op.value)} styles={customStyles}
                                        placeholder='Select service' options={options}/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Control value={state.message} onChange={handleChange} name='message'
                                              placeholder='Message' as="textarea" rows={1}/>
                            </Form.Group>

                            <ButtonSubmit onSubmit={handleSubmit} border={'1px solid rgba(26,24,24,0.75)'}
                                          color={'rgba(26,24,24,0.75)'}
                                          margin={'0'} text='submit'/>

                        </Form>
                    </Col>

                    <Col md={6} className='contact-from-right p-0'>
                        <div className="contact-from-right__inner">
                            <img src={imgs} alt=""/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledContactForm>
    )
};
const StyledContactForm = styled.section`
  .contact-from-left{
    padding-right: 85px;
    padding-left: 35px;
    background-color: #F8F8F8;
    padding-top: 95px;
    padding-bottom: 95px;
    .inline{
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      .select-here{
        width: calc(50% - 15px);
      }
    }
    .form-group{
      margin-bottom: 30px !important;
    }
    .form-control{
      height: 50px;
      line-height: 53px;
      color: #1a1818;
      font-size: 14px;
      font-weight: 300;
      border-radius: 0;
      border-color:rgba(26,24,24,0.75);
      background-color: transparent;
      padding-left: 20px;
      &::placeholder{
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        color: rgba(26,24,24,0.75);
      }
    }
      textarea{
      height: 50px;
        min-height: 50px !important;
        max-width:100%;
        padding-top: 15px;
        line-height: 21px !important;
      }
  }
  .contact-from-right{
  &__inner{
    height: 100%;
  }
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  

  
  .css-yk16xz-control{
    background-color: transparent !important;
    border-color:rgba(26,24,24,0.75) !important;
    .css-1uccc91-singleValue{
      color:rgba(26,24,24,0.75) !important;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .css-yk16xz-control .css-1wa3eu0-placeholder{
    font-size: 14px !important;
    font-weight: 300 !important;
    color: rgba(26,24,24,0.75) !important;
   
  }
  
  @media(max-width:768px){
    .contact-from-left{
      min-width:100%;
      padding-right: 35px;
    }
  }
  
  @media(max-width:767px){
  .row{
    flex-direction: row-reverse;
  }
  .sub-title{
    text-align: center;
  }
    .contact-from-left{
     padding-left: 15px;
     padding-right: 15px;
     padding-top: 50px;
     padding-bottom: 50px;
    }
    .contact-from-left .form-group{
    margin-bottom: 16px !important;
  }
  }
  
`;

function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);


