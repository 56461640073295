import React, {useRef} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Title from "../../../components/Title";
import ReactHtmlParser from "react-html-parser";
import Swiper from 'react-id-swiper';
import "swiper/swiper.scss";
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";
import Text from "../../../components/Text";
import styled from "styled-components";

const RelatedNews = ({data}) => {
    const containerRef = useRef(null);

    const swiperRef = useRef(null);
    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };
    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 500,
        // centeredSlides: true,
        // loop: true,
        // containerClass: `abc`,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },

        breakpoints: {

            768: {
                slidesPerView: 3,
                spaceBetween: 30,
                speed: 500,
                // centeredSlides: true,
                // loop: true,
                // containerClass: `abc`,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            }
        }
    };

    let arrowOffset = containerRef?.current?.offsetLeft + 15;
    return (
        <StyledNews className="homeNews" containerOffset={arrowOffset}>
            <Container ref={containerRef}>
                <Row>
                    <Col sm={12} className="relative_wrap">
                        <Title fontSize={60} fontWeight={300} fontFamily={"'DIN Next LT Pro', sans-serif"}
                               textTransform={'uppercase'} color={'#000000'}
                               lineHeight={60} letterSpacing={0.25} margin={'0 0 10px 0'}>
                            {ReactHtmlParser('Related News')}
                        </Title>
                        <div className="Slider__Nav">
                            <button className="slidePrev" onClick={goPrev}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="22.829"
                                     viewBox="0 0 42 22.829">
                                    <g id="Component_17_32" data-name="Component 17 – 32"
                                       transform="translate(1 1.414)">
                                        <line id="Line_55" data-name="Line 55" x1="40"
                                              transform="translate(40 10) rotate(180)" fill="none" stroke="#000000"
                                              stroke-linecap="round" stroke-width="2"/>
                                        <path id="Path_1164" data-name="Path 1164" d="M28.965,0l10,10-10,10"
                                              transform="translate(38.965 20) rotate(180)"
                                              fill="none" stroke="#000000" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </button>
                            <button className="slideNext" onClick={goNext}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="22.829"
                                     viewBox="0 0 42 22.829">
                                    <g id="Component_17_33" data-name="Component 17 – 33"
                                       transform="translate(1 1.414)">
                                        <line id="Line_55" data-name="Line 55" x1="40" transform="translate(0 10)"
                                              fill="none" stroke="#000000"
                                              stroke-linecap="round" stroke-width="2"/>
                                        <path id="Path_1164" data-name="Path 1164" d="M28.965,0l10,10-10,10"
                                              transform="translate(1.035)" fill="none"
                                              stroke="#000000" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"/>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div>
                <Row>
                    <Col sm={12} className="newsSliderCol">
                        <div className="newsSlider">
                            {data.length ?
                                <Swiper {...params} ref={swiperRef}>
                                    {data ? data.map((item, key) => {
                                        let thumbImage = item?.images?.list ? item.images.list.find(e => e.is_thumb === 'on') : '';
                                        return (
                                            <div key={key}>
                                                <div className="Slider__Single">
                                                    <a href="#">
                                                        {thumbImage ?
                                                            <div className="Slider__Single__img">
                                                                <img
                                                                    src={baseUrl + thumbImage.path + thumbImage.img_name}
                                                                    alt=""/>
                                                            </div>
                                                            :
                                                            <div className="Slider__Single__img">
                                                                <img src={BlurImg} alt=""/>
                                                            </div>
                                                        }
                                                        <div className="Slider__Single__title">
                                                            <Text fontSize={'12'}
                                                                  fontWeight={'500'} lineHeight={'18'}
                                                                  textTransform={'uppercase'} letterSpacing={'3.6'}
                                                                  color={'#342664'}
                                                            >
                                                                <p>{ReactHtmlParser(item?.page_data?.sub_title)}</p>
                                                            </Text>
                                                            <p>{ReactHtmlParser(item?.page_data?.short_desc)}</p>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    }) : ''
                                    }
                                </Swiper>
                                : ''
                            }

                        </div>
                    </Col>
                </Row>
            </div>
        </StyledNews>
    );
};
const StyledNews = styled.section`
  &.homeNews {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
    background-color: #F8F8F8;
    .relative_wrap{
      position: relative;
      h2{
        margin-bottom: 50px;
      }
    }
    .newsSliderCol {
      padding-right: 0;
    }

    .newsSlider {
      .swiper-container-initialized {
        padding-left: ${props => props.containerOffset || 170}px;
        padding-right: ${props => props.containerOffset || 170}px;
      }
      .Slider__Single {
        &__img {
          padding-top: calc(470 / 750 * 100%);
          position: relative;
          overflow: hidden;
          > img{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &__logo {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;

            > img {
              width: 165px;
              height: 50px;
            }
          }
        }

        &__title {
          margin-top: 20px;
          position: relative;
          display: block;

          .texts {
            font-family: 'Poppins', sans-serif;
          }

          p {
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
          }
        }
      }
    }
    .Slider__Nav {
      text-align: right;
      position: absolute;
      bottom: 15px;
      right: 10px;
      button {
        border: none;
        background-color: transparent;
        width: 30px;
        height: 12px;
        padding: 0;

        &.slidePrev {
          margin-right: 6px;
        }

        &.slideNext {
          margin-left: 6px;
        }

        svg {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
    
  }

  @media (min-width: 991px) and (max-width: 1300px) {
  }
  @media (max-width: 990px) {
  }

  @media(max-width: 767px){
    .swiper-slide{
      padding-right: 15px;
    }
  }
`;

export default RelatedNews;