export const HOME = {
  FETCH_HOME: {
    MAIN: 'FETCH_HOME',
    SUCCESS: 'FETCH_HOME_SUCCESS',
    FAILURE: 'FETCH_HOME_FAILURE',
  },
  FETCH_FEATURED_PROJECT: {
    MAIN: 'FETCH_FEATURED_PROJECT',
    SUCCESS: 'FETCH_FEATURED_PROJECT_SUCCESS',
    FAILURE: 'FETCH_FEATURED_PROJECT_FAILURE',
  },
  FETCH_HOME_NEWS: {
    MAIN: 'FETCH_HOME_NEWS',
    SUCCESS: 'FETCH_HOME_NEWS_SUCCESS',
    FAILURE: 'FETCH_HOME_NEWS_FAILURE',
  },
};
