import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import ReactHtmlParser from "react-html-parser";
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import {LightgalleryItem, LightgalleryProvider,} from "react-lightgallery";

import Title from "../../../components/Title";
import {baseUrl} from "../../../components/BaseUrl";
import "lightgallery.js/dist/css/lightgallery.css"


const PhotoItem = ({image, thumb, group}) => (
    <LightgalleryItem group={group} src={image} thumb={thumb}>
        <img src={image} alt='' style={{width: "100%"}}/>
    </LightgalleryItem>

);

const DetailGallery = ({post}) => {
    const containerRef = useRef();

    const swiperRef = useRef(null);
    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };
    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 500,
        // centeredSlides: true,
        // loop: true,
        // containerClass: `abc`,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        // autoplay: {
        //     disableOnInteraction: true
        // },
        // loop: true,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
                speed: 500,
                // centeredSlides: true,
                // loop: true,
                // containerClass: `abc`,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            }
        }
    };
    useEffect(() =>{
        let getOffset = containerRef?.current?.offsetLeft + 15;
        swiperRef.current.style.paddingLeft = getOffset + `px`;
    }, [swiperRef])

    useEffect(() => {
        document.querySelector('.swiper-container').style.paddingLeft = containerRef.current.offsetLeft + 15 + 'px';
    }, [swiperRef])


    // let getOffset = containerRef?.current?.offsetLeft + 15;
    return (
        <StyledComponent className="projectGallery">
            <Container ref={containerRef}>
                <Row>
                    <Col sm={12} className="relative_wrap">
                        <Title fontSize={60} fontWeight={300} fontFamily={"'DIN Next LT Pro', sans-serif"}
                               textTransform={'uppercase'} color={'#000000'}
                               lineHeight={60} letterSpacing={0.25} margin={'0 0 10px 0'}>
                            {ReactHtmlParser('Gallery')}
                        </Title>
                        <div className="Slider__Nav">
                            <button className="slidePrev" onClick={goPrev}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="22.829"
                                     viewBox="0 0 42 22.829">
                                    <g id="Component_17_32" data-name="Component 17 – 32"
                                       transform="translate(1 1.414)">
                                        <line id="Line_55" data-name="Line 55" x1="40"
                                              transform="translate(40 10) rotate(180)" fill="none" stroke="#000000"
                                              stroke-linecap="round" stroke-width="2"/>
                                        <path id="Path_1164" data-name="Path 1164" d="M28.965,0l10,10-10,10"
                                              transform="translate(38.965 20) rotate(180)"
                                              fill="none" stroke="#000000" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </button>
                            <button className="slideNext" onClick={goNext}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="22.829"
                                     viewBox="0 0 42 22.829">
                                    <g id="Component_17_33" data-name="Component 17 – 33"
                                       transform="translate(1 1.414)">
                                        <line id="Line_55" data-name="Line 55" x1="40" transform="translate(0 10)"
                                              fill="none" stroke="#000000"
                                              stroke-linecap="round" stroke-width="2"/>
                                        <path id="Path_1164" data-name="Path 1164" d="M28.965,0l10,10-10,10"
                                              transform="translate(1.035)" fill="none"
                                              stroke="#000000" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"/>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div>
                <div className="projectGallerySliderCol">
                    <div className="projectGallerySlider">
                        {post?.images ?
                            <LightgalleryProvider
                                lightgallerySettings={
                                    {
                                        download: false,
                                        thumbnail: false,
                                        fullScreen: false,
                                        share: false
                                    }
                                    }
                                >
                                    <Swiper {...params} ref={swiperRef}>
                                        {post.images.map((item, key) => (
                                            <div key={key}>
                                                <div className="Slider__Single">
                                                    <div className="Slider__Single__img">
                                                        <PhotoItem image={baseUrl + item.path + item.img_name}
                                                                   group="group1"/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </Swiper>
                            </LightgalleryProvider>
                            : ''
                        }
                    </div>
                </div>

            </div>
        </StyledComponent>
    )
}

const StyledComponent = styled.section`
  &.projectGallery {
    padding-top: 100px !important;
    padding-bottom: 100px !important;

    .relative_wrap {
      position: relative;

      h2 {
        margin: 0 0 50px 0;
      }
    }

    .swiper-container-initialized {

    }

    .projectGallerySlider {
      .swiper-container {
      }

      .Slider__Single {
        &__img {
          padding-top: calc(470 / 750 * 100%);
          position: relative;
          overflow: hidden;

          .react_lightgallery_item {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }

      }
    }

    .Slider__Nav {
      text-align: right;
      position: absolute;
      right: 10px;
      bottom: 20px;
      button {
        border: none;
        background-color: transparent;
        width: 30px;
        height: 12px;
        padding: 0;

        &.slidePrev {
          margin-right: 6px;
        }

        &.slideNext {
          margin-left: 6px;
        }

        svg {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }


  @media (min-width: 991px) and (max-width: 1300px) {
  }
  
  @media (max-width: 990px) {
  }

  @media (max-width: 767px) {
    .projectGallerySliderCol{
      padding-right: 15px;
    }
  }

`;

export default DetailGallery;

