import React, {Component} from "react";
import {Col} from 'react-bootstrap';
import Slider from "react-slick";
import styled from 'styled-components';
import projectImg1 from '../assets/images/dynamic/project-img1.jpg';
import {hover, fontDin} from "../globalStyleVars";
import arrowLeft from "../assets/images/static/arrow-left.svg";
import arrowRight from "../assets/images/static/arrow-right.svg";

class SlickSlider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const arrowOffset = this.props.arrowOffset;
        const arrowColor = this.props.arrowColor ? this.props.arrowColor : '#000000';
        let settings = {
            dots: this.props?.dots ? this.props.dots : false,
            infinite: this.props?.infinite ? this.props.infinite : true,
            speed: this.props?.speed ? this.props.speed : 300,
            slidesToShow: this.props?.slideToShow ? this.props.slideToShow : 3,
            slidesToScroll: this.props?.slidesToScroll ? this.props.slidesToScroll : 3,
            arrows: false,
        };
        return (
            <StyledSlickSlider className="SlickSliderWrapper"
                               arrowOffset ={arrowOffset}>
                <Slider ref={c => (this.slider = c)} {...settings}>
                    {this.props.children}
                </Slider>
                <div className="SlickSliderCustomNav">
                    <span className="customSlickPrev" onClick={this.previous}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="22.829" viewBox="0 0 42 22.829">
                          <g id="Component_17_32" data-name="Component 17 – 32" transform="translate(1 1.414)">
                            <line id="Line_55" data-name="Line 55" x1="40" transform="translate(40 10) rotate(180)" fill="none" stroke={arrowColor}
                                  stroke-linecap="round" stroke-width="2"/>
                            <path id="Path_1164" data-name="Path 1164" d="M28.965,0l10,10-10,10" transform="translate(38.965 20) rotate(180)"
                                  fill="none"stroke={arrowColor} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                          </g>
                        </svg>
                    </span>
                    <span className="customSlickNext" onClick={this.next}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="22.829" viewBox="0 0 42 22.829">
                          <g id="Component_17_33" data-name="Component 17 – 33" transform="translate(1 1.414)">
                            <line id="Line_55" data-name="Line 55" x1="40" transform="translate(0 10)" fill="none" stroke={arrowColor}
                                  stroke-linecap="round" stroke-width="2"/>
                            <path id="Path_1164" data-name="Path 1164" d="M28.965,0l10,10-10,10" transform="translate(1.035)" fill="none"
                                  stroke={arrowColor} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                          </g>
                        </svg>
                    </span>
                </div>

            </StyledSlickSlider>
        );
    }
}

const StyledSlickSlider = styled.div`
  &.SlickSliderWrapper {
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 60px;
    position: relative;

    .slick-slider {
      width: 140%;
    }

    .SlickSlider__Single {
      &__img {
        padding-top: calc(500 / 370 * 100%);
        position: relative;
        overflow: hidden;

        > img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: transform 2s;
        }
      }

      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;

        h4 {
          margin-bottom: 0;
          font-family: ${fontDin};
          font-weight: lighter;
          font-size: 32px;
          text-transform: uppercase;
        }

        span {
          width: 50px;
          height: 50px;
          background-color: ${hover};
          color: #000000;
          font-size: 35px;
          font-weight: lighter;
          text-align: center;
          opacity: 0;
          transition: opacity .5s ease-in;
        }
      }

      a {
        &:hover {
          .SlickSlider__Single__img {
            img {
              transform: scale(1.1);
            }
          }

          .SlickSlider__Single__title {
            span {
              opacity: 1;
              color: #000000;
            }
          }
        }
      }
    }

    .slick-arrow {
      position: absolute;
      top: -30px;
      left: auto;
      width: 25px;
      height: 20px;
      z-index: 1;

      &:before {
        display: list-item;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.slick-prev {
        right: 60px;

        &:before {
          content: '';
          background-image: url('${arrowLeft}');
        }
      }

      &.slick-next {
        right: 15px;

        &:before {
          content: '';
          background-image: url('${arrowRight}');
        }
      }
    }

    .SlickSliderCustomNav {
      position: absolute;
      right: ${props => props.arrowOffset || 0}px;
      top: -50px;
      .customSlickPrev, .customSlickNext{
        cursor: pointer;
        width: 35px;
        display: inline-block;
        svg{
          width: 100%;
        }
      }
      .customSlickPrev{
        padding-right: 15px;
      }
      .customSlickNext{
        padding-left: 15px;
      }
    }
  }

`;
export default SlickSlider;