import React from 'react';
import styled from 'styled-components';
import {Col, Container} from 'react-bootstrap';
import SubTitle from "../../../components/SubTitle";
import Title from "../../../components/Title";
import ReactHtmlParser from "react-html-parser";
import Text from "../../../components/Text";
import Slider from "react-slick";
import QuoteLeft from "../../../assets/images/quote-left.svg";
import QuoteRight from "../../../assets/images/quote-right.svg";


const DetailTestimonial = ({post}) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                }
            }
        ]
    };


    return (
        <StyledComponent className="projectTestimonial" QuoteLeft={QuoteLeft} QuoteRight={QuoteRight}>
            <Container>
                <div className="projectTestimonialWrapper">
                    <Title text="Testimonial"/>
                    {
                        post && post.length ?
                            <Slider {...settings}>
                                {
                                    post.map((item, key) =>
                                        <Col key={key}>
                                            <div className="SlickSlider__Single">
                                                <SubTitle
                                                    text={ReactHtmlParser(item?.data.description)}
                                                    color={'#000000'}
                                                    fontSize={'32'}
                                                    fontWeight={'300'}
                                                    lineHeight={'48'}
                                                    margin={'0 0 60px 0'}
                                                />
                                                <div className="person">
                                                    <p className="name">{item?.data.extra_field_1}</p>
                                                    <p className="designation">{item?.data.extra_field_2}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }
                            </Slider>
                            : ''
                    }
                </div>
            </Container>
        </StyledComponent>
    )
}

const StyledComponent = styled.section`
  padding-top: 100px;
  padding-bottom: 100px;

  &.projectTestimonial {
    .projectTestimonialWrapper {
      width: 55%;
      margin: 0 auto;
      text-align: center;

      .title {
        font-family: "Poppins", SansSerif;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 3.2px;
        font-weight: 500;
        text-transform: uppercase;
      }

      .sub-title {
        position: relative;

        &:before {
          position: absolute;
          content: "";
          left: -30px;
          top: -20px;
          width: 40px;
          height: 30px;
          background-image: url(${props => props.QuoteLeft || ''});
        }

        &:after {
          position: absolute;
          content: "";
          right: -30px;
          bottom: -20px;
          width: 40px;
          height: 30px;
          background-image: url(${props => props.QuoteRight || ''});
        }
      }

      .SlickSliderWrapper {
        margin-top: 40px;

        .slick-slider {
          width: 100%;

          .SlickSlider__Single {
            padding: 20px 30px;

            .person {
              font-family: "Poppins", SansSerif;

              .name {
                font-size: 18px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 3.2px;
                text-transform: uppercase;
                color: #000000;
                margin-bottom: 5px;
              }

              .designation {
                font-size: 16px;
                font-weight: 300;
                line-height: 20px;
                color: #000000;
              }
            }
          }

          .slick-dots {
            li {
              &.slick-active {
                button {
                  &:after {
                    position: absolute;
                    content: "";
                    border: 1px solid #000000;
                    border-radius: 50%;
                    width: 17px;
                    height: 17px;
                    left: 0;
                    right: 0;
                    top: 1px;
                    margin: 0 auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  @media (min-width: 991px) and (max-width: 1300px) {
  }
  @media (max-width: 990px) {
  }

  @media(max-width: 767px){
    &.projectTestimonial{
      padding-top: 75px !important;
      padding-bottom: 75px !important;
    }
    .projectTestimonialWrapper{
      width: 100% !important;
    }
  }
`;

export default DetailTestimonial;

