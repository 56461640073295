import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover, darkGreen} from '../globalStyleVars';
import mainLogo from '../assets/images/static/logo-white.svg';
import hambuerger from '../assets/images/menu-hambuerger.svg';
import hambuergerWhite from '../assets/images/close.svg';
import {Link, useLocation} from 'react-router-dom';
import $ from 'jquery';
import {setSectionId} from "../redux/actions/menu";
import {connect} from 'react-redux'
import menuBg from '../assets/images/menu-bg.jpg'
import caret from "../assets/images/caret-down.svg";

const OpenedMenu = ({logo, color, img, setSectionId}) => {
    let [menuItems, setMenuItems] = useState('false');
    let [newClass, setNewClass] = useState('');
    let [mobileSlide, setMobileSlide] = useState('');

    let handleMenuToggle = (e) => {
        e.stopPropagation();
        setMenuItems('toggled');
    };

    let handleClose = () => {
        setMenuItems('');
    };

    let {pathname} = useLocation();


    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 100) {
                // setNewClass('menu-fixed')
                document.querySelector(".menu-bar").classList.add('menu-fixed')
            } else {
                // setNewClass('menu')
                document.querySelector(".menu-bar").classList.remove('menu-fixed')
            }
        });
        if (window.screen.width < 767) {
            // menu click on mobile
            $('.mobile-menu .menuItems__top__single').each(function () {
                let $this = $(this);
                if ($this.find('ul').length > 0) {
                    $this.addClass('hasChild')
                }
            });

            $('.hasChild').on('click', function () {
                var $this_ul = $(this).find('ul'),
                    $this = $(this);
                $('.hasChild ul').not($this_ul).slideUp();
                $('.hasChild').not($this).removeClass('active');
                $this_ul.slideToggle();
                $this.toggleClass('active');
            });

            $('.mobile-menu .menuItems__top__single li a').click(function () {
                $('.menu-items').removeClass('true');
                $('.menu-bar').removeClass('toggled');
            });
            $('body').prepend("<div class='Overlay'></div>");

            $('.menu-bar__menu-lists a').on('click', function () {
                $('.toggle-slide-menu').toggleClass('active');
                if ($('.Overlay').hasClass('show')) {
                    $('.Overlay').removeClass('show')
                } else {
                    $('.Overlay').addClass('show')
                }

                $('.menu-bar').toggleClass('toggled');
            });

            $('.toggle-slide-menu a').click(function () {
                $('.toggle-slide-menu').removeClass('active')
                $('.menu-bar').removeClass('toggled');
                $('.Overlay').removeClass('show')
            });

            $('.Overlay').click(function () {
                $('.Overlay').removeClass('show')
                $('.menu-bar').removeClass('toggled');
                $('.toggle-slide-menu').removeClass('active')
            })
        }


    }, []);

    return (
        <div>
            <StyledMenu className={`menu-bar ${newClass} ${menuItems}`} color={color}>
                <Container>
                    <Row>
                        <Col md={3} sm={6} className="menu-bar__logo">
                            <Link to="/"><img src={logo || mainLogo} alt=""/></Link>
                        </Col>
                        {/*<Col className="menu-bar__logo"><a href="/"><img src={logo || mainLogo} alt=""/></a> </Col>*/}
                        <Col md={9} sm={6} className="menu-bar__menu-lists">
                            <ul onClick={handleClose}>
                                <li><Link className={pathname === '/about' ? 'active' : ''} to="/about">About us</Link>
                                </li>
                                <li><Link className={pathname === '/concern' ? 'active' : ''}
                                          to="/concern">Concern</Link></li>
                                <li><Link className={pathname === '/projects' ? 'active' : ''}
                                          to="/projects">Projects</Link></li>
                                <li><Link className={pathname === '/landowner' ? 'active' : ''}
                                          to="/landowner">Landowner</Link></li>
                                <li><Link className={pathname === '/news-events' ? 'active' : ''} to="/news">News &
                                    Events</Link></li>
                                <li><Link className={pathname === '/career' ? 'active' : ''} to="/career">Career</Link>
                                </li>
                                <li><Link className={pathname === '/contact' ? 'active' : ''}
                                          to="/contact">Contact</Link></li>
                                <li onClick={handleMenuToggle}>
                                    <a href="javascript:void(0)">
                                        <img src={img || hambuerger} alt=""/>
                                        <img src={hambuergerWhite} alt=""/>
                                    </a>
                                </li>
                            </ul>
                            <div className="hamburger" onClick={handleMenuToggle}>
                                <a href="javascript:void(0)">
                                    <img src={img || hambuerger} alt=""/>
                                    <img src={hambuergerWhite} alt=""/>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledMenu>


            <StyleToggleMenu className={'toggle-slide-menu'}>
                <div className="toggle-slide-menu">
                    <ul>
                        <li className={pathname === '/' ? 'active' : ''} onClick={handleClose}><Link
                            to={'/'}>Home</Link></li>
                        <li className={pathname === '/about' ? 'active' : ''} onClick={handleClose}><Link to={'/about'}>About
                            us</Link></li>
                        <li className={pathname === '/concern' ? 'active' : ''} onClick={handleClose}><Link to={'/concern'}>Concern</Link></li>
                        <li className={pathname === '/projects/ongoing' ? 'active' : ''} onClick={handleClose}><Link to={'/projects/ongoing'}>Projects</Link></li>
                        <li className={pathname === '/landowner' ? 'active' : ''} onClick={handleClose}><Link to={'/landowner'}>Landowner</Link></li>
                        <li className={pathname === '/news' ? 'active' : ''} onClick={handleClose}><Link to={'/news'}>News & Events</Link></li>
                        <li className={pathname === '/career' ? 'active' : ''} onClick={handleClose}><Link to={'/career'}>Career</Link></li>
                        <li className={pathname === '/contact' ? 'active' : ''} onClick={handleClose}><Link to={'/contact'}>Contact</Link></li>

                    </ul>
                </div>
            </StyleToggleMenu>


        </div>
    )
};
const StyledMenu = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  height: 90px;
  align-items: center;
  display: flex;
  transition: all .6s ease;
  z-index: 99;

  &.toggled {
    &.menu-fixed {
      //background-color: transparent;
    }

    .menu-bar__logo {
      //opacity: 0;
    }

    .hamburger {
      a {
        img {

        }

        img:first-child {
          opacity: 0 !important;
          visibility: hidden !important;
          display: none !important;
        }

        img:last-child {
          opacity: 1 !important;
          visibility: visible !important;
          display: block !important;
        }
      }
    }
  }

  .row {
    align-items: center;
  }

  &.menu-fixed {
    background-color: rgba(0,0,0,.6);

    .menu-bar__menu-lists {
      ul {
        li {
          a {
            &:hover, &.active {
              color: ${hover} !important;
            }
          }

        }
      }
    }
  }

  &.true {
    background-color: transparent;
  }

  .menu-bar__logo {
    img {
      height: 50px;
      object-fit: contain;
    }
  }

  .menu-bar__menu-lists {
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      li {
        a {
          font-size: 15px;
          font-weight: 500;
          line-height: 27px;
          font-family: 'Poppins', sans-serif;
          text-transform: capitalize;
          color: ${props => props.color || '#ffffff'};
          transition: color .3s ease;
          margin-right: 25px;

          &:hover, &.active {
            color: ${hover};
          }
        }

        &:nth-last-of-type(1) a {
          transition: opacity .4s ease;
          transition-delay: 0s;
          margin-right: 0;

          &:hover {
            opacity: .4 !important;
          }
        }

        &:nth-last-of-type(1) a {
          img:nth-last-of-type(1) {
            display: none;
            height: 22px;
          }
        }

        &:last-child {
          display: none;
        }

        :nth-last-child(2) {
          a {
            margin-right: 0;
          }
        }
      }
    }
  }

  &.true {
    li:not(:last-child) a {
      opacity: 0 !important;
      visibility: hidden !important;
    }

    li:nth-last-of-type(1) a img {
      &:nth-of-type(1) {
        display: none;
      }

      &:nth-of-type(2) {
        display: block !important;
      }
    }
  }

  .hamburger {
    display: none;

    img {
      transition: 1s all ease;
    }
  }

  @media (max-width: 991px) {
    .hamburger {
      display: block !important;
      position: absolute;
      right: 5px;
      top: -18px;

      a {
        img {
          &:last-child {
            display: none !important;
          }
        }
      }
    }

    .menu-bar__menu-lists {
      ul {
        justify-content: flex-end;

        li:not(:last-child) {
          display: none;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .menu-bar__menu-lists {
      ul {
        display: none;
      }
    }
    .hamburger {
      width: 30px;
      height: 30px;
      position: absolute;
      top: -15px;
      right: 15px;

    }

    .menu-bar__logo img {
      height: 50px;
    }
  }
`;

const StyleToggleMenu = styled.div`
  position: fixed;
  height: 100vh;
  width: 300px;
  z-index: 9;
  background: rgba(0, 0, 0, .84);
  right: 0;
  top: 0;
  padding-top: 100px;
  transform: translateX(100%);
  transition: transform .6s ease;
  //display: none;
  &.active {
    transform: none;
  }

  ul {

    li {
      font-size: 16px;
      color: #ffffff;
      text-transform: uppercase;
      transition: background-color .3s ease;
      display: block;
      cursor: pointer;

      &:hover {
        color: ${hover};
      }

      &:hover {
        background-color: rgba(35, 34, 34, 0.98);
      }

      &.hasChild {
        span {
          padding: 10px 20px 10px 50px;
          display: block;
          position: relative;

          &:after {
            position: absolute;
            content: '';
            background-image: url(${caret});
            height: 20px;
            width: 20px;
            background-repeat: no-repeat;
            background-position: center;
            right: 32px;
            background-size: 13px;
            bottom: 0;
            top: 0;
            margin: auto;
          }
        }

        ul {
          display: none;
          background-color: rgba(35, 34, 34, 0.98);

          li {
            a {
              font-size: 13px;
              color: rgba(255, 255, 255, 0.76);
            }
          }
        }

        &.active {
          //background-color: rgba(35,34,34,0.98);
          color: ${hover};

          span:after {
            transform: rotate(180deg);
          }

        }
      }

      a {
        font-size: 16px;
        color: #ffffff;
        padding: 10px 20px 10px 50px;
        text-transform: uppercase;
        display: block;

        &:hover {

        }
      }

      &.active > a {
        color: ${hover} !important;
      }
    }
  }

`;

function mapDispatchToProps(dispatch) {
    return {
        setSectionId: data => dispatch(setSectionId(data))
    }
}

export default connect(null, mapDispatchToProps)(OpenedMenu);
