import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {HOME} from '../../constants/home'

function* fetchHomeData(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_CHILD_BY_PAGE_ID,
      actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err);
    yield put({type: HOME.FETCH_HOME.FAILURE, error: "something went wrong"})
  }
}

function* fetchfeatureData(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_ALL_PROJECTS,
        actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_FEATURED_PROJECT.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err);
    yield put({type: HOME.FETCH_FEATURED_PROJECT.FAILURE, error: "something went wrong"})
  }
}

function* fetchHomeNewsData(actions) {
  try {
    const result = yield call(
        AxiosServices.get,
        ApiServices.GET_CHILD_BY_PAGE_ID,
        actions.params
    ); //true when Json Server
    yield put({
      type: HOME.FETCH_HOME_NEWS.SUCCESS,
      result: result.data,
    })
  } catch (err) {
    Logger(err);
    yield put({type: HOME.FETCH_HOME_NEWS.FAILURE, error: "something went wrong"})
  }
}


export default function* homeSagas() {
  yield takeLatest(HOME.FETCH_HOME.MAIN, fetchHomeData);
  yield takeLatest(HOME.FETCH_FEATURED_PROJECT.MAIN, fetchfeatureData);
  yield takeLatest(HOME.FETCH_HOME_NEWS.MAIN, fetchHomeNewsData);
}
