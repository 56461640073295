import React, {Component, useEffect, useRef, useState} from 'react';
import {Container,Row,Col,Form} from "react-bootstrap";
import styled from 'styled-components';
import imgs from '../../../assets/images/beside-map-image.jpg';
import SubTitle from '../../../components/SubTitle';
import Select from 'react-select';
import ButtonSubmit from '../../../components/ButtonSubmit';
import $ from "jquery";
import {fetchFormData,postFormSubmit} from '../../../redux/actions/form';
import {connect} from "react-redux";
import {ApiParams} from "../../../constants/apiParams";
import Message from '../../../components/PopupMessage';
import {Loading} from '../../../components/Loading';
import {emailValidation, emptyValidation, emptyNumber} from '../../../config/validator';
import Title from "../../../components/Title";
import ReactHtmlParser from "react-html-parser";
import Text from "../../../components/Text";


const options = [
    {value: 'Fanam Real Estate', label: 'Fanam Real Estate'},
    {value: 'Fanam Mart', label: 'Fanam Mart'},
    {value: 'Fanam News', label: 'Fanam News'},
    {value: 'Fanam Treading Corporation', label: 'Fanam Treading Corporation'},
    {value: 'NRB Airlines', label: 'NRB Airlines'},
    {value: 'Fanam Express', label: 'Fanam Express'},
    {value: 'Digital Store', label: 'Digital Store'},
    {value: 'Fanam Construction', label: 'Fanam Construction'},
    {value: 'NRB electronics & automobiles ltd.', label: 'NRB electronics & automobiles ltd.'},
    {value: 'Probashi Properties ltd', label: 'Probashi Properties ltd'},
    {value: 'Nrb Holdings', label: 'Nrb Holdings'},
    {value: 'Dhaka Icon', label: 'Dhaka Icon'},
    {value: 'Other', label: 'Other'},
];



const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
        backgroundColor:state.isSelected ? '#41A843' : '#FFF',
        margin:0
    }),
    menu: (provided, state) => ({
        ...provided,
        color: 'rgba(0,0,0,0.5)',
        backgroundColor:state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin:0
    }),
    menuList:(provided, state) => ({
        ...provided,
        backgroundColor:state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
    }),

};


const ContactForm = (props) =>{

    const containerRef = useRef(null);


    useEffect(() => {

        let mainParam = {
            [ApiParams.form_slug]: 'contact-form'

        };
        props.getFormData(mainParam);


        let getOffset = $('.container').offset().left + 15;
        if ($(window).width() > 992) {
            setTimeout(function () {
                $('.contact-from-left').css('padding-left', getOffset);
                $('.contact-from-right').css('padding-right', getOffset);
            })
        }
    }, []);

    let { formFields, submitForm, formSubmitErr,popup, data } = props;
    let [state, setState] = useState({
        contact_for_concern:'',
        name: '',
        phone: '',
        email: '',
        message: ''
    });

    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })
    };

    let handleSelect = (name,value)=>{
        setState({
            ...state,
            [name]: value
        })
    }

    let handleInit = () => {
        setState({
            contact_for_concern:'',
            name: '',
            phone: '',
            email: '',
            message: ''
        })
    }


    let handleSubmit = (e) => {
        e.preventDefault()

        e.preventDefault()
        let formField = {
            form_id: 'contact-form'
        }
        let len = props.formFields.length;

        for(let i = 0; i < len; i++) {
            formField[props.formFields[i]["field_key"]] = ""
        }

        formField["contact_for_concern"] = state.contact_for_concern
        formField["name"] = state.name
        formField["phone"] = state.phone
        formField["email"] = state.email
        formField["message"] = state.message

        let bodyFormData = new FormData()

        for(let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        props.postFormSubmit(bodyFormData);
        // submitForm(formField)
        // handleInit()
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleEmptyNum();
        handleLoaderBeforeSubmit();

    };

    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let handleEmptyNum = () => {
        let isEmptyNum = emptyValidation(state.phone);
        isEmptyNum ? setEmptyNum(true) : setEmptyNum(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };



    return(
        <StyledContactForm>
            {!popup ? loading ? <Loading/> : '' : null}
            {/*<Message/>*/}
            <Container ref={containerRef}/>
            <Container fluid className="careerFormWrapper">
                <Row>
                    <Col md={5} className='contact-from-left'>
                        <div className="contact-from-left__inner">
                            <Title fontSize={60} fontWeight={300} fontFamily={"'DIN Next LT Pro', sans-serif"}
                                   textTransform={'uppercase'} color={'#ffffff'}
                                   lineHeight={60} letterSpacing={0.25} margin={'0 0 45px 0'}>
                                {ReactHtmlParser('Contact <br />Us')}
                            </Title>
                            <Text fontSize={'14'} fontWeight={'300'} lineHeight={'21'} color={'#ffffff'} letterSpacing={'0'}
                                  margin={'0 0 20px 0'}>
                                {ReactHtmlParser(data?.page_data?.description)}
                            </Text>
                        </div>
                    </Col>
                    <Col md={7} className='contact-from-right'>

                        <Form>
                            <Form.Group className="inline">
                                <div className="inline__inner">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control className={empty ? 'has-error' : ''} onBlur={handleEmptyName} value={state.name} name='name'  onChange={handleChange} type="text" placeholder="Full name" />
                                </div>

                                <div className="inline__inner">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum} value={state.phone} name='phone'  onChange={handleChange} type="number" placeholder="Phone number" />
                                </div>
                            </Form.Group>


                            <Form.Group className="inline">
                                <div className="inline__inner">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control className={!emailErr ? 'has-error' : ''} onBlur={handleEmailBlur} value={state.email} name='email'  onChange={handleChange} type="email" placeholder="Email address" />
                                </div>

                                <div className="inline__inner">
                                    <Form.Label>Concern</Form.Label>
                                    <Select  onChange={op => handleSelect('contact_for_concern', op.value)}  styles={customStyles}  placeholder='Select type' options={options} />
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Message</Form.Label>
                                <Form.Control value={state.message} name='message'  onChange={handleChange} placeholder='Enter Your Message' as="textarea" rows={3} />
                            </Form.Group>

                            <Form.Group className='inline'>
                                <ButtonSubmit onSubmit={handleSubmit} fontSize={'16'}
                                              border={'1px solid #000000'}
                                              color={'#000000'}
                                              margin={'0'}
                                              text='Submit' />
                            </Form.Group>

                        </Form>
                    </Col>
                </Row>
            </Container>
        </StyledContactForm>
    )
};
const StyledContactForm = styled.section`
  .careerFormWrapper {
    background-color: #342664;
  }

  .contact-from-right {
    background-color: #F8F8F8;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 100px;


    .inline {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;

      .inline__inner {
        width: calc(50% - 15px);
      }

    }

    .dc-btn{
      width: calc(50% - 15px);
    }

    .form-file input {
      position: relative;
      height: 50px;
      width: 100%;

      &:focus {
        outline: none;
      }

      &:after {
        font-family: "Poppins", 'Arial', SansSerif;
        content: 'Upload CV/Resume';
        height: 100%;
        width: 100%;
        border: 1px solid #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        background-color: #f8f8f8;
        cursor: pointer;
        color: #000000;
        font-size: 14px;
      }
    }

    .form-group {
      margin-bottom: 30px !important;
      label{
        font-family: "Poppins", 'Arial', SansSerif;
        font-size: 12px;
        font-weight: 300;
        line-height: 24px;
      }

      .select-here {
        font-family: "Poppins", 'Arial', SansSerif;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
      }
    }

    .form-control {
      height: 50px;
      line-height: 53px;
      color: #000000;
      font-weight: 300;
      border-radius: 0;
      border-color: #000000;
      background-color: transparent;
      padding-left: 20px;

      &::placeholder {
        font-family: "Poppins", 'Arial', SansSerif;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        color: #000000;
      }
    }

    textarea {
      max-width: 100%;
      padding-top: 15px;
      line-height: 21px !important;
      &.form-control{
        height: auto !important;
      }
    }
  }

  .contact-from-left {
    padding-top: 100px;
    padding-bottom: 100px;
    &__inner{
      padding-right: 150px;
      .texts{
        p{
          font-family: "Poppins", 'Arial', SansSerif;
          a{
            font-family: "Poppins", 'Arial', SansSerif;
            font-size: 16px;
            font-weight: 300;
            line-height: 25px;
            color: #ffffff;
          }
        }
      }
    }
  }


  .css-yk16xz-control {
    background-color: transparent !important;
    color: #000000 !important;
    border-color: #000000 !important;

    .css-1uccc91-singleValue {
      color: #000000 !important;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder {
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #000000 !important;

  }

  @media (max-width: 768px) {
    .contact-from-left {
      min-width: 100%;
      padding-right: 35px;
    }
    .contact-from-left {
      min-width: 100%;
      padding-left: 35px;
      padding-right: 35px;
    }
    .contact-from-right{
      min-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    
  }

  @media (max-width: 767px) {
    .contact-from-right {
      background-color: #F8F8F8;
      padding-top: 75px;
      padding-bottom: 75px;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .contact-from-left__inner {
      padding-right: 15px;
      padding-top: 75px;
      padding-bottom: 75px;
    }

    .row {
      flex-direction: row-reverse;
    }

    .row {
      flex-direction: row-reverse;
    }

    .sub-title {
      text-align: center;
    }

    .contact-from-left {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .contact-from-left .form-group {
      margin-bottom: 16px !important;
    }

    .contact-from-left {
      min-width: 100%;
      padding-left: 35px;
      padding-right: 35px;
    }
    .contact-from-right{
      min-width: 100% !important;
      padding-left: 20px;
      padding-right: 20px;
    }
  
  }
  @media(max-width: 767px){
    .contact-from-right {
      background-color: #F8F8F8;
      padding-top: 75px;
      padding-bottom: 75px;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .contact-from-left__inner {
      padding-right: 15px;
      padding-top: 75px;
      padding-bottom: 75px;
    }
  }
`;


function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data=> dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);



