import React, {Component, useEffect, useState} from 'react';
import {Container, Row, Col, Form} from "react-bootstrap";
import styled from 'styled-components';
import SubTitle from '../../../components/SubTitle';
import Select from 'react-select';
import ButtonSubmit from '../../../components/ButtonSubmit';
import $ from "jquery";
import {baseUrl} from "../../../components/BaseUrl";
import {connect} from "react-redux";
import {ApiParams} from "../../../constants/apiParams";
import Message from '../../../components/PopupMessage';
import {fetchFormData, postFormSubmit} from '../../../redux/actions/form';
import {Loading} from '../../../components/Loading';
import {emailValidation, emptyValidation, emptyNumber} from '../../../config/validator';

const options2 = [
    {value: '1000', label: '1000sft '},
    {value: '1200', label: '1200sft '},
    {value: '1400', label: '1400sft '},
    {value: '1600', label: '1600sft '},
    {value: '1800', label: '1800sft '},
    {value: '2000', label: '2000sft '},
    {value: '2000+', label: '2000sft +'}
];


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? '#41A843' : '#FFF',
        margin: 0
    }),
    menu: (provided, state) => ({
        ...provided,
        color: 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin: 0
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
    }),

};


const ContactForm = (props) => {

    let {img} = props;

    useEffect(() => {
        let getOffset = $('.container').offset().left + 15;
        if ($(window).width() > 992) {
            setTimeout(function () {
                $('.contact-from-left').css('padding-left', getOffset);
            })
        }

        let mainParam = {
            [ApiParams.form_slug]: 'buyer'

        };
        props.getFormData(mainParam);


    }, []);


    let {formFields, submitForm, formSubmitErr, popup} = props;
    let [state, setState] = useState({
        location: '',
        size: '',
        name: '',
        phone: '',
        email: '',
        message: '',
    });


    let handleChange = e => {
        let value = e.target.value
        setState({
            ...state,
            [e.target.name]: value
        })

    };

    let handleSelect = (name, value) => {
        setState({
            ...state,
            [name]: value
        })

    }

    let handleInit = () => {
        setState({
            location: '',
            size: '',
            name: '',
            phone: '',
            email: '',
            message: '',
        })
    }


    let handleSubmit = (e) => {
        e.preventDefault()

        e.preventDefault()
        let formField = {
            form_id: 'buyer'
        }
        let len = props.formFields.length;

        for (let i = 0; i < len; i++) {
            formField[props.formFields[i]["field_key"]] = ""
        }

        formField["location"] = state.location;
        formField["size"] = state.size;
        formField["name"] = state.name;
        formField["phone"] = state.phone;
        formField["email"] = state.email;
        formField["message"] = state.message;

        let bodyFormData = new FormData();

        for (let key in formField) {
            bodyFormData.append(`${key}`, formField[key])
        }

        props.postFormSubmit(bodyFormData);
        // postFormSubmit(bodyFormData);
        handleInit();
        handleEmailBlur();
        handleEmptyName();
        handleEmptyNum();
        handleLoaderBeforeSubmit();

    };


    let [emailErr, setEmailErr] = useState(true);
    let [empty, setEmpty] = useState(false);
    let [emptyNum, setEmptyNum] = useState('');

    let handleEmailBlur = () => {
        let isValid = emailValidation(state.email);
        isValid ? setEmailErr(true) : setEmailErr(false)
    };

    let handleEmptyName = () => {
        let isEmpty = emptyValidation(state.name);
        isEmpty ? setEmpty(true) : setEmpty(false)
    };

    let handleEmptyNum = () => {
        let isEmptyNum = emptyValidation(state.phone);
        isEmptyNum ? setEmptyNum(true) : setEmptyNum(false)
    };

    let [loading, setLoading] = useState(false);

    let handleLoaderBeforeSubmit = () => {
        setLoading(true)
    };

    return (
        <StyledContactForm>
            {!popup ? loading ? <Loading/> : '' : null}
            <Message/>
            <Container fluid>
                <Row>
                    <Col md={6} className='contact-from-left'>

                        <Form onSubmit={handleSubmit}>

                            <SubTitle text={'inquiry'} margin={'0 0 40px 0'}/>
                            <Form.Group className='inline'>
                                <Form.Control type="text" value={state.location} name='location' onChange={handleChange}
                                              placeholder="Location"/>
                                <Select onChange={op => handleSelect('size', op.value)} styles={customStyles}
                                        className='select-here files' placeholder='Select size' options={options2}/>
                            </Form.Group>

                            <SubTitle text={'contact us'} margin={'0 0 30px 0'}/>


                            <Form.Group>
                                <Form.Control className={empty ? 'has-error' : ''} onBlur={handleEmptyName}
                                              value={state.name} onChange={handleChange} name='name' type="text"
                                              placeholder="Full name"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Control className={emptyNum ? 'has-error' : ''} onBlur={handleEmptyNum}
                                              value={state.phone} onChange={handleChange} name='phone' type="number"
                                              placeholder="Phone number"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Control className={!emailErr ? 'has-error' : ''} onBlur={handleEmailBlur}
                                              value={state.email} onChange={handleChange} name='email' type="email"
                                              placeholder="Email address"/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Control value={state.message} onChange={handleChange} name='message'
                                              placeholder='Message' as="textarea" rows={1}/>
                            </Form.Group>

                            <ButtonSubmit onSubmit={handleSubmit} border={'1px solid rgba(26,24,24,0.75)'}
                                          color={'rgba(26,24,24,0.75)'} margin={'0'} text='Explore'/>

                        </Form>
                    </Col>

                    <Col md={6} className='contact-from-right p-0'>
                        <div className="contact-from-right__inner">
                            <img src={img ? baseUrl + img.path + img.img_name : ''} alt=""/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledContactForm>
    )
};
const StyledContactForm = styled.section`
  .contact-from-left{
    padding-right: 85px;
    padding-left: 35px;
    background-color: #F8F8F8;
    padding-top: 95px;
    padding-bottom: 95px;
    .inline{
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      .select-here{
        width: calc(50% - 15px);
      }
      input{
        width: calc(50% - 15px);
      }
    }
    .form-group{
      margin-bottom: 30px !important;
    }
    .form-control{
      height: 50px;
      line-height: 53px;
      color: #1a1818;
      font-size: 14px;
      font-weight: 300;
      border-radius: 0;
      border-color:rgba(26,24,24,0.75);
      background-color: transparent;
      padding-left: 20px;
      &::placeholder{
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        color: rgba(26,24,24,0.75);
      }
    }
      textarea{
      height: 50px;
        min-height: 50px !important;
        max-width:100%;
        padding-top: 15px;
        line-height: 21px !important;
      }
  }
  .contact-from-right{
  &__inner{
    height: 100%;
  }
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  

  
  .css-yk16xz-control{
    background-color: transparent !important;
    border-color:rgba(26,24,24,0.75) !important;
    .css-1uccc91-singleValue{
      color:rgba(26,24,24,0.75) !important;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .css-yk16xz-control .css-1wa3eu0-placeholder{
    font-size: 14px !important;
    font-weight: 300 !important;
    color: rgba(26,24,24,0.75) !important;
   
  }
  
  @media(max-width:768px){
    .contact-from-left{
      min-width:100%;
      padding-right: 35px;
    }
  }
  
  @media(max-width:767px){
  .contact-from-right__inner{
    display: none;
  }
  .row{
    flex-direction: row-reverse;
  }
  .sub-title{
    text-align: center;
  }
    .contact-from-left{
     padding-left: 15px;
     padding-right: 15px;
     padding-top: 50px;
     padding-bottom: 50px;
    }
    .contact-from-left .form-group{
    margin-bottom: 16px !important;
  }
  }
  
`;


function mapStateToProps(state) {
    return {
        formFields: state.formReducer.formFields,
        popup: state.formReducer.popup
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFormData: params => dispatch(fetchFormData(params)),
        postFormSubmit: data => dispatch(postFormSubmit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
