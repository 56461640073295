import React, {useEffect, useLayoutEffect} from 'react';
import {connect} from "react-redux";
import InnerBanner from '../../components/InnerBanner';
import JobDesc from './components/JobDescription';
import Contact from './components/ContactForm';
import {fetchCareerDetailData} from "../../redux/actions/career";
import {ApiParams} from "../../constants/apiParams";
import {useParams} from "react-router-dom";

// img
import image from '../../assets/images/dynamic/career.jpg'
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";

const Apply = ({getCareerDetailData, careerPageDetail})=>{
    let uriParams = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);

        let param = {
            [ApiParams.page_slug]: uriParams.slug
        }

        getCareerDetailData(param);
    }, []);

    let bannerTitle = careerPageDetail?.page_data ? careerPageDetail.page_data.title : '';
    let innerBannerImageData = careerPageDetail?.images?.list ? careerPageDetail.images.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = innerBannerImageData?.path ? baseUrl + innerBannerImageData?.path + innerBannerImageData?.img_name : BlurImg;

    return(
        <div>
            <InnerBanner image={bannerImagePath} text={bannerTitle} height={'382px'}/>
            <JobDesc data={careerPageDetail} />
            <Contact />
        </div>
    )
};
function mapStateToProps(state) {
    return {
        careerPageDetail: state.careerReducer.careerPageDetail,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCareerDetailData: params => dispatch(fetchCareerDetailData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Apply);