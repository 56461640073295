import React, {useEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import Jobs from './components/Jobs';
import {fetchCareerPageData, fetchCareerListData} from "../../redux/actions/career";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import CareerFrom from "./components/ContactForm";
import BlurImg from "../../assets/images/blur.jpg";


const Career = ({getCareerPageData, getCareerListData, careerPage, careerPageList}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        let param = {
            [ApiParams.page_type]: 'job-list'
        }

        getCareerPageData(param);
    }, []);

    let careerData = careerPage ? careerPage.page_data : null;
    let pageId = careerPage?.page_data?.id;

    useEffect(() => {
        let childparam = {
            [ApiParams.page_id]: pageId
        }

        getCareerListData(childparam);
    }, [pageId]);

    let careerPageSub = careerData ? careerData.title : '';

    let innerBannerImageData = careerPage?.images?.list ? careerPage.images.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = innerBannerImageData?.path ? baseUrl + innerBannerImageData?.path + innerBannerImageData?.img_name : BlurImg;

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <InnerBanner image={bannerImagePath} text={careerPageSub} height={'382px'}/>
            <Jobs list={careerPageList}/>
            <CareerFrom/>
        </motion.div>
    )
};

function mapStateToProps(state) {
    return {
        careerPage: state.careerReducer.careerPage,
        careerPageList: state.careerReducer.careerPageList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCareerPageData: params => dispatch(fetchCareerPageData(params)),
        getCareerListData: params => dispatch(fetchCareerListData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Career);
