import React, {Component, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";
import ReactHtmlParser from "react-html-parser";
import Title from './Title';
import Text from './Text';
import Link from './Link1';
import Modal from './Modal';
import SubTitle from './SubTitle';
import Button from './Button';

import BlurImg from "../assets/images/blur.jpg";

const DescriptionWithImage = ({
                                  bgColor,
                                  ImageUrl,
                                  TitleTextComponent,
                                  DescText,
                                  Links,
                                  ButtonText = 'Learn More',
                                  justify,
                                  popup = false,
                                  PopupTitle,
                                  ModalClass,
                                  PopupDescText
                              }) => {
    const [show, setShow] = useState(
        false
    );

    function handleModal(value) {
        setShow(value);
    }

    return (
        <>
            <StyledDescriptionWithImage bg={bgColor} justify={justify} className="pt-100 pb-100 description-with-image">
                <Container>
                    <Row>
                        <Col md={{span: 6}} className='description-with-image__img'>
                            <Row>
                                <div className='description-with-image__img__section'>
                                    <img src={ImageUrl ? ImageUrl : BlurImg} alt=""/>
                                </div>
                            </Row>
                        </Col>
                        <Col md={{span: 5, offset: 1}} className='description-with-image__content'>
                            <div className="description-with-image__content__section">
                                {
                                    TitleTextComponent ?
                                        TitleTextComponent
                                        :
                                        <Title fontSize={60} fontWeight={300}
                                               fontFamily={"'DIN Next LT Pro', sans-serif"}
                                               textTransform={'uppercase'} color={'#000000'}
                                               lineHeight={60} letterSpacing={0.25} margin={'0 0 30px 0'}>
                                            {Title}
                                        </Title>
                                }
                                <Text className="description-with-image__content__section__text" fontSize={14}
                                      fontWeight={400} color={'#505050'} lineHeight={21}
                                      margin={'0 0 30px 0'}>
                                    {DescText}
                                </Text>
                                {popup === false ?
                                    <Link text={'Learn More'} link={Links} fontSize={15} fontWeight={500}
                                          color={'#000000'}/>
                                    :
                                    <div
                                        className="popup-on"
                                        onClick={() => handleModal(true)}
                                    >
                                        <Link text={ButtonText} link={'#'} fontSize={15} fontWeight={500}
                                              color={'#000000'}/>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>

                </Container>
                <Modal
                    show={show}
                    handleClose={() => handleModal(false, null)}
                    post={PopupDescText}
                    className={ModalClass}
                    title={PopupTitle}/>
            </StyledDescriptionWithImage>
        </>
    )
};

const StyledDescriptionWithImage = styled.section`
  &.description-with-image {
    background-color: ${props => props.bg || '#ffffff'};

    .description-with-image__img {
      position: relative;

      &__section {
        z-index: 1;
        width: 80%;
        position: relative;
        padding-left: 15px;
        
        &:before {
          position: absolute;
          content: '';
          width: calc(100% + 15%);
          height: 300px;
          bottom: 0;
          background-color: #F9C602;
          left: 15px;
          z-index: -1;
        }
        img {
          width: 100%;
        }
      }
    }

    .description-with-image__content {
      display: flex;
      align-items: center;

      &__section {
       

        .texts {
          margin-bottom: 40px;
          //max-height: 175px;
          overflow: hidden;
        }
      }
    }
  }
  
  
  @media(max-width: 767px){
    &.description-with-image{
      padding-top: 75px !important;
      padding-bottom: 75px !important;
    }
    &.description-with-image .description-with-image__img__section{
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 30px;
      width: 100%;
    }
    &.description-with-image .description-with-image__img__section:before{
      height: 100px;
      content: none;
    }
  }
`;
export default DescriptionWithImage;