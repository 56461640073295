import React from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import arrowImg from '../assets/images/static/round-arrow-right.svg'
import {Link} from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

const Links = ({text, link, img, color, margin, letterSpace, fontSize, textTransform, fontWeight}) => {

    return (
        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledLink className={`${isVisible ? 'anim-active' : ''} fade-up title link`}
                            margin={margin} letterSpace={letterSpace} fontWeight={fontWeight}
                            textTransform={textTransform} fontSize={fontSize} color={color}>

                    <Link to={link || '/'}>{text}<span><img src={arrowImg} alt=""/></span></Link>
                </StyledLink>
            }
        </VisibilitySensor>
    )
};

const StyledLink = styled.div`
    margin:${props => props.margin || '0'}px;
    a{
        margin: 0;
        display: flex;
        color: ${props => props.color || '#FFF'};
        font-size:${props => props.fontSize || 12}px;
        font-weight:${props => props.fontWeight || 300};
        letter-spacing:${props => props.letterSpace || '2'}px;
        text-transform:${props => props.textTransform || 'uppercase'};
        line-height: 24px;
        transition:all .3s ease;
        align-items: center;
        position: relative;
        color:${props=> props.color || `${hover}`};
        span {
            height: 25px;
            width: 25px;
            z-index: 2;
            position: relative;
            img{
                margin-left: 20px;
                transition: all .3s ease !important;
            }
        }
        &:hover{
            span {
            color: ${hover};
                img{
                    transform: translateX(20px);
                }
            }
        }  
    }
`;

export default Links;