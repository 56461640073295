import React, {useState} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover, fontDin} from '../../../globalStyleVars';
import ReactHtmlParser from "react-html-parser";

import Title from "../../../components/Title";
import SubTitle from "../../../components/SubTitle";
import {Link} from "react-router-dom";
import Modal from "../../../components/Modal";

const BoardOfDirector = (data) => {
    const [show, setShow] = useState(
        false
    );
    const [modalData, setModalData] = useState(
        false
    );

    function handleModal(value, modalValues) {
        setShow(value);
        setModalData(modalValues)
    }

    let boardOfDirectorValue = data?.data?.page_data ? data.data.page_data : "";
    let boardOfDirectorPosts = data?.data?.posts?.list ? data.data.posts.list : "";
    return (
        <StyledBoardOfDirector>
            <Container className='BoardOfDirector p-0'>
                <Col md={12} className="relative_wrap">
                    <SubTitle color={'#000000'} fontSize={'24'} lineHeight={'36'}
                              letterSpacing={'0'}
                              fontWeight={'400'} margin={'0 0 60px 0'}
                              text={ReactHtmlParser(boardOfDirectorValue.title)}/>
                    <Title fontSize={60} fontWeight={300} fontFamily={"'DIN Next LT Pro', sans-serif"}
                           textTransform={'uppercase'} color={'#000000'}
                           lineHeight={60} letterSpacing={0.25} margin={'0 0 30px 0'}>
                        {ReactHtmlParser(boardOfDirectorValue.sub_title)}
                    </Title>
                </Col>
                <Col md={12} className="BoardOfDirector__wrap">
                    {
                        boardOfDirectorPosts && boardOfDirectorPosts.length ?
                            <Row>
                                {
                                    boardOfDirectorPosts ? boardOfDirectorPosts.map((item, key) => {
                                        return (
                                            <Col key={key} md={6}>
                                                <div className="BoardOfDirector__wrap__Single">
                                                    <div
                                                        className="popup-on"
                                                        onClick={() => handleModal(true, item)}
                                                    >
                                                        <Link
                                                            to={'#'}>
                                                            <div className="BoardOfDirector__wrap__Single__title">
                                                                <h4>{ReactHtmlParser(item.data.title)}</h4>
                                                                <p>{ReactHtmlParser(item.data.extra_description_one)}</p>
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="30"
                                                                 height="30" viewBox="0 0 24 24">
                                                                <g id="Component_1_2" data-name="Component 1 – 2"
                                                                   opacity="0.5">
                                                                    <g id="Ellipse_5" data-name="Ellipse 5" fill="none"
                                                                       stroke="#000" stroke-width="1">
                                                                        <circle cx="12" cy="12" r="12" stroke="none"/>
                                                                        <circle cx="12" cy="12" r="11.5" fill="none"/>
                                                                    </g>
                                                                    <g id="Group_4" data-name="Group 4"
                                                                       transform="translate(-722.5 -1564.137)">
                                                                        <path id="Path_1" data-name="Path 1"
                                                                              d="M717.64,1571.722l4,4-4,4"
                                                                              transform="translate(17.36 0.415)"
                                                                              fill="none" stroke="#000"
                                                                              stroke-linecap="round"
                                                                              stroke-linejoin="round" stroke-width="1"/>
                                                                        <path id="Path_2" data-name="Path 2"
                                                                              d="M722.472,1576.137h-9"
                                                                              transform="translate(16.528)" fill="none"
                                                                              stroke="#000" stroke-linecap="round"
                                                                              stroke-width="1"/>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </Link>
                                                    </div>

                                                </div>
                                            </Col>

                                        )
                                    }) : ''
                                }
                            </Row>
                            : ''
                    }
                </Col>
            </Container>
            <Modal
                show={show}
                handleClose={() => handleModal(false, null)}
                post={ReactHtmlParser(modalData?.data?.description)}
                title={ReactHtmlParser(modalData?.data?.title)}/>
        </StyledBoardOfDirector>
    );
};

const StyledBoardOfDirector = styled.section`
  .BoardOfDirector {
    padding-top: 100px !important;
    padding-bottom: 100px !important;

    &__wrap {
      &__Single {
        background-color: #F6F5F3;

        a {
          padding: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          svg {
            transform: translateX(0px);
            transition: 0.5s all ease;
          }

          &:hover {
            .BoardOfDirector__wrap__Single {
              &__title {
                h4 {
                  color: ${hover};
                }
              }
            }

            svg {
              transform: translateX(15px);
            }
          }
        }

        &__title {
          h4 {
            font-family: "Poppins", SansSerif;
            font-size: 30px;
            line-height: 42px;
            font-weight: 400;
            text-transform: uppercase;
            color: #000000;
            transition: 0.5s all ease;
          }

          p {
            font-family: "Poppins", SansSerif;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media (min-width: 991px) and (max-width: 1300px) {

  }

  @media (max-width: 767px) {
    .BoardOfDirector {
      padding-top: 100px !important;
      padding-bottom: 100px !important;

      &__wrap {
        &__Single {
          margin-bottom: 30px;
        }
      }
    }
  }

`;

export default BoardOfDirector;
