import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
// import Menu from "../../components/Menu";
import {connect} from 'react-redux'
import {fetchServicesTwoPageData} from "../../redux/actions/services/servicesTwo";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import Success from './components/Success';
import Gallery from './components/Gallery';
import Map from './components/Map';
import ContactFrom from './components/ContactForm';

// img
import Banner from '../../assets/images/dynamic/banner.jpg';

class Services extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        let params = {
            [ApiParams.page_type]: 'services-02'
        };

        // if (!this.props.serviceTwoPage.length) {
        //     this.props.getServiceTwoPageData(params)
        // }

        this.props.getServiceTwoPageData(params)
    }

    render() {
        let {serviceTwoPage} = this.props;
        let pageData = serviceTwoPage ? serviceTwoPage.page_data : null
        let pageTitle = pageData ? pageData.title : ''

        let bannerImagePath = serviceTwoPage ?
            serviceTwoPage.images ?
                serviceTwoPage.images.list ?
                    serviceTwoPage.images.list[0] ?
                        serviceTwoPage.images.list[0].path + serviceTwoPage.images.list[0].img_name : null
                    : null
                : null
            : null

        let banner = bannerImagePath ? baseUrl + bannerImagePath : '';

        let postList = serviceTwoPage ? serviceTwoPage.posts ? serviceTwoPage.posts.list : [] : [];

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">

                <InnerBanner
                    text={pageTitle}
                    subTitle={'services'}
                    image={banner}
                />

                <Success post={postList} />
                <Gallery post={postList} />
                {/*<Map post={postList} />*/}
                <ContactFrom post={postList} />
            </motion.div>
        )
    }
};

function mapStateToProps(state) {
    return {
        serviceTwoPage: state.serviceTwoReducer.servicePage,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getServiceTwoPageData: params => dispatch(fetchServicesTwoPageData(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
