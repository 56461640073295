import {createGlobalStyle, css} from 'styled-components';
import {darkGreen, fontDin, hover} from './globalStyleVars';
import modalClose from './assets/images/close.svg';
import NextArrowImage from "./assets/images/_next-black.svg";

import React, {useEffect} from 'react'


function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`
${createCSS()};
#root{
  overflow: hidden !important;
}
@font-face {
    font-family: 'DIN Next LT Pro Condensed';
    src: url('./assets/fonts/DINNextLTPro-BoldCondensed.eot');
    src: url('./assets/fonts/DINNextLTPro-BoldCondensed.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-BoldCondensed.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-BoldCondensed.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-BoldCondensed.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-BoldCondensed.svg#DINNextLTPro-BoldCondensed') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-Heavy.eot');
    src: url('./assets/fonts/DINNextLTPro-Heavy.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-Heavy.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-Heavy.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-Heavy.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-Heavy.svg#DINNextLTPro-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro Medium Cond';
    src: url('./assets/fonts/DINNextLTPro-HeavyCondensed.eot');
    src: url('./assets/fonts/DINNextLTPro-HeavyCondensed.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-HeavyCondensed.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-HeavyCondensed.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-HeavyCondensed.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-HeavyCondensed.svg#DINNextLTPro-HeavyCondensed') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-BoldItalic.eot');
    src: url('./assets/fonts/DINNextLTPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-BoldItalic.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-BoldItalic.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-BoldItalic.svg#DINNextLTPro-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro Black Condensed';
    src: url('./assets/fonts/DINNextLTPro-BlackCondensed.eot');
    src: url('./assets/fonts/DINNextLTPro-BlackCondensed.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-BlackCondensed.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-BlackCondensed.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-BlackCondensed.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-BlackCondensed.svg#DINNextLTPro-BlackCondensed') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro Condensed';
    src: url('./assets/fonts/DINNextLTPro-Condensed.eot');
    src: url('./assets/fonts/DINNextLTPro-Condensed.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-Condensed.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-Condensed.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-Condensed.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-Condensed.svg#DINNextLTPro-Condensed') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-BlackItalic.eot');
    src: url('./assets/fonts/DINNextLTPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-BlackItalic.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-BlackItalic.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-BlackItalic.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-BlackItalic.svg#DINNextLTPro-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-Black.eot');
    src: url('./assets/fonts/DINNextLTPro-Black.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-Black.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-Black.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-Black.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-Black.svg#DINNextLTPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-Bold.eot');
    src: url('./assets/fonts/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-Bold.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-Bold.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-Bold.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-Bold.svg#DINNextLTPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-Medium.eot');
    src: url('./assets/fonts/DINNextLTPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-Medium.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-Medium.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-Medium.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-Medium.svg#DINNextLTPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-Light.eot');
    src: url('./assets/fonts/DINNextLTPro-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-Light.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-Light.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-Light.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-Light.svg#DINNextLTPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro Medium Cond';
    src: url('./assets/fonts/DINNextLTPro-MediumCond.eot');
    src: url('./assets/fonts/DINNextLTPro-MediumCond.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-MediumCond.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-MediumCond.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-MediumCond.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-MediumCond.svg#DINNextLTPro-MediumCond') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-HeavyItalic.eot');
    src: url('./assets/fonts/DINNextLTPro-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-HeavyItalic.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-HeavyItalic.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-HeavyItalic.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-HeavyItalic.svg#DINNextLTPro-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-Italic.eot');
    src: url('./assets/fonts/DINNextLTPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-Italic.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-Italic.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-Italic.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-Italic.svg#DINNextLTPro-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro Light Condensed';
    src: url('./assets/fonts/DINNextLTPro-LightCondensed.eot');
    src: url('./assets/fonts/DINNextLTPro-LightCondensed.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-LightCondensed.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-LightCondensed.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-LightCondensed.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-LightCondensed.svg#DINNextLTPro-LightCondensed') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-LightItalic.eot');
    src: url('./assets/fonts/DINNextLTPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-LightItalic.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-LightItalic.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-LightItalic.svg#DINNextLTPro-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-UltraLight.eot');
    src: url('./assets/fonts/DINNextLTPro-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-UltraLight.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-UltraLight.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-UltraLight.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-UltraLight.svg#DINNextLTPro-UltraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-Regular.eot');
    src: url('./assets/fonts/DINNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-Regular.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-Regular.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-Regular.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-Regular.svg#DINNextLTPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url('./assets/fonts/DINNextRoundedLTPro-Regular.eot');
    src: url('./assets/fonts/DINNextRoundedLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextRoundedLTPro-Regular.woff2') format('woff2'),
        url('./assets/fonts/DINNextRoundedLTPro-Regular.woff') format('woff'),
        url('./assets/fonts/DINNextRoundedLTPro-Regular.ttf') format('truetype'),
        url('./assets/fonts/DINNextRoundedLTPro-Regular.svg#DINNextRoundedLTPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro UltraLight Cond';
    src: url('./assets/fonts/DINNextLTPro-UltraLightCond.eot');
    src: url('./assets/fonts/DINNextLTPro-UltraLightCond.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-UltraLightCond.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-UltraLightCond.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-UltraLightCond.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-UltraLightCond.svg#DINNextLTPro-UltraLightCond') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-UltraLightIt.eot');
    src: url('./assets/fonts/DINNextLTPro-UltraLightIt.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-UltraLightIt.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-UltraLightIt.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-UltraLightIt.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-UltraLightIt.svg#DINNextLTPro-UltraLightIt') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url('./assets/fonts/DINNextRoundedLTPro-Medium.eot');
    src: url('./assets/fonts/DINNextRoundedLTPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextRoundedLTPro-Medium.woff2') format('woff2'),
        url('./assets/fonts/DINNextRoundedLTPro-Medium.woff') format('woff'),
        url('./assets/fonts/DINNextRoundedLTPro-Medium.ttf') format('truetype'),
        url('./assets/fonts/DINNextRoundedLTPro-Medium.svg#DINNextRoundedLTPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url('./assets/fonts/DINNextRoundedLTPro-Bold.eot');
    src: url('./assets/fonts/DINNextRoundedLTPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextRoundedLTPro-Bold.woff2') format('woff2'),
        url('./assets/fonts/DINNextRoundedLTPro-Bold.woff') format('woff'),
        url('./assets/fonts/DINNextRoundedLTPro-Bold.ttf') format('truetype'),
        url('./assets/fonts/DINNextRoundedLTPro-Bold.svg#DINNextRoundedLTPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next LT Pro';
    src: url('./assets/fonts/DINNextLTPro-MediumItalic.eot');
    src: url('./assets/fonts/DINNextLTPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextLTPro-MediumItalic.woff2') format('woff2'),
        url('./assets/fonts/DINNextLTPro-MediumItalic.woff') format('woff'),
        url('./assets/fonts/DINNextLTPro-MediumItalic.ttf') format('truetype'),
        url('./assets/fonts/DINNextLTPro-MediumItalic.svg#DINNextLTPro-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Next Rounded LT Pro';
    src: url('./assets/fonts/DINNextRoundedLTPro-Light.eot');
    src: url('./assets/fonts/DINNextRoundedLTPro-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/DINNextRoundedLTPro-Light.woff2') format('woff2'),
        url('./assets/fonts/DINNextRoundedLTPro-Light.woff') format('woff'),
        url('./assets/fonts/DINNextRoundedLTPro-Light.ttf') format('truetype'),
        url('./assets/fonts/DINNextRoundedLTPro-Light.svg#DINNextRoundedLTPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Black.eot');
    src: url('./assets/fonts/Poppins-Black.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-Black.woff2') format('woff2'),
        url('./assets/fonts/Poppins-Black.woff') format('woff'),
        url('./assets/fonts/Poppins-Black.ttf') format('truetype'),
        url('./assets/fonts/Poppins-Black.svg#Poppins-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraBoldItalic.eot');
    src: url('./assets/fonts/Poppins-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('./assets/fonts/Poppins-ExtraBoldItalic.woff') format('woff'),
        url('./assets/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype'),
        url('./assets/fonts/Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraLight.eot');
    src: url('./assets/fonts/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-ExtraLight.woff2') format('woff2'),
        url('./assets/fonts/Poppins-ExtraLight.woff') format('woff'),
        url('./assets/fonts/Poppins-ExtraLight.ttf') format('truetype'),
        url('./assets/fonts/Poppins-ExtraLight.svg#Poppins-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-BoldItalic.eot');
    src: url('./assets/fonts/Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/Poppins-BoldItalic.woff') format('woff'),
        url('./assets/fonts/Poppins-BoldItalic.ttf') format('truetype'),
        url('./assets/fonts/Poppins-BoldItalic.svg#Poppins-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-BlackItalic.eot');
    src: url('./assets/fonts/Poppins-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-BlackItalic.woff2') format('woff2'),
        url('./assets/fonts/Poppins-BlackItalic.woff') format('woff'),
        url('./assets/fonts/Poppins-BlackItalic.ttf') format('truetype'),
        url('./assets/fonts/Poppins-BlackItalic.svg#Poppins-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraLightItalic.eot');
    src: url('./assets/fonts/Poppins-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('./assets/fonts/Poppins-ExtraLightItalic.woff') format('woff'),
        url('./assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype'),
        url('./assets/fonts/Poppins-ExtraLightItalic.svg#Poppins-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Bold.eot');
    src: url('./assets/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-Bold.woff2') format('woff2'),
        url('./assets/fonts/Poppins-Bold.woff') format('woff'),
        url('./assets/fonts/Poppins-Bold.ttf') format('truetype'),
        url('./assets/fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ExtraBold.eot');
    src: url('./assets/fonts/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-ExtraBold.woff2') format('woff2'),
        url('./assets/fonts/Poppins-ExtraBold.woff') format('woff'),
        url('./assets/fonts/Poppins-ExtraBold.ttf') format('truetype'),
        url('./assets/fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-SemiBold.eot');
    src: url('./assets/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('./assets/fonts/Poppins-SemiBold.woff') format('woff'),
        url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype'),
        url('./assets/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Light.eot');
    src: url('./assets/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-Light.woff2') format('woff2'),
        url('./assets/fonts/Poppins-Light.woff') format('woff'),
        url('./assets/fonts/Poppins-Light.ttf') format('truetype'),
        url('./assets/fonts/Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Regular.eot');
    src: url('./assets/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-Regular.woff2') format('woff2'),
        url('./assets/fonts/Poppins-Regular.woff') format('woff'),
        url('./assets/fonts/Poppins-Regular.ttf') format('truetype'),
        url('./assets/fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Thin.eot');
    src: url('./assets/fonts/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-Thin.woff2') format('woff2'),
        url('./assets/fonts/Poppins-Thin.woff') format('woff'),
        url('./assets/fonts/Poppins-Thin.ttf') format('truetype'),
        url('./assets/fonts/Poppins-Thin.svg#Poppins-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-SemiBoldItalic.eot');
    src: url('./assets/fonts/Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('./assets/fonts/Poppins-SemiBoldItalic.woff') format('woff'),
        url('./assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype'),
        url('./assets/fonts/Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-LightItalic.eot');
    src: url('./assets/fonts/Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/Poppins-LightItalic.woff') format('woff'),
        url('./assets/fonts/Poppins-LightItalic.ttf') format('truetype'),
        url('./assets/fonts/Poppins-LightItalic.svg#Poppins-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-MediumItalic.eot');
    src: url('./assets/fonts/Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-MediumItalic.woff2') format('woff2'),
        url('./assets/fonts/Poppins-MediumItalic.woff') format('woff'),
        url('./assets/fonts/Poppins-MediumItalic.ttf') format('truetype'),
        url('./assets/fonts/Poppins-MediumItalic.svg#Poppins-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Medium.eot');
    src: url('./assets/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-Medium.woff2') format('woff2'),
        url('./assets/fonts/Poppins-Medium.woff') format('woff'),
        url('./assets/fonts/Poppins-Medium.ttf') format('truetype'),
        url('./assets/fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Italic.eot');
    src: url('./assets/fonts/Poppins-Italic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-Italic.woff2') format('woff2'),
        url('./assets/fonts/Poppins-Italic.woff') format('woff'),
        url('./assets/fonts/Poppins-Italic.ttf') format('truetype'),
        url('./assets/fonts/Poppins-Italic.svg#Poppins-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-ThinItalic.eot');
    src: url('./assets/fonts/Poppins-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Poppins-ThinItalic.woff2') format('woff2'),
        url('./assets/fonts/Poppins-ThinItalic.woff') format('woff'),
        url('./assets/fonts/Poppins-ThinItalic.ttf') format('truetype'),
        url('./assets/fonts/Poppins-ThinItalic.svg#Poppins-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
    

   
   
    body{
       font-family: 'DIN Next LT Pro' , sans-serif;
       font-style: normal;
       margin: 0;
       padding: 0;
       overflow-x: hidden;
       color: #1B1D1D;
       -webkit-font-smoothing: antialiased;
       -moz-osx-font-smoothing: grayscale;
    }
    a{
      transition:color .3s ease;
      &:hover {
       color: ${hover} !important;
      }
    }
    
    ::selection {
      background: ${hover};
      color: #FFF;
    }
    
    p, a, h1, h2, h4, h3 {
      color: #1B1D1D;
     font-family: 'DIN Next LT Pro' , sans-serif;
    }
    
    ul {
      margin: 0;
      padding: 0
    }
    
    li {
      list-style: none;
    }
    a:hover, a:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }
    
    .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
      outline: none;
      box-shadow: none;
    }
    
    table {
      width: 100%;
    }
    .form-control {
      box-shadow: none;
      outline: 0;
    
      &:focus {
        box-shadow: none;
      }
    }
  
    .p-0{
      padding:0 !important;
    }
    .pt-150{
      padding-top: 150px;
    }
    .pb-150{
      padding-bottom: 150px;
    }
    .pt-120{
      padding-top: 120px;
    }
    .pb-120{
      padding-bottom: 120px;
    }
    .pt-100{
      padding-top: 100px;
    }
    .pb-100{
      padding-bottom: 100px;
    }
    .swiper-button-disabled{
      opacity: 0 !important;
    }
    .gmnoprint,.gm-control-active{
      opacity:0;
    }
    
    .swiper-button-next:after{
      display: none;
    }
    .swiper-button-prev:after{
      display: none;
    }
    
    @media  (min-width: 1440px) {
      .container {
        //min-width: 1366px;
        min-width: 80%;
        margin: auto;
      }
    }
    
    @media (max-width: 991px){
     
        .container {
            min-width: 95%;
        }
    }
    
    @media (max-width: 768px){
        .container, .container-md, .container-sm {
            max-width: 90%;
            overflow: hidden;
        }
    }
    
    @media (max-width: 767px){
        .container, .container-sm {
            max-width: 100%;
        }
          .pt-120,.pt-150{
              padding-top: 70px;
            }
            .pb-120,.pb-150{
              padding-bottom:70px;
          }
      
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    
    #root{
      overflow-x: hidden;
    }
    
    
    .modal-header{
      padding: 0;
      padding-bottom: 27px;
      margin-bottom: 40px;
    }
    
     .modal-dialog{
        width: 80%;
        max-width:unset;
     }
      .modal-content{
        border-radius: 0;
        padding: 50px 70px;
        .close{
          height: 50px;
          width: 50px;
          background-image: url(${modalClose});
          background-color: ${hover};
          background-size: 25px;
          background-position: center;
          padding: 0;
          background-repeat: no-repeat;
          position: absolute;
          right: -34px;
          opacity: 1;
          top: 16px;
          transition:all .3s ease;
          &:hover{
            background-color: ${darkGreen};
          }
          span{
              display: none;
          }
          
        }
      }
      .modal-body{
        padding: 0;  
        tbody{
          tr{
            &:nth-of-type(1) td{
              border-top:0;
            }
           
            td{
              padding: 20px ;
              font-size: 14px;
              font-weight: 300;
              border-color:#DFE6E5;
              &:nth-of-type(1){
                padding-left: 0;
                font-size: 16px;
                font-weight: 400;
                color: rgba(0,0,0,0.51);
              }
              p{
                font-size: 14px;
                font-weight: 300;
                margin-bottom: 10px;
              }
            }
            &:nth-last-of-type(1){
                 td p:last-child{
                  margin-bottom: 0;
                }
                border-bottom:1px solid #DFE6E5;
            }
          }
        }
      }
      .modal-title{
          h2{
            font-size: 42px !important;
            font-weight: bold;
            line-height: 42px;
            margin-bottom: 0;
          }
      }
      .LandownerModal{
        ul{
          li{
            padding: 30px 100px 30px 30px;
            background-color: #F8F8F8;
            margin-bottom: 20px;
            color: #505050;
            position: relative;
            &:after{
              content: '';
              position: absolute;
              right: 40px;
              background-size: contain;
              overflow: hidden;
              width: 20px;
              height: 12px;
              background-image: url(${NextArrowImage});
            }
            &:hover{
              background-color: #F3F3F3;
            }
          }
          &.devProcess{
            li{
              h4{
                font-family: ${fontDin};
                font-size: 24px;
                line-height: 36px;
                font-weight: 400;
                color: #000000;
              }
              p{
                font-size: 18px;
              }
              &:after{
                content: none;
              }
            }
          }
        }
      }
      
      @media(min-width: 768px){
        .modal-content .table-responsive{
          overflow: visible;
        }
      }
      
      @media (max-width:767px) {
      .container{
        overflow: hidden;
      }
      section{
        overflow: hidden;
      }
      
        .modal-dialog{
          width: 100%;
          margin: 0;
        }
        .modal-content{
            padding: 50px 15px;
             .close{
              height: 40px;
              width: 40px;
              right: 16px;
              background-size: 20px;
            }
        }
        .modal-title h2 {
            font-size: 25px !important;
            line-height: 30px;
        }
      }
      
      
  .css-yk16xz-control,.css-1pahdxg-control{
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color:#D9D9D9 !important;
    box-shadow: none !important;
    .css-1wa3eu0-placeholder{
      font-weight: 300;
      line-height: 21px;
      color: rgba(0,0,0,0.5);
      outline: none;
    }
    .css-1okebmr-indicatorSeparator{
      display: none;
    }
    .css-tlfecz-indicatorContainer,.css-1gtu0rj-indicatorContainer{
      margin-right: 10px;
    }
  }
  .css-2613qy-menu{
    border-radius: 0 !important;
    margin-top: 0 !important;
  }
  
  .fade-up{
    transition: opacity .2s ease, transform .2s ease;
    opacity: 0;
    transform: translateY(40px);
    transition-delay: 0s;
  }
  
  .fade-right{
    span{
      opacity: 0;
    }
    &.anim-active{
      span{
        opacity: 1;
        transition:opacity .45s ease;
      }
    }
  }
  
  .anim-active{
    opacity: 1;
    transform: translateY(0px);
    transition-delay: .2s;
    transition: opacity .6s ease, transform .6s ease;
  }
  
  .info-window{
    max-width:200px;
  }
  .gm-style-iw{
    border-radius: 0 !important;
  }
  .swiper-pagination-bullet{
    outline:none;
  }
  .css-nmuc1a-menu{
    z-index: 5 !important;
  }
  
  
  .map-info__img{
    img{
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }
  .map-info__content{
    h4{
      font-size: 20px;
    }
    p{
      margin-bottom: 5px;
    }
  }
  
  .Overlay{
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;
    &.show{
      display: block;
    }
  }
  
  .form-control.has-error{
    border-color:#dc004e !important;
  }
 
 .content-loader{
   position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
 }

.loading-before-submit{
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      height: 40px;
    }
}

`;



