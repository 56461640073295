import React from 'react';
import styled from "styled-components";
import {Container, Col, Row} from "react-bootstrap";
import {baseUrl} from "../../../components/BaseUrl";
import SubTitle from "../../../components/SubTitle";
import Text from "../../../components/Text";
import ReactHtmlParser from "react-html-parser";

import BlurImg from "../../../assets/images/blur.jpg";
import Favicon from "../../../assets/images/favicon.svg";
import {fontDin} from "../../../globalStyleVars";

const MissionValue = (data) => {
    // let missionValue = data?.data?.page_data ? data.data.page_data : "";
    let missionValuePosts = data?.data?.posts?.list ? data.data.posts.list : "";
    let missionValueBannerImage = data?.data?.images?.list ? data.data.images.list.find(e => e.is_banner === 'on'):'';


    return (
        <StyledMissionValue className="mission-value" listIcon={Favicon}>
            <Container>
                <Row>
                    <Col md={12}>
                        {missionValueBannerImage ?
                            <div className="mission-value__banner">
                                <img src={baseUrl + missionValueBannerImage.path + missionValueBannerImage.img_name} alt=""/>
                            </div>
                            :
                            <div className="mission-value__banner">
                                <img src={BlurImg} alt=""/>
                            </div>
                        }
                    </Col>
                    {missionValuePosts ?
                        <Col md={12}>
                            <Row>
                                {missionValuePosts.map((item, key) =>
                                    <Col md={{size: 6, order: 1, offset: key}} className='mission-value__desc'>
                                        <SubTitle color={'#000000'} fontSize={'50'} lineHeight={'60'}
                                                  letterSpacing={'0'}
                                                  fontWeight={'300'} margin={'0 0 60px 0'}
                                                  text={item.data.title}/>
                                        <Text fontSize={'16'} fontWeight={'400'} lineHeight={'24'} color={'#000000'}
                                              letterSpacing={'0'} margin={'0 0 0 0'}>
                                            {ReactHtmlParser(item.data.description)}

                                        </Text>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                        : ''
                    }
                </Row>
            </Container>
        </StyledMissionValue>
    );
};

const StyledMissionValue = styled.section`
  &.mission-value {
    padding-bottom: 150px;

    .mission-value__banner {
      position: relative;
      padding-top: calc(600 / 1170 * 100%);
      overflow: hidden;
      margin-bottom: 100px;

      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .sub-title {
      font-family: ${fontDin};
      position: relative;
      margin-left: 100px;
      text-transform: initial;

      &:before {
        position: absolute;
        content: "";
        width: 0;
        height: 1px;
        background-color: #000000;
        left: -100px;
        top: 18px;
        transition: width 1s;

      }

      &.anim-active {
        &:before {
          transition-delay: .5s;
          width: 70px;
        }
      }
    }

    .mission-value__desc {
      .texts {
        p {
          font-family: "Poppins", SansSerif;
        }
        ul {
          li {
            position: relative;
            font-family: "Poppins", SansSerif;
            &:before {
              position: absolute;
              content: '';
              left: -25px;
              top: 10px;
              width: 10px;
              height: 10px;
              background-image: url(${props => props.listIcon || ''});
            }
          }
        }
      }

    }

  }
  
  @media(max-width: 767px){
    &.mission-value{
      padding: 0px 0 75px;
    }
  }
`;

export default MissionValue;