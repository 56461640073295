import {CONCERN} from "../../constants/concern";

let initialState = {
  concernMain: [],
  concernMainLoading: false,
  concernMainErr: ''
};

export default function concernReducer(state = initialState, action) {
  switch (action.type) {
    case CONCERN.FETCH_CONCERN_PAGE.MAIN:
      return {
        ...state,
        concernMain: [],
        concernMainLoading: true,
        concernMainErr: ''
      };

    case CONCERN.FETCH_CONCERN_PAGE.SUCCESS:
      return {
        ...state,
        concernMain: action.result,
        concernMainLoading: false,
        concernMainErr: ''
      };

    case CONCERN.FETCH_CONCERN_PAGE.FAILURE:
      return {
        ...state,
        concernMain: [],
        concernMainLoading: false,
        concernMainErr: 'Error from Concern'
      };
    default:
      return state
  }
}
