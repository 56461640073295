import {HOME} from '../../constants/home'

const initialState = {
  homeData: [],
  homeLoading: false,
  homeErr: '',
  featureData: [],
  featureLoading: false,
  featureErr: '',
  homeNewsData: [],
  homeNewsLoading: false,
  homeNewsErr: ''
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case HOME.FETCH_HOME.MAIN:
      return {
        ...state,
        homeData: [],
        homeLoading: true,
        homeErr: ''
      };
    case HOME.FETCH_HOME.SUCCESS:
      return {
        ...state,
        homeData: action.result,
        homeLoading: false,
        homeErr: '',
      };
    case HOME.FETCH_HOME.FAILURE:
      return {
        ...state,
        homeData: [],
        homeLoading: false,
        homeErr: 'Error in home',
      };
    case HOME.FETCH_FEATURED_PROJECT.MAIN:
      return {
        ...state,
        featureData: [],
        featureLoading: true,
        featureErr: ''
      };
    case HOME.FETCH_FEATURED_PROJECT.SUCCESS:
      return {
        ...state,
        featureData: action.result,
        featureLoading: false,
        featureErr: '',
      };
    case HOME.FETCH_FEATURED_PROJECT.FAILURE:
      return {
        ...state,
        featureData: [],
        featureLoading: false,
        featureErr: 'Error in Featured',
      };
    case HOME.FETCH_HOME_NEWS.MAIN:
      return {
        ...state,
        homeNewsData: [],
        homeNewsLoading: true,
        homeNewsErr: ''
      };
    case HOME.FETCH_HOME_NEWS.SUCCESS:
      return {
        ...state,
        homeNewsData: action.result,
        homeNewsLoading: false,
        homeNewsErr: '',
      };
    case HOME.FETCH_HOME_NEWS.FAILURE:
      return {
        ...state,
        homeNewsData: [],
        homeNewsLoading: false,
        homeNewsErr: 'Error in home News',
      };
    default:
      return state
  }
}
