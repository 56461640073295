import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {ApiParams} from "../../constants/apiParams";
import {fetchAboutMain, fetchAboutHistory} from "../../redux/actions/about";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import Text from '../../components/TextSection';
import bannerImg from "../../assets/images/dynamic/detail-bg.jpg";
import PartnersList from './components/partners-list';
import {fetchPartners} from "../../redux/actions/partners";


class Partners extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        let mainParam = {
            [ApiParams.page_type]: 'partners'
        };
        this.props.getPartners(mainParam);
    }

    render() {
        const {partners} = this.props;

        let pageData = partners ? partners.page_data: [];
        let postList = partners ? partners.posts ? partners.posts.list:[]:[];
        let bannerImage = partners? partners.images ? partners.images.list[0] : '' :'';

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                <InnerBanner
                    text={pageData ? pageData.title:''}
                    image={bannerImage ? baseUrl+bannerImage.path+bannerImage.img_name :''}
                />
                <PartnersList post={postList} />

            </motion.div>
        )
    }
}


function mapStateToProps(state) {
    return {
        partners: state.partnersReducer.partners,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getPartners: params => dispatch(fetchPartners(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Partners);