import React, {Component, useEffect, useRef} from 'react';
import styled from 'styled-components';

import {Container, Row, Col, Table} from "react-bootstrap";
import htmlParser from 'react-html-parser';

// img
import {baseUrl} from "../../../components/BaseUrl";
import VisibilitySensor from "react-visibility-sensor";
import BlurImg from "../../../assets/images/blur.jpg";
import ReactHtmlParser from "react-html-parser";
import Title from "../../../components/Title";

const Specification = ({post}) => {
    let thumbImageData = post?.images ? post.images.find(e => e.is_thumb === 'on') : '';
    let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
    const containerSetRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        let getOffset = containerRef.current.offsetLeft + 15;
        if (window.screen.width > 768) {
            containerSetRef.current.style.paddingLeft = getOffset + 'px';
        }
    }, [containerRef])
    return (
        <StyledSpecification className="specification">
            <Container ref={containerRef} />
            <Container fluid ref={containerSetRef}>
                <Row>
                    <Col md={6} className="specification-left p-0">
                        <div className="specification-left__img">
                            <img src={thumbImagePath} alt=""/>
                        </div>
                    </Col>

                    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                        {({isVisible}) =>
                            <Col md={6} className={`${isVisible ? 'anim-active' : ''} fade-up specification-right`}>
                                <Title fontSize={60} fontWeight={300} fontFamily={"'DIN Next LT Pro', sans-serif"}
                                       textTransform={'uppercase'} color={'#FFF'}
                                       lineHeight={60} letterSpacing={0.25} margin={'0 0 60px 0'}>
                                    {ReactHtmlParser(post?.data ? post?.data?.title : 'Specification')}
                                </Title>
                                {htmlParser(post?.data?.description)}
                            </Col>
                        }
                    </VisibilitySensor>
                </Row>
            </Container>
        </StyledSpecification>
    )
};

const StyledSpecification = styled.section`
  background-color: #182058;

  .specification-left {
    order: 2;

    &__img {
      padding-top: 102.49%;
      position: relative;
      height: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

    }
  }

  .specification-right {
    order: 1;
    padding-top: 100px;
    padding-bottom: 60px;
    padding-right: 60px;

    table {
      tr {
        border-bottom: 1px solid rgba(206,206,206,0.3);

        td {
          font-family: "Poppins", SansSerif;
          color: #FFFFFF;
          font-size: 16px;
          line-height: 24px;
          padding-top: 20px;
          padding-bottom: 20px;

          &:nth-of-type(1) {
            padding-left: 0;
            font-weight: 500;
            position: relative;
            &:before{
              position: absolute;
              content: '';
              width: 10px;
              height: 1px;
              background-color: #F9C602;
              right: 30px;
              top: 30px;
            }
          }
        }

        //&:nth-of-type(1) {
        //  td{
        //    border-top:0;
        //  }
        //}
        //&:nth-last-of-type(1){
        //  td{
        //    border-bottom:1px solid rgba(206,206,206,0.30);
        //  }
        //}
      }
    }
  }

  @media (max-width: 768px) {

    .specification-right {
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  @media (max-width: 767px) {
    .specification-right {
      padding-left: 15px;
      padding-right: 15px;

      table tr td {
        font-size: 14px;
        line-height: 21px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
      
    }
     .specification-right table tr td:nth-of-type(1):before{
       top: 22px;
       left: calc(100% + 20px);
     }
    .specification-right table tr td:nth-of-type(1){
      display: inline-flex;
      width: 50%;
    }
     .specification-right table tr td{
       width: 50%;
     }
  }

`;
export default Specification;