import React, {useEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {fetchContactPageData} from "../../redux/actions/contact";
import {connect} from 'react-redux'
import {ApiParams} from "../../constants/apiParams";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import imgs from '../../assets/images/contact-banner.jpg';
import Map from './components/Map';
import Contact from './components/ContactForm';
import {baseUrl} from "../../components/BaseUrl";
import BlurImg from "../../assets/images/blur.jpg";

const ContactUs = ({getContactData, address}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'get-in-touch'
        };
        getContactData(mainParam);
    }, []);

    let bannerImageData = address?.images?.list ? address?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;

console.log(address,'contact index');

    return (
        <motion.div variants={PageAnimation} animate="show" exit="exit" initial="hidden">
            <InnerBanner image={bannerImagePath} text={address?.page_data ? address.page_data.title:''} height={'382px'}/>
            <Map/>
            <Contact data={address}/>

        </motion.div>
    )
};

function mapStateToProps(state) {
    return {
        address: state.contactReducer.address,
    }
}

let mapDispatchToProps = dispatch => {
    return {
        getContactData: params => dispatch(fetchContactPageData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
