import {SERVICES} from "../../constants/services";

export function fetchServicesPageData(params) {
  return {
    type: SERVICES.FETCH_SERVICES_PAGE.MAIN,
    params
  }
}

export function fetchServiceListData(params) {
  return {
    type: SERVICES.FETCH_SERVICES_LIST.MAIN,
    params
  }
}

export function fetchServiceListChildData(params) {
  return {
    type: SERVICES.FETCH_SERVICES_CHILD.MAIN,
    params
  }
}

export function fetchServiceOne(params) {
  return {
    type: SERVICES.FETCH_SERVICES_ONE.MAIN,
    params
  }
}

export function fetchServiceThree(params) {
  return {
    type: SERVICES.FETCH_SERVICES_THREE.MAIN,
    params
  }
}

export function fetchServiceFour(params) {
  return {
    type: SERVICES.FETCH_SERVICES_FOUR.MAIN,
    params
  }
}
