import React, {useState} from 'react';
import {Modal} from "react-bootstrap";


const Popup = ({show, handleClose, post, title, className}) => {
    return (
        <Modal show={show} onHide={handleClose} className={className}>
            <Modal.Header closeButton>
                <Modal.Title><h2>{title}</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {post}
            </Modal.Body>
        </Modal>

    )
};


export default Popup;
