import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {CONTACT} from "../../constants/contact";
import {ApiServices} from "../../../network/ApiServices";


function* fetchContactPageData(actions){
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_PAGE_BY_TYPE_DATA,
            actions.params
        ); //true when Json Server
        yield put({
            type: CONTACT.FETCH_CONTACT_PAGE.SUCCESS,
            result: result.data
        })
    } catch (err) {
        Logger(err)
        yield put({
            type: CONTACT.FETCH_CONTACT_PAGE.FAILURE, error: "something went wrong"
        })
    }
}


export default function* contactSaga() {
    yield takeLatest(CONTACT.FETCH_CONTACT_PAGE.MAIN, fetchContactPageData)
}
