import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from './SubTitle';
import VisibilitySensor from "react-visibility-sensor";
import BlurImg from "../assets/images/blur.jpg";

const InnerBanner = ({subTitle, text, image, children, height}) => {
    return (
        <StyledBanner height={height} className="inner-banner">
            <img src={image.length ? image : BlurImg} alt=""/>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="inner-banner__content text-center">
                            {subTitle ? <SubTitle margin={'0 0 25px 0'} color={'#FFF'} text={subTitle}/> : ''}
                            <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                {({isVisible}) =>
                                    <h1 className={`${isVisible ? 'anim-active' : ''} fade-right`}>{text.split("").map(function (char, index) {
                                        return <span aria-hidden="true" key={index}>{char}</span>;
                                    })}</h1>
                                }
                            </VisibilitySensor>
                            {children}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledBanner>
    )
};

const StyledBanner = styled.div`
  height: ${props => props.height || '500px'};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .container {
    height: 100%;
    position: relative;

    .row {
      height: 100%;
    }

    .col-md-12 {
      height: 100%;
    }

    p {
      width: 80%;
    }
  }

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
  }

  .inner-banner__content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 55px;

    h1 {
      color: #ffffff;
      font-size: 50px;
      line-height: 60px;
      font-weight: 300;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .inner-banner__goDown {
    position: absolute;
    right: 0;
    bottom: 150px;
    z-index: 2;
    animation: bouncing .800s ease-in-out infinite alternate;
  }

  @media (max-width: 767px) {
    .inner-banner__goDown {
      display: none;
    }

    .inner-banner__content h1 {
      font-size: 30px;
      line-height: 36px;
    }

    height: 50vh;
  }
  //@keyframes bouncing{
  //  0%{
  //    transform: translateY(0px);
  //  }
  //  100%{
  //    transform: translateY(15px);
  //  }
  //}
`;

export default InnerBanner;
