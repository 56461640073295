import React, {Component, useEffect, useState} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import imgs from '../../../assets/images/dynamic/services.jpg'
import {hover} from "../../../globalStyleVars";
import VisibilitySensor from "react-visibility-sensor";
import {baseUrl} from "../../../components/BaseUrl";
import {Link} from 'react-router-dom';
import BlurImg from "../../../assets/images/blur.jpg";

const NewsList = ({news}) => {

    return (
        <StyledNews className='pt-120 pb-120'>
            <Container>
                <Row>
                    {news ? news.map(newsList => {
                        let thumbImg = newsList?.images?.list ? newsList.images.list.find(e => e.is_thumb === 'on') : '';
                        let thumbImgData = thumbImg?.path ? baseUrl + thumbImg?.path + thumbImg?.img_name : BlurImg;
                        return (
                                <VisibilitySensor key={newsList.page_data.id} partialVisibility={{top: 0, bottom: 60}}>
                                    {({isVisible}) =>
                                        <Col className={`${isVisible ? 'anim-active' : ''} fade-up`} md={4} sm={6}>
                                            <div className="news-single">
                                                <Link to={`/news/${newsList.page_data.slug}`}/>
                                                <div className="news-single__img">
                                                    <img
                                                        src={thumbImgData}
                                                        alt=""/>
                                                </div>
                                                <p>{newsList.page_data.sub_title}</p>
                                                <h4>{newsList.page_data.short_desc}</h4>
                                            </div>
                                        </Col>
                                    }
                                </VisibilitySensor>
                            )
                        }
                    ) : []}


                </Row>
            </Container>
        </StyledNews>
    )
};

const StyledNews = styled.section`
  background-color: rgba(238, 238, 238, 0.40);

  .col-sm-6 {
    margin-bottom: 30px;
  }

  .news-single {
    //border-bottom: 1px solid rgba(221, 221, 221, 0.51);
    padding-bottom: 20px;
    height: 100%;

    > a {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
      left: 0;
      top: 0;
    }

    &__img {
      padding-top: calc(250 / 370 * 100%);
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        transform: scale(1.01);
        transition: transform 1s ease;
      }
    }

    p{
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 12px;
      font-family: "Poppins", SansSerif;
      font-weight: 500;
      letter-spacing: 3.2px;
      line-height: 18px;
      color: #342664;
    }
    h4 {
      font-size: 24px;
      font-weight: 400;
      margin: 10px 0 0 0;
      transition: color .3s ease;
      color: #505050;
    }

    &:hover {
      img {
        transform: scale(1.04);
      }

      h4 {
        color: ${hover};
      }
    }
  }
`;

export default NewsList;