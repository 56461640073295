import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import ReactHtmlParser from "react-html-parser";
import Swiper from 'react-id-swiper';
import "swiper/swiper.scss";

import Title from "../../../components/Title";
import SubTitle from "../../../components/SubTitle";
import BlurImg from "../../../assets/images/blur.jpg";
import {baseUrl} from "../../../components/BaseUrl";
import AngelLeft from "../../../assets/images/static/angel-left.svg";
import AngelRight from "../../../assets/images/static/angel-right.svg";


const Concern = (props) => {
    let concernData = props.data;
    const containerRef = useRef(null);


    const params = {
        slidesPerView: 2,
        spaceBetween: 15,
        speed: 500,
        // centeredSlides: true,
        // loop: true,
        // containerClass: `abc`,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        // autoplay: {
        //     disableOnInteraction: true
        // },
        // loop: true,

        breakpoints: {

            768: {
                slidesPerView: 3,
                spaceBetween: 30,
                speed: 500,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                autoplay: {
                    disableOnInteraction: true
                },
            }
        }
    };

    let getOffset = containerRef?.current?.offsetLeft + 15;
    return (
        <StyledConcern className="homeConcern" containerOffset={getOffset}>
            <Container ref={containerRef}>  {/* ref={containerRef} */}
                <Row>
                    <Col md={12} className="relative_wrap">
                        <Title fontSize={60} fontWeight={300} fontFamily={"'DIN Next LT Pro', sans-serif"}
                               textTransform={'uppercase'} color={'#000000'}
                               lineHeight={60} letterSpacing={0.25} margin={'0 0 10px 0'}>
                            {ReactHtmlParser(concernData?.page_data ? concernData.page_data.title : 'Concern')}
                        </Title>
                    </Col>
                </Row>
            </Container>
            <div> {/* ref={containerSetRef} */}

                <Row>
                    <Col md={12} className="concernSliderCol">
                        <div className="concernSlider">
                            {concernData?.posts?.list ?
                                <Swiper {...params}>
                                    {
                                        concernData.posts.list.map((item, key) => (
                                            <div key={key}>
                                                <div className="Slider__Single">
                                                    <a href={item?.data?.extra_description_one !== '' ? item?.data?.extra_description_one : '#'}
                                                       target={item?.data?.extra_description_one !== '' ? '_blank' : ''}>
                                                        {item?.images ?
                                                            <div className="Slider__Single__img">
                                                                {
                                                                    item.images[0] ?
                                                                        <img
                                                                            src={baseUrl + item.images[0].path + item.images[0].img_name}
                                                                            alt=""/>
                                                                        : ''
                                                                }

                                                                {
                                                                    item.images[1] ?
                                                                        <div className="Slider__Single__img__logo">
                                                                            <img
                                                                                src={baseUrl + item.images[1].path + item.images[1].img_name}
                                                                                alt=""/>
                                                                        </div>
                                                                        : ''
                                                                }
                                                            </div>
                                                            :
                                                            <div className="Slider__Single__img">
                                                                <img src={BlurImg} alt=""/>
                                                                <div className="Slider__Single__img__logo">
                                                                    <img src={BlurImg} alt=""/>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="Slider__Single__title">
                                                            <SubTitle text={ReactHtmlParser(item?.data?.title)}
                                                                      color={'#000000'}
                                                                      fontSize={'32'} fontWeight={'300'}
                                                                      lineHeight={'32'}
                                                                      margin={'30px 0 0 50px'}
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Swiper>
                                : ''
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </StyledConcern>
    )
}

const StyledConcern = styled.section`
  &.homeConcern {
    padding-top: 100px !important;
    padding-bottom: 100px !important;

    .relative_wrap {
      position: relative;

      h2 {
        margin: 0 0 50px 0;
      }
    }

    .concernSliderCol {
      padding-right: 0;
      position: relative;
    }

    .concernSlider {
      position: relative;

      .swiper-container {
        position: relative;
        padding-left: ${props => props.containerOffset || 170}px;
        padding-right: ${props => props.containerOffset || 170}px;

        .swiper-button-disabled {
          pointer-events: none;
        }

        .swiper-button-prev, .swiper-button-next {
          position: absolute;
          width: 140px;
          height: 100%;
          z-index: 2;
          top: 0;
          //background-color: rgba(0, 0, 0, 0.1);

        }

        .swiper-button-prev {
          left: 0;

          &:before {
            position: absolute;
            content: '';
            background-image: url(${AngelLeft});
            background-repeat: no-repeat;
            width: 25px;
            height: 50px;
            background-size: 25px;
            left: 50px;
            transform: translateY(-40%);
            top: 40%;
          }

        }

        .swiper-button-next {
          right: 15px;

          &:before {
            position: absolute;
            content: '';
            background-image: url(${AngelRight});
            background-repeat: no-repeat;
            width: 25px;
            height: 50px;
            background-size: 25px;
            right: 55px;
            transform: translateY(-40%);
            top: 40%;
          }
        }
      }


      .Slider__Single {
        &__img {
          padding-top: calc(470 / 750 * 100%);
          position: relative;
          overflow: hidden;

          > img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .7s ease-in;
          }

          &__logo {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;


            > img {
              transition: all .5s ease-in;
              transform: scale(1);
              width: 330px;
            }
          }
        }

        &__title {
          //margin-top: 20px;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100px;
            height: 1px;
            background-color: #F9C602;
          }

          .sub-title {
            padding-top: 40px;
          }
        }

        a {
          &:hover {
            .Slider__Single__img {
              > img {
                transform: scale(1.1);
              }

              &__logo {
                > img {
                  transition-delay: .1s;
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
    }

  }

  @media (min-width: 991px) and (max-width: 1300px) {
  }
  @media (max-width: 990px) {
  }

  @media (max-width: 767px) {
    &.homeConcern {
      padding-top: 75px !important;
      padding-bottom: 75px !important;
      .concernSlider {
        .swiper-container {
          .swiper-button-prev, .swiper-button-next{
            width: 50px;
          }
          .swiper-button-prev{
            &:before{
              width: 25px;
              height: 50px;
              left: 15px;
            }
          }
          .swiper-button-next{
            &:before{
              width: 25px;
              height: 50px;
              right: 0;
            }
          }
        }
      }
    }

    .concernSliderCol {
      padding-right: 15px !important;
      h3 {
        margin: 30px 0px 0 30px !important;
        padding-top: 20px !important;
        line-height: 30px;
        font-size: 23px;
      }
    }
  }
`;

export default Concern;

