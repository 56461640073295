import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
// import Menu from "../../components/Menu";
import {connect} from 'react-redux'
import {fetchServiceOne} from "../../redux/actions/services/index";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import Success from './components/Success';
import Gallery from './components/Gallery';
import Map from './components/Map';
import ContactFrom from './components/ContactForm';

// img
import Banner from '../../assets/images/dynamic/banner.jpg';

class Services extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        let params = {
            [ApiParams.page_type]: 'services-01'
        };

        // if (!this.props.serviceTwoPage.length) {
        //     this.props.getServiceTwoPageData(params)
        // }

        this.props.getServiceOne(params)
    }

    render() {
        let {serviceOne} = this.props;


        let pageData = serviceOne ? serviceOne.page_data : null
        let pageTitle = pageData ? pageData.title : ''

        let bannerImagePath = serviceOne ?
            serviceOne.images ?
                serviceOne.images.list ?
                    serviceOne.images.list[0] ?
                        serviceOne.images.list[0].path + serviceOne.images.list[0].img_name : null
                    : null
                : null
            : null

        let banner = bannerImagePath ? baseUrl + bannerImagePath : '';

        let postList = serviceOne ? serviceOne.posts ? serviceOne.posts.list:'':'';




        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">

                <InnerBanner
                    // text={pageTitle}
                    text={'Design consultancy'}
                    subTitle={'services'}
                    image={banner}
                />

                <Success post={postList} />
                <Gallery post={postList} />
                {/*<Map post={postList} />*/}
                <ContactFrom post={postList} />
            </motion.div>
        )
    }
};

function mapStateToProps(state) {
    return {
        serviceOne: state.serviceReducer.serviceOne,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getServiceOne: params => dispatch(fetchServiceOne(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
