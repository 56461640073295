import React, {Component, useEffect, useState} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col, Table} from 'react-bootstrap';
import ReactHtmlParser from "react-html-parser";


const Jd = ({data}) => {

    return (
        <StyledJd className='pt-120 pb-120'>
            <Container>
                <Row>
                    <Col md={12}>
                        <Table responsive>
                            <tbody>
                            {
                                data?.posts?.list ?
                                    data?.posts?.list.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.data.title}:</td>
                                            <td>{ReactHtmlParser(item.data.description)}</td>
                                        </tr>
                                    ))
                                    :
                                    ''
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </StyledJd>
    )
};
const StyledJd = styled.section`
  table {
    tr {
      td {
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.20);
        padding: 20px 0;
        font-size: 16px;
        font-weight: 300;

        &:nth-of-type(1) {
          font-size: 18px;
          border: none;

          font-weight: 500;
          @media (min-width: 768px) {
            width: 25%;
          }
        }

      }
    }
  }

  @media (max-width: 767px) {
    table tr td {
      font-size: 15px;

      &:nth-of-type(1) {
        font-size: 16px;
        padding-right: 20px;
      }
    }
  }
`;
export default Jd;