import React, {useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../../components/PageAnimation";
import {baseUrl} from "../../../components/BaseUrl";
import {fontDin} from '../../../globalStyleVars';
import VisibilitySensor from "react-visibility-sensor";
import {Link} from "react-router-dom";
import LinkComponent from "../../../components/Link";
import SubTitle from "../../../components/SubTitle";
import ReactHtmlParser from "react-html-parser";
import BlurImg from "../../../assets/images/blur.jpg";

const ProjectsList = (props) => {

    let filteredProjects = props.uriParams.slug ? props.data ? props.data.filter(f => f.category_data ? f.category_data.slug === props.uriParams.slug : null) : null : props.data;

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <StyledProjectList className="ProjectList">
                <div className="filter">
                    <Container>
                        <Row>
                            <Col>
                                <LinkComponent text={'Ongoing'} link={'/projects/ongoing'} color={'#000000'} fontSize={'16'}
                                      fontWeight={'500'} textTransform={'uppercase'} letterSpace={'3.2'}/>
                            </Col>
                            <Col>
                                <LinkComponent text={'Upcoming'} link={'/projects/upcoming'} color={'#000000'} fontSize={'16'}
                                      fontWeight={'500'} textTransform={'uppercase'} letterSpace={'3.2'}/>
                            </Col>
                            {/*<Col>*/}
                            {/*    <LinkComponent text={'Completed'} link={'/projects/completed'} color={'#000000'} fontSize={'16'}*/}
                            {/*          fontWeight={'500'} textTransform={'uppercase'} letterSpace={'3.2'}/>*/}
                            {/*</Col>*/}
                        </Row>
                    </Container>
                </div>

                <Container>
                    <Row>
                        {
                            filteredProjects ? filteredProjects.map((productData, key) => {
                                    let thumbImg = productData?.images?.list ? productData.images.list.find(e => e.is_thumb === 'on') : '';
                                    return (
                                        <Col key={key} md={6} className="ProjectList__Col">
                                            <div className="ProjectList__Single">
                                                <Link to={'/projects/'+productData.category_data.slug +'/'+ productData.product_data.slug}>
                                                    <div className="ProjectList__Single__img">
                                                        {
                                                            thumbImg ?
                                                                <img src={baseUrl + thumbImg.path + thumbImg.img_name}
                                                                     alt=""/>
                                                                :
                                                                <img src={BlurImg}
                                                                     alt=""/>
                                                        }
                                                        <div className="exploreBtn">
                                                            <p>Explore projects</p>
                                                        </div>
                                                    </div>

                                                    <div className="ProjectList__Single__title">
                                                        <div className="titleWrapper">
                                                            <SubTitle
                                                                text={ReactHtmlParser(productData.product_data.title)}
                                                                color={'#000000'}
                                                                fontSize={'32'} fontWeight={'300'}
                                                                lineHeight={'32'}
                                                                margin={'0 0 0 0'}
                                                            />
                                                            <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                                                {({isVisible}) =>
                                                                    <p className={`${isVisible ? 'anim-active' : ''} fade-up area`}>{ReactHtmlParser(productData.product_data.location)}</p>
                                                                }
                                                            </VisibilitySensor>
                                                        </div>
                                                        <span>+</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        </Col>
                                    )
                                })
                                :
                                ''
                        }
                    </Row>
                </Container>
            </StyledProjectList>
        </motion.div>
    );
}

const StyledProjectList = styled.section`
  &.ProjectList {
    padding-top: 100px;
    padding-bottom: 100px;

    .filter {
      margin-bottom: 60px;

      .link {
        border: 1px solid #ABABAB;

        a {
          width: 100%;

          &:after {
            background-color: #0B0D5C;
          }

          &:hover {
            border-color: #0B0D5C !important;

            span {
              color: #F9C602 !important;
            }
          }
        }
      }
    }

    .ProjectList__Col {
      &:nth-of-type(odd) {
        .ProjectList__Single {
          padding-right: 50px;
        }
      }

      &:nth-of-type(even) {
        .ProjectList__Single {
          padding-left: 50px;
        }
      }
    }

    .ProjectList__Single {
      margin-bottom: 55px;

      &__img {
        padding-top: calc(600 / 520 * 100%);
        position: relative;
        overflow: hidden;

        > img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: transform 2s;
        }

        .exploreBtn {
          position: absolute;
          left: 0;
          right: 0;
          height: 70px;
          bottom: -70px;
          font-size: 24px;
          font-weight: 400;
          background-color: rgba(249, 198, 2, 0.9);
          display: flex;
          align-items: center;
          justify-content: center;     
          transition: bottom .2s ease-in;
          p{
            text-transform: uppercase;
            margin: 0;
            color: #ffffff;
          }
        }
      }

      &__title {
        margin-top: 38px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;


        & > span {
          width: 70px;
          height: 70px;
          background-color: #F9C602;
          font-size: 50px;
          font-weight: 200;
          font-family: ${fontDin};
          text-align: center;
          opacity: 0;
          transition: opacity .2s;
          color: #000000;
          line-height: 70px;
        }

        .sub-title {

        }

        .area {
          font-family: "Poppins", SansSerif;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #000000;
        }
      }

      a {
        &:hover {
          .ProjectList__Single__img {
            img {
              transform: scale(1.1);
            }
            .exploreBtn{
              bottom: 0;
            }
          }

          .ProjectList__Single__title {
            & > span {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  
  
  @media(max-width: 767px){
    &.ProjectList{
      padding: 75px 0 !important;
      .filter{
        .col:last-child{
          margin-top: 20px;
        }
      }
      
      .ProjectList__Col:nth-of-type(odd) .ProjectList__Single, .ProjectList__Col:nth-of-type(even) .ProjectList__Single{
        padding: 0 !important;
      }
      
      .ProjectList__Single__title{
        .titleWrapper{
          h3{
            font-size: 23px;
            margin: 0 !important;
          }
        }
      }

      .ProjectList__Single__title{
        margin-bottom: 30px;
      }
    }
  }
`;

export default ProjectsList;