import {NEWS} from "../../constants/news";

export function fetchNews(params) {
  return {
    type: NEWS.FETCH_NEWS.MAIN,
    params
  }
}
export function fetchNewsList(params) {
  return {
    type: NEWS.FETCH_NEWS_LIST.MAIN,
    params
  }
}

export function fetchNewsDetail(params) {
  return {
    type: NEWS.FETCH_NEWS_DETAILS.MAIN,
    params
  }
}

