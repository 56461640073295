import React, {Component, useEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {ApiParams} from "../../constants/apiParams";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import NewsDescription from './components/NewsDescription'
import RelatedNews from './components/RelatedNews'
import {fetchNewsDetail, fetchNewsList} from "../../redux/actions/news";
import BlurImg from "../../assets/images/blur.jpg";
import {useParams} from "react-router-dom";

const NewsDetails = ({getNewsList, newsList, getNewsDetail, newsDetails}) => {
    let uriParams = useParams();
    useEffect(() => {
        let childParam = {
            [ApiParams.page_id]: 37
        };
        if (newsList.length === 0) {
            getNewsList(childParam);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_slug]: uriParams.slug
        };
        getNewsDetail(mainParam);
    }, [uriParams.slug]);
    let innerBannerImageData = newsDetails?.images?.list ? newsDetails.images.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = innerBannerImageData?.path ? baseUrl + innerBannerImageData?.path + innerBannerImageData?.img_name : BlurImg;
    let newsTitle = newsDetails?.page_data ? newsDetails.page_data.sub_title : '';
    //news_tag
    let relatedNews = newsList && newsDetails ? newsList.filter(f => f.page_data ? (f.page_data?.news_tag === newsDetails.page_data?.news_tag) && (f.page_data.id != newsDetails.page_data.id) : null) : null;

    return (
        <>
            <InnerBanner image={bannerImagePath} text={newsTitle} height={'382px'}/>
            <NewsDescription data={newsDetails} newsList={newsList}/>
            <RelatedNews data={relatedNews}/>
        </>
    )
};


function mapStateToProps(state) {
    return {
        newsDetails: state.newsReducer.newsDetails,
        newsList: state.newsReducer.newsList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNewsDetail: params => dispatch(fetchNewsDetail(params)),
        getNewsList: params => dispatch(fetchNewsList(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);
