import React, {Component, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {hover} from '../../../globalStyleVars';
import ReactHtmlParser from "react-html-parser";
import SubTitle from "../../../components/SubTitle";
import LandownerImage from "../../../assets/images/dynamic/landowner-img.jpg";

const HomeLandowner = (props) => {

    return (
        <StyledConcern className="homeLandowner">
            <Container>
                <Row>
                    <Col>
                        <div className="homeLandowner__img">
                            <img src={LandownerImage} alt=""/>
                        </div>
                        <div className="homeLandowner__title">
                            <Link to={'/landowner'}>
                                <SubTitle text={ReactHtmlParser('Explore Land Owner')}
                                          color={'#000000'}
                                          fontSize={'32'} fontWeight={'300'}
                                          lineHeight={'32'}
                                />
                                <p>in more details</p>
                            </Link>

                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledConcern>
    )
}

const StyledConcern = styled.section`
  &.homeLandowner{
    background-color: #FFFEF8;
    padding-top: 100px;
    padding-bottom: 100px;
    .homeLandowner__img{
      position: relative;
      padding-top: calc(350 / 1170 * 100%);
      overflow: hidden;
      margin-bottom: 30px;
      img{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .homeLandowner__title{
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100px;
        height: 1px;
        background-color: #F9C602;
      }
      a{
        display: block;
        margin-left: 40px;
        padding-top: 30px;
        .sub-title{
          margin: 0 !important;
        }
      }
    }
  }
  @media (min-width: 991px) and (max-width: 1300px) {
  }
  @media (max-width: 990px) {
  }
  @media(max-width: 767px){
    &.homeLandowner{
      padding-top: 75px !important;
      padding-bottom: 75px !important;
      .homeLandowner__img{
        height: calc(550 / 1170 * 100%);
      }
      .homeLandowner__title{
        a{
          margin-left: 30px;
          padding-top: 20px;
        }
      }
    }
  }

`;

export default HomeLandowner;

