import React from 'react';
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import DescriptionWithImage from "../../../components/DescriptionWithImage";
import SubTitle from "../../../components/SubTitle";
import {baseUrl} from "../../../components/BaseUrl";

const ManagingDirector = (data) => {
    let managingDirectorData = data?.data?.page_data ? data.data.page_data : "";
    let missionValueThumbImage = data?.data?.images?.list ? data.data.images.list.find(e => e.is_thumb === 'on'):'';

    return (
        <StyledManagingDirector className="managing-director">
            <DescriptionWithImage
                TitleTextComponent={
                    <SubTitle
                        color={'#000000'}
                        fontSize={'24'}
                        lineHeight={'36'}
                        letterSpacing={'0'}
                        fontWeight={'400'} margin={'0 0 60px 0'}
                        text={ReactHtmlParser(managingDirectorData.title)}
                    />
                }
                bgColor={'#F6F5F3'}
                ImageUrl={baseUrl + missionValueThumbImage.path + missionValueThumbImage.img_name}
                DescText={ReactHtmlParser(managingDirectorData.short_desc)}
                PopupTitle={ReactHtmlParser(managingDirectorData.title)}
                PopupDescText={ReactHtmlParser(managingDirectorData.description)}
                popup={true}
            />
        </StyledManagingDirector>
    );
};
const StyledManagingDirector = styled.section`
  &.managing-director {
    .sub-title {
      font-family: "Poppins", SansSerif;
      position: relative;
      margin-left: 100px;
      text-transform: initial;

      &:before {
        position: absolute;
        content: "";
        width: 0;
        height: 1px;
        background-color: #000000;
        left: -100px;
        top: 18px;
        transition: width 1s;

      }

      &.anim-active {
        &:before {
          transition-delay: .5s;
          width: 70px;
        }
      }
    }
  }
  @media(max-width: 767px){
    .pt-100{
      padding-top: 75px;
    }
    .pb-100{
      padding-bottom: 75px;
    }
  }
`;

export default ManagingDirector;