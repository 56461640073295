import {SERVICES} from "../../constants/services";

let initialState = {
  servicePage: [],
  servicePageLoading: false,
  servicePageErr: '',
  servicePageList: [],
  servicePageListLoading: false,
  servicePageListErr: '',
  serviceListChild: [],
  serviceListChildLoading: false,
  serviceListChildErr: '',
  serviceOne: [],
  serviceOneLoading: false,
  serviceOneErr:'',
  serviceFour: [],
  serviceFourLoading: false,
  serviceFourErr:'',
  serviceThree: [],
  serviceThreeLoading: false,
  serviceThreeErr:'',
}

export default function serviceReducer(state = initialState, action) {
  switch(action.type) {
    case SERVICES.FETCH_SERVICES_PAGE.MAIN:
      return {
        ...state,
        servicePage: [],
        servicePageLoading: true,
        servicePageErr: ''
      }

    case SERVICES.FETCH_SERVICES_PAGE.SUCCESS:
      return {
        ...state,
        servicePage: action.result,
        servicePageLoading: false,
        servicePageErr: ''
      }

    case SERVICES.FETCH_SERVICES_PAGE.FAILURE:
      return {
        ...state,
        servicePage: [],
        servicePageLoading: false,
        servicePageErr: 'Error from service page fetching'
      }

    case SERVICES.FETCH_SERVICES_LIST.MAIN:
      return {
        ...state,
        servicePageList: [],
        servicePageListLoading: true,
        servicePageListErr: ''
      }

    case SERVICES.FETCH_SERVICES_LIST.SUCCESS:
      return {
        ...state,
        servicePageList: action.result,
        servicePageListLoading: false,
        servicePageListErr: ''
      }

    case SERVICES.FETCH_SERVICES_LIST.FAILURE:
      return {
        ...state,
        servicePageList: [],
        servicePageListLoading: false,
        servicePageListErr: 'Error from service list fetching'
      }

    case SERVICES.FETCH_SERVICES_CHILD.MAIN:
      return {
        ...state,
        serviceListChild: [],
        serviceListChildLoading: true,
        serviceListChildErr: ''
      }

    case SERVICES.FETCH_SERVICES_CHILD.SUCCESS:
      return {
        ...state,
        serviceListChild: action.result,
        serviceListChildLoading: false,
        serviceListChildErr: ''
      }

    case SERVICES.FETCH_SERVICES_CHILD.FAILURE:
      return {
        ...state,
        serviceListChild: [],
        serviceListChildLoading: false,
        serviceListChildErr: 'Error from service list child fetching'
      }

    case SERVICES.FETCH_SERVICES_ONE.MAIN:
      return {
        ...state,
        serviceOne: [],
        serviceOneLoading: true,
        serviceOneErr: ''
      }

    case SERVICES.FETCH_SERVICES_ONE.SUCCESS:
      return {
        ...state,
        serviceOne: action.result,
        serviceOneLoading: false,
        serviceOneErr: ''
      }

    case SERVICES.FETCH_SERVICES_ONE.FAILURE:
      return {
        ...state,
        serviceOne: [],
        serviceOneLoading: false,
        serviceOneErr: 'Error from service fetching'
      }

    case SERVICES.FETCH_SERVICES_FOUR.MAIN:
      return {
        ...state,
        serviceFour: [],
        serviceFourLoading: true,
        serviceFourErr: ''
      }

    case SERVICES.FETCH_SERVICES_FOUR.SUCCESS:
      return {
        ...state,
        serviceFour: action.result,
        serviceFourLoading: false,
        serviceFourErr: ''
      }

    case SERVICES.FETCH_SERVICES_FOUR.FAILURE:
      return {
        ...state,
        serviceFour: [],
        serviceFourLoading: false,
        serviceFourErr: 'Error from service fetching'
      }

    case SERVICES.FETCH_SERVICES_THREE.MAIN:
      return {
        ...state,
        serviceThree: [],
        serviceThreeLoading: true,
        serviceThreeErr: ''
      }

    case SERVICES.FETCH_SERVICES_THREE.SUCCESS:
      return {
        ...state,
        serviceThree: action.result,
        serviceThreeLoading: false,
        serviceThreeErr: ''
      }

    case SERVICES.FETCH_SERVICES_THREE.FAILURE:
      return {
        ...state,
        serviceThree: [],
        serviceThreeLoading: false,
        serviceThreeErr: 'Error from service fetching'
      }

    default:
      return state
  }
}
