import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import DescriptionWithImage from '../../../components/DescriptionWithImage';
import ReactHtmlParser from "react-html-parser";
import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux';
import {fetchHomeAboutUsData} from "../../../redux/actions/home";
import Title from "../../../components/Title";
import Link from "../../../components/Link1";
import SubTitle from "../../../components/SubTitle";
import Quotes from "../../../assets/images/static/quotes.svg"
import Career1 from "../../../assets/images/dynamic/career-1.jpg"
import Career2 from "../../../assets/images/dynamic/career-2.jpg"
import Career3 from "../../../assets/images/dynamic/career-3.jpg"


const HomeCareer = (data) => {
    return (
        <StyledComponents>
            <Container>
                <div className="careerWrapper">
                    <div className="careerItems">
                        <div className="careerItems__inner">
                            <SubTitle text={'Career'} fontSize={'12'} fontWeight={'300'} letterSpacing={'3.6'}
                                      color={'#342664'} lineHeight={'18'} margin={'0 0 28px 0'}/>
                            <SubTitle text={'join us'} fontSize={'32'} fontWeight={'300'} lineHeight={'32'}
                                      color={'#000000'} margin={'0 0 0 0'}/>
                            <Link text={'apply now'} color={'#505050'} fontSize={'16'} letterSpace={'0.8'}
                                  fontWeight={'400'} textTransform={'none'} img={''} margin={'30px 0 0 0'} link={'/career'}/>
                        </div>
                    </div>
                    <div className="careerItems">
                        <div className="careerItems__inner">
                            <img src={Career3} alt=""/>

                        </div>
                    </div>
                    <div className="careerItems">
                        <div className="careerItems__inner">
                            <img src={Career2} alt=""/>

                        </div>
                    </div>
                    <div className="careerItems">
                        <div className="careerItems__inner">
                            <img src={Career1} alt=""/>

                        </div>
                    </div>
                    <div className="careerItems">
                        <div className="careerItems__inner">
                            <p>If you believe you <br/> share the same values <br/> with us, We will offer <br/> you the
                                opportunity to <br/> shine.</p>
                        </div>
                    </div>
                </div>
            </Container>
        </StyledComponents>
    );
};


const StyledComponents = styled.section`
  .careerWrapper {
    display: grid;
    grid-template-areas:'. myArea myArea .' '. myArea myArea .';
    gap: 30px;
    padding: 100px 0;

    .careerItems {
      position: relative;

      &:not(:nth-of-type(3)) {
        padding-top: calc(200 / 270 * 100%);
      }

      &:nth-of-type(3) {
        grid-area: myArea;
        padding-top: calc(455 / 570 * 100%);
        background-color: cornflowerblue;
      }

      h3 {
        &:nth-of-type(1) {
          font-family: "Poppins", SansSerif;
        }

        &:nth-of-type(2) {
          padding-bottom: 30px;
          border-bottom: 1px solid #F9C602;
        }
      }

      .link {
        a {
          justify-content: center;
          text-transform: uppercase;
        }
      }

      &__inner {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &:last-child {
        background-color: #F9C602;

        .careerItems__inner {
          //  padding: 50px 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          letter-spacing: 0.9px;
          line-height: 27px;
          text-align: center;
          font-weight: 300;

          p {
            position: relative;
            margin: 0;

            &:before {
              content: '';
              position: absolute;
              width: 20px;
              height: 15px;
              left: 0;
              top: 0;
              background-image: url(${Quotes});
            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .careerWrapper .careerItems:last-child .careerItems__inner p {
      font-size: 10px;
      line-height: 16px;
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    .careerWrapper {
      padding: 75px 0 !important;
      display: flex !important;
      flex-direction: column !important;

      .careerItems {
        display: none;
        h3{
          font-size: 23px;
        }
        .link{
          a{
            justify-content: flex-start;
          }
        }
        &:first-child {
          order: 2;
          display: block !important;
          padding-top: calc(150 / 450 * 100%);
        }

        &:nth-of-type(2) {
          order: 1;
          display: block !important;
        }
      }
    }
  }
  
  @media(max-width: 767px){
    &.careerWrapper{
     
    }
  }
`;


export default HomeCareer;




