import React, {Component, useEffect} from 'react';
// components
import Slider from './components/BannerSlider';
import OurProject from './components/OurProjects';
import Concern from './components/Concern';
import About from './components/AboutUs';
// import Services from './components/Services';
// import Map from './components/Map';
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {fetchfeatureData, fetchHome} from "../../redux/actions/home";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import Landowner from "./components/Landowner";
import HomeNews from "./components/HomeNews";
import HomeCareer from "./components/Career";


const HomePage = ({getHome, getFeatured, homeData, featureData}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_id]: 42
        }

        getHome(mainParam);
        getFeatured();
    }, [])

    let homeBanner = homeData ? homeData.find(f => f.page_data.types === '1') : []; // Home banner slider
    let aboutData = homeData ? homeData.find(f => f.page_data.types === '4') : []; // Home about us
    let featuredProjectData = featureData ? featureData : []; // Home featured
    let homeConcern = homeData ? homeData.find(f => f.page_data.types === '28') : []; // Home concern
    let homeNews = homeData ? homeData.find(f => f.page_data.types === '29') : []; // Home News
    let pageId = homeNews?.page_data?.id;


    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <Slider data={homeBanner}/>
            <About data={aboutData}/>
            <OurProject data={featuredProjectData}/>
            <Concern data={homeConcern} />
            <Landowner />
            <HomeCareer />
            {/*<HomeNews pageId={pageId} data={homeNews} />*/}
        </motion.div>
    );
};


function mapStateToProps(state) {
    return {
        homeData: state.homeReducer.homeData,
        featureData: state.homeReducer.featureData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getHome: params => dispatch(fetchHome(params)),
        getFeatured: () => dispatch(fetchfeatureData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);



