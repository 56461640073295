import React, {useRef} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover, fontDin} from '../../../globalStyleVars';

import Swiper from 'react-id-swiper';
import "swiper/swiper.scss";
import ReactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import Links from '../../../components/Link';

import {baseUrl} from "../../../components/BaseUrl";
import Title from "../../../components/Title";
import BlurImg from "../../../assets/images/blur.jpg";

const OurProjects = (props) => {
        let featuredProjects = props?.data;

        const params = {
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 500,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            autoplay: {
                disableOnInteraction: true
            },
            breakpoints: {

                768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                    speed: 500,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    autoplay: {
                        disableOnInteraction: true
                    },
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                    speed: 500,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    autoplay: {
                        disableOnInteraction: true
                    },
                }
            }

            // loop: true,
        };

        let featuredProjectArray = featuredProjects?featuredProjects:[];
        let projects = featuredProjectArray.filter((p) => p.product_data.is_featured === 1);
        let len = projects.length;
        return (
            <StyledProject>
                <Container className='OurProject p-0'>
                    <Col sm={12}>

                        <Title fontSize={60} fontWeight={300} fontFamily={"'DIN Next LT Pro', sans-serif"}
                               textTransform={'uppercase'} color={'#000000'}
                               lineHeight={60} letterSpacing={0.25} margin={'0 0 30px 0'}>
                            {ReactHtmlParser('Luxury <br />Properties')}
                        </Title>
                    </Col>
                    <Col sm={12}>
                        <Links text={'Explore all projects'} link={'projects'} fontSize={15} fontWeight={500}
                               color={'#000000'}/>
                    </Col>

                    <Col sm={12} className="relative_wrap">
                        {len && len === projects.length ?
                            <Swiper {...params}>
                                {
                                    projects.map((item, key) => {
                                        let thumbImageData = item.images?.list ? item.images?.list.find(e => e.is_thumb === 'on') : '';
                                        let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
                                        console.log(item, 'home product');
                                        return (
                                            <div key={key} className="Slider__Single">
                                                <Link
                                                    to={'/projects/' + item.category_data.slug + '/' + item.product_data.slug}>
                                                    <div className="Slider__Single__img">
                                                        <img
                                                            src={thumbImagePath}
                                                            alt=""/>
                                                    </div>
                                                    <div className="Slider__Single__title">
                                                        <h4>{item.product_data.title}</h4>
                                                        <p>{item.product_data.location}</p>
                                                        <span>+</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </Swiper>
                            : ''}

                    </Col>
                </Container>
            </StyledProject>
        )

}

const StyledProject = styled.div`
  .OurProject {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
    .relative_wrap{
      position: relative;
    }
    .swiper-container{
      margin-top: 60px;
    }
    .Slider__Single{
      &__img{
        padding-top: calc(500 / 370 * 100%);
        position: relative;
        overflow: hidden;
        img{
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 2s ease-in;          
        }
      }
      &__title{
        display: flex;
        justify-content: space-between;
        //align-items: center;
        margin-top: 30px;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        h4 {
          margin-bottom: 0;
          font-family: ${fontDin};
          font-weight: lighter;
          font-size: 24px;
          text-transform: uppercase;
          width: 100%;
          padding-right: 50px;
        }

        span {
          width: 50px;
          height: 50px;
          background-color: ${hover};
          color: #000000;
          font-size: 35px;
          line-height: 50px;
          font-weight: lighter;
          text-align: center;
          opacity: 0;
          transition: opacity .5s ease-in;
          position: absolute;
          right: 0;
        }
      }
      a{
        &:hover{
          .Slider__Single__img{
            img{
              transform: scale(1.1);
              filter: contrast(150%);
            }
          }
          .Slider__Single__title {
            span {
              opacity: 1;
              color: #000000;
            }
          }
        }
      }
    }

    .link {
      a {
        display: inline-block;
        width: auto;
        position: relative;
        border: none !important;
        height: auto;
        &:after {
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          width: 0;
          top: auto;
          bottom: 0;
          margin: 0 auto;
          height: 1px;
          background-color: #000;
          transition: width .4s ease-in;
        }

        &:hover {
          border: none !important;

          span {
            color: ${hover}
          }

          &:after {
            width: 100%;
          }
        }
      }
    }

    .Slider__Nav {
      text-align: right;
      text-align: right;
      position: absolute;
      top: -45px;
      right: 10px;
      button {
        border: none;
        background-color: transparent;
        width: 30px;
        height: 12px;
        padding: 0;

        &.slidePrev {
          margin-right: 3px;
        }

        &.slideNext {
          margin-left: 3px;
        }

        svg {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

  }

  @media (min-width: 991px) and (max-width: 1300px) {

  }

  @media (max-width: 990px) {

  }
  
  @media(max-width: 767px){
    .OurProject{
      padding-top: 75px !important;
      padding-bottom: 75px !important;
    }
  }

`;

export default OurProjects;

