import React, {useEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {ApiParams} from "../../constants/apiParams";
import {fetchAboutMain, fetchAboutHistory, fetchAboutChild} from "../../redux/actions/about";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import TitleLeftDescRight from "../../components/TitleLeftDescRight";
import MissionValue from "./components/MissionValue";
import ManagingDirector from "./components/ManagingDirector";
import BoardOfDirector from "./components/BoardOfDirector";


const About = ({getAboutMain, getAboutHistory, getAboutChild, aboutMain, aboutHistory, aboutChild}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'about-us'
        };
        let timeLine = {
            [ApiParams.page_type]: 'about-timeline'
        };

        getAboutMain(mainParam);
        getAboutHistory(timeLine);
    }, [])

    useEffect(() => {
        if (aboutMain) {
            if (aboutMain.page_data) {
                let aboutChildId = {
                    [ApiParams.page_id]: aboutMain.page_data.id
                };
                getAboutChild(aboutChildId);
            }
        }

    }, [aboutMain])

    let whoWeAre = aboutChild ? aboutChild.find(f => f.page_data.id === 6) : []; // Who we are data
    let missionValue = aboutChild ? aboutChild.find(f => f.page_data.id === 46) : []; // Mission Value
    let chairmanMessage = aboutChild ? aboutChild.find(f => f.page_data.id === 47) : []; // Chairman Message
    let viceChairmans = aboutChild ? aboutChild.find(f => f.page_data.id === 48) : []; // Vice Chairman Message


    let bannerTitle = aboutMain ?
        aboutMain.page_data ? aboutMain.page_data.title : ''
        : '';

    let bannerImagePath = aboutMain ?
        aboutMain.images ?
            aboutMain.images.list ?
                aboutMain.images.list[0] ?
                    aboutMain.images.list[0].path + aboutMain.images.list[0].img_name : ''
                : ''
            : ''
        : '';

    let banner = bannerImagePath ? baseUrl + bannerImagePath : '';

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <InnerBanner
                text={bannerTitle}
                image={banner}
                height={'382px'}
            />
            <TitleLeftDescRight
                title={whoWeAre?.page_data?.title ? whoWeAre.page_data.title : ''}
                subtitle={'Welcome to <br /> Fanam Group'}
                description={whoWeAre?.page_data?.description ? whoWeAre.page_data.description : ''}
                backgroundColor={'#fff'}
            />
            <MissionValue data={missionValue}/>
            {/*<ManagingDirector data={chairmanMessage}/>*/}
            <BoardOfDirector data={viceChairmans}/>
        </motion.div>
    );
};

function mapStateToProps(state) {
    return {
        aboutMain: state.aboutReducer.aboutMain,
        aboutHistory: state.aboutReducer.aboutHistory,
        aboutChild: state.aboutReducer.aboutChild,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAboutMain: params => dispatch(fetchAboutMain(params)),
        getAboutHistory: params => dispatch(fetchAboutHistory(params)),
        getAboutChild: params => dispatch(fetchAboutChild(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
