import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {ApiParams} from "../../constants/apiParams";
import {fetchAboutMain, fetchAboutHistory} from "../../redux/actions/about";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import Text from '../../components/TextSection';
import BuyerForm from './components/BuyersContactForm';
import bannerImg from '../../assets/images/dynamic/detail-bg.jpg';
import {fetchBuyers} from "../../redux/actions/buyers";
import htmlParser from 'react-html-parser';

class Buyers extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'buyers'
        };
        this.props.getBuyers(mainParam);
    }

    render() {

        let {buyers} = this.props;

        let pageData =  buyers ? buyers.page_data : '';
        let BannerImage = buyers ? buyers.images ? buyers.images.list.find(f=>f.is_banner === 'on'):'':'';
        let thumbImage = buyers ? buyers.images ? buyers.images.list.find(f=>f.is_thumb === 'on'):'':'';

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                <InnerBanner
                    text={pageData ? pageData.title:''}
                    image={BannerImage ? baseUrl+BannerImage.path+BannerImage.img_name:''}
                />
                <Text justify={'justify'} DescText={pageData ? htmlParser(pageData.description) :''}/>
                <BuyerForm img={thumbImage} />

            </motion.div>
        )
    }
}


function mapStateToProps(state) {
    return {
        buyers: state.buyersReducer.buyers,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBuyers: params => dispatch(fetchBuyers(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Buyers);

