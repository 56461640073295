import React, {Component, useEffect, useState} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Text from '../../../components/Text';
import {hover} from "../../../globalStyleVars";

// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {baseUrl} from "../../../components/BaseUrl";
import VisibilitySensor from "react-visibility-sensor";

import FeatureImg from "../../../assets/images/dynamic/feature.jpg";
import Gradiant from "../../../assets/images/dynamic/gredent.svg";
import ReactHtmlParser from "react-html-parser";


const Features = ({post}) => {

    return (

        <>
            <StyledComponent backgroundImage={Gradiant}>
                <Container fluid>
                    <Row>
                        <div className="featureImage">
                            <img src={FeatureImg} alt=""/>
                            <div className="featureImageOverlayText">
                                <Container>
                                    <div className="textWrapper">
                                        <SubTitle
                                            text={ReactHtmlParser(post?.data?.title)}
                                            color={'#FFFFFF'}
                                            fontSize={'32'} fontWeight={'300'}
                                            lineHeight={'32'}
                                            margin={'0 0 30px 0'}
                                        />
                                        <Text fontSize={'16'} color={'#fff'} lineHeight={'24'} fontWeight={'400'}
                                              text={ReactHtmlParser(post?.data?.description)}/>
                                    </div>
                                </Container>
                            </div>
                        </div>
                    </Row>
                </Container>

            </StyledComponent>
        </>
    )
}


const StyledComponent = styled.section`
  .featureImage {
    width: 100%;
    position: relative;
    padding-top: calc(410 / 1366 * 100%);
    overflow: hidden;
    .container{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .featureImageOverlayText {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-image: url(${props => props.backgroundImage || ''});
        background-size: cover;
      }

      .textWrapper {
        padding-left: 150px;
        padding-right: 150px;

        .texts {
          font-family: "Poppins", SansSerif;
          p{
            font-family: "Poppins", SansSerif;
          }
        }
        ul{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 40px;
          li{
            font-family: "Poppins", SansSerif;
            list-style: disc;
            margin-right: 40px;
            color: #ffffff;
          }
        }
      }
    }
  }
  @media(max-width: 767px){
    
    .featureImage{
      min-height: 400px;
      padding: 0 !important;

      .featureImageOverlayText .textWrapper{
        padding: 0 !important;
      }
    }
     
  }
`;

export default Features;
