export const ABOUT = {
  FETCH_ABOUT_PAGE: {
    MAIN: 'FETCH_ABOUT_PAGE',
    SUCCESS: 'FETCH_ABOUT_PAGE_SUCCESS',
    FAILURE: 'FETCH_ABOUT_PAGE_FAILURE'
  },
  FETCH_ABOUT_HISTORY: {
    MAIN: 'FETCH_ABOUT_HISTORY',
    SUCCESS: 'FETCH_ABOUT_HISTORY_SUCCESS',
    FAILURE: 'FETCH_ABOUT_HISTORY_FAILURE'
  },
  FETCH_ABOUT_CHILD: {
    MAIN: 'FETCH_ABOUT_CHILD',
    SUCCESS: 'FETCH_ABOUT_CHILD_SUCCESS',
    FAILURE: 'FETCH_ABOUT_CHILD_FAILURE'
  }
}
