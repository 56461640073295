import {takeLatest, put, call} from 'redux-saga/effects'
import Logger from '../../../helpers/logger'
import {AxiosServices} from '../../../network/AxiosService';
import {ApiServices} from '../../../network/ApiServices';
import {CONCERN} from "../../constants/concern";

function* fetchConcernMain(actions) {
  try {
    const result = yield call(
      AxiosServices.get,
      ApiServices.GET_PAGE_BY_TYPE_DATA,
      actions.params
    )

    yield put({
      type: CONCERN.FETCH_CONCERN_PAGE.SUCCESS,
      result:  result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: CONCERN.FETCH_CONCERN_PAGE.FAILURE
    })
  }
}

export default function* concernSagas() {
  yield takeLatest(CONCERN.FETCH_CONCERN_PAGE.MAIN, fetchConcernMain);
}
