import React, {useEffect} from 'react';
import {fetchProjectsDetail} from "../../redux/actions/projects";
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";

import BlurImg from "../../assets/images/blur.jpg";
import InnerBanner from "../../components/InnerBannerCenteredText";
import TitleLeftDescRight from "../../components/TitleLeftDescRight";
import DetailSpecification from "./components/DetailSpecification";
import DetailGallery from "./components/DetailGallery";
import DetailFeature from "./components/DetailFeatures";
import DetailAmenities from "./components/DetailAmenities";
import DetailTestimonial from "./components/DetailTestimonial";


const ProjectDetail = ({getProjectsDetail, projectDetail}) => {
    window.scrollTo(0, 0);

    let uriParams = useParams();
    useEffect(() => {
        let params = {
            [ApiParams.project_slug]: uriParams.project_slug
        };
        getProjectsDetail(params)

    }, [uriParams.project_slug]);


    let projectData = projectDetail?.product_data ? projectDetail.product_data : '';
    let categoryData = projectDetail?.category_data ? projectDetail.category_data : '';

    let innerBannerImageData = projectDetail?.images?.list ? projectDetail.images.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = innerBannerImageData?.path ? baseUrl + innerBannerImageData?.path + innerBannerImageData?.img_name : BlurImg;

    let overviewData = projectDetail?.posts?.list ? projectDetail.posts.list.find(e => e.data.type === "30") : '';
    let specificationData = projectDetail?.posts?.list ? projectDetail.posts.list.find(e => e.data.type === "31") : '';
    let galleryData = projectDetail?.posts?.list ? projectDetail.posts.list.find(e => e.data.type === "32") : '';
    let featureData = projectDetail?.posts?.list ? projectDetail.posts.list.find(e => e.data.type === "33") : '';
    let amenitiesData = projectDetail?.posts?.list ? projectDetail.posts.list.filter(e => e.data.type === "34") : '';
    let testimonialData = projectDetail?.posts?.list ? projectDetail.posts.list.filter(e => e.data.type === "35") : '';

    return (
        <>
            <InnerBanner
                image={bannerImagePath}
                text={projectData?.title ? projectData.title : ''}
                subTitle={categoryData.title}
                height={'700px'} location={projectData?.address ? projectData.address : ''}
            />
            <TitleLeftDescRight
                title={overviewData?.data?.title}
                subtitle={overviewData?.data?.extra_description}
                description={overviewData?.data?.description}
                backgroundColor={'#FFFBEC'}
            />
            <DetailSpecification post={specificationData}/>
            {
                galleryData && galleryData.images && galleryData.images.length ?
                    <DetailGallery post={galleryData}/>
                    : ''
            }

            {
                featureData ?
                    <DetailFeature post={featureData}/>
                    : ''
            }

            {
                amenitiesData && amenitiesData.length ?
                    <DetailAmenities post={amenitiesData}/>
                    : ''
            }

            {
                testimonialData && testimonialData.length ?
                    <DetailTestimonial post={testimonialData}/>
                    : ''
            }

        </>
    )
};


let mapStateToProps = state => {
    return {
        projectDetail: state.projectsReducer.projectDetail,
    }
};

let mapDispatchToProps = dispatch => {
    return {
        getProjectsDetail: (params) => dispatch(fetchProjectsDetail(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);
