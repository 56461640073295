export const CAREER = {
  FETCH_CAREER_PAGE: {
    MAIN: 'FETCH_CAREER_PAGE',
    SUCCESS: 'FETCH_CAREER_PAGE_SUCCESS',
    FAILURE: 'FETCH_CAREER_PAGE_FAILURE'
  },
  FETCH_CAREER_LIST: {
    MAIN: 'FETCH_CAREER_LIST',
    SUCCESS: 'FETCH_CAREER_LIST_SUCCESS',
    FAILURE: 'FETCH_CAREER_LIST_FAILURE'
  },
  FETCH_CAREER_DETAIL: {
    MAIN: 'FETCH_CAREER_DETAIL',
    SUCCESS: 'FETCH_CAREER_DETAIL_SUCCESS',
    FAILURE: 'FETCH_CAREER_DETAIL_FAILURE'
  }
};
