import React, {useEffect} from 'react';
import {ApiParams} from "../../constants/apiParams";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import {Container, Row, Col} from "react-bootstrap";
import styled from "styled-components";
import {fetchConcernMain} from "../../redux/actions/concern";
import InnerBanner from '../../components/InnerBanner';

import BlurImg from "../../assets/images/blur.jpg";
import SubTitle from "../../components/SubTitle";
import ReactHtmlParser from "react-html-parser";


const About = ({getConcernMain, concernMain}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'concern'
        };

        getConcernMain(mainParam);
    }, [])

    let bannerTitle = concernMain?.page_data ? concernMain.page_data.title : '';
    let innerBannerImageData = concernMain?.images?.list ? concernMain.images.list.find(e => e.is_banner === 'on') : '';
    let innerBannerImage = innerBannerImageData?.path ? baseUrl + innerBannerImageData?.path + innerBannerImageData?.img_name : BlurImg;

    let postData = concernMain?.posts?.list ? concernMain.posts.list : '';

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <InnerBanner text={bannerTitle} image={innerBannerImage} height={'382px'}/>
            <StyledConcern className="Concern">
                <Container>
                    <Row>
                        {postData ? postData.map((item, key) =>
                                <Col key={key} md={6} className="Concern__Col">
                                    <div className="Concern__Single">
                                        <a href={item?.data?.extra_description_one !== '' ? item?.data?.extra_description_one : '#'} target={item?.data?.extra_description_one !== '' ? '_blank' : ''}>
                                            {item?.images ?
                                                <div className="Concern__Single__img">
                                                    <img src={baseUrl + item.images[0].path + item.images[0].img_name}
                                                         alt=""/>
                                                    <div className="Concern__Single__img__logo">
                                                        <img src={baseUrl + item.images[1].path + item.images[1].img_name}
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                :
                                                <div className="Concern__Single__img">
                                                    <img src={BlurImg} alt=""/>
                                                    <div className="Concern__Single__img__logo">
                                                        <img src={BlurImg} alt=""/>
                                                    </div>
                                                </div>
                                            }
                                            <div className="Concern__Single__title">
                                                <SubTitle text={ReactHtmlParser(item.data.title)}
                                                          color={'#000000'}
                                                          fontSize={'32'} fontWeight={'300'}
                                                          lineHeight={'32'}
                                                          margin={'33px 0 0 0'}
                                                />
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                            )
                            : ""
                        }

                    </Row>
                </Container>
            </StyledConcern>
        </motion.div>
    );
};

const StyledConcern = styled.div`
  &.Concern {
    padding-top: 100px;
    padding-bottom: 100px;
    .Concern__Col{
      &:nth-of-type(odd){
        .Concern__Single{
          padding-right: 50px;
        }
      }
      &:nth-of-type(even){
        .Concern__Single{
          padding-left: 50px;
        }
      }
    }
    .Concern__Single {
      margin-bottom: 55px;
      &__img {
        padding-top: calc(300 / 520 * 100%);
        position: relative;
        overflow: hidden;

        > img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: transform 2s;
        }

        &__logo {
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;


          > img {
            transition: all .5s ease-in;
            transform: scale(1.01);
            width: 330px;
            //height: 50px;
          }
        }
      }

      &__title {
        margin-top: 20px;
        position: relative;

        .sub-title {

        }
      }

      a {
        &:hover {
          .Concern__Single__img {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  
  @media(max-width: 767px){
    &.Concern{
      .Concern__Col:nth-of-type(odd) {
        .Concern__Single{
          padding-right: 0px;
        }
      }
      .Concern__Col:nth-of-type(even){
        .Concern__Single{
          padding-left: 0px;
        }
      }
      
      .Concern__Single__title{
        h3{
          font-size: 23px;
          line-height: 27px;
        }
      }
    }
  }
`;

function mapStateToProps(state) {
    return {
        concernMain: state.concernReducer.concernMain
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getConcernMain: params => dispatch(fetchConcernMain(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
