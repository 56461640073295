export const ApiServices = {
  GET_PAGE_BY_TYPE_DATA: '/get-req-data/page-by-type/',
  GET_CHILD_BY_PAGE_ID: '/get-req-data/child-pages/',
  GET_PAGE_BY_PAGE_SLUG: '/get-req-data/page-by-slug/',
  GET_FORM_FIELDS: '/get-req-data/form-fields',
  POST_FORM_SUBMIT: '/post-req-data/form-submit',
  GET_ALL_PROJECTS:'/get-req-data/all-projects',
  GET_PROJECT_DETAIL:'/get-req-data/project-by-slug',
  GET_POST_BY_SLUG:'get-req-data/posts-by-slug?page_id',
  GET_PROJECTS_BY_PARENT_CAT_SLUG:'/get-req-data/projects-by-parent-cat/',
  GET_PROJECTS_BY_CATEGORY_SLUG:'/get-req-data/projects-by-category'
};
