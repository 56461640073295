import React, {Component, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Container, Col, Row} from "react-bootstrap";

// img
import img1 from '../../../assets/images/dynamic/cr1.jpg'
import img2 from '../../../assets/images/dynamic/cr3.jpg'
import img3 from '../../../assets/images/dynamic/cr2.jpg'
import img4 from '../../../assets/images/dynamic/cr4.jpg'


import $ from "jquery";
import VisibilitySensor from "react-visibility-sensor";
import {baseUrl} from "../../../components/BaseUrl";

const PartnersList = ({post}) => {

    let findPost = post ? post.find(f=> f.data ? f.data.slug === 'partners-logo':''):'';
    let logos = findPost ? findPost.images:'';

    return (
        <StyledPartnerList className='partners-list pt-120 pb-120'>
            <Container>
                <Row>
                    {logos ? logos.map(data=>(
                        <VisibilitySensor key={data.id}  partialVisibility={{top: 0, bottom: 60}}>
                            {({isVisible}) =>
                                <Col className={`${isVisible ? 'anim-active' : ''} fade-up`} xs={6} sm={3}>
                                    <div className="partners-list__single text-center">
                                        <img src={baseUrl+data.path+data.img_name} alt=""/>
                                    </div>
                                </Col>
                            }
                        </VisibilitySensor>
                    )):''}

                </Row>
            </Container>
        </StyledPartnerList>
    )
};
// language=LESS
const StyledPartnerList = styled.section`
  background-color: rgba(238,238,238,0.40);
  .partners-list__single {
    padding-top: 80%;
    position: relative;
    border: 1px solid rgba(221, 221, 221, 0.5);
    margin-bottom: 30px;
    img {
      position: absolute;
      object-fit: contain;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      max-width:80%;
    }
  }
`;

export default PartnersList;

