import React, {Component} from 'react';
import InnerBanner from '../../components/InnerBanner';
// import Menu from "../../components/Menu";
import {connect} from 'react-redux'
import {fetchServiceFour} from "../../redux/actions/services/index";
import {ApiParams} from "../../constants/apiParams";
import {baseUrl} from "../../components/BaseUrl";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import Success from './components/Success';
import Gallery from './components/Gallery';
import Map from './components/Map';
import ContactFrom from './components/ContactForm';

// img
import Banner from '../../assets/images/dynamic/banner.jpg';
import {fetchServiceThree} from "../../redux/actions/services";

class Services extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);

        let params = {
            [ApiParams.page_type]: 'service-04'
        };

        // if (!this.props.serviceTwoPage.length) {
        //     this.props.getServiceTwoPageData(params)
        // }

        this.props.getServiceFour(params)
    }

    render() {
        let {serviceFour} = this.props;
        let pageData = serviceFour ? serviceFour.page_data : null
        let pageTitle = pageData ? pageData.title : ''

        let bannerImagePath = serviceFour ?
            serviceFour.images ?
                serviceFour.images.list ?
                    serviceFour.images.list[0] ?
                        serviceFour.images.list[0].path + serviceFour.images.list[0].img_name : null
                    : null
                : null
            : null

        let banner = bannerImagePath ? baseUrl + bannerImagePath : '';

        let postList = serviceFour ? serviceFour.posts ? serviceFour.posts.list : [] : [];

        return (
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">

                <InnerBanner
                    text={pageTitle}
                    // text={'Furniture'}
                    subTitle={'services'}
                    image={banner}
                />

                <Success post={postList} />
                <Gallery post={postList} />
                {/*<Map post={postList} />*/}
                <ContactFrom post={postList} />
            </motion.div>
        )
    }
}

function mapStateToProps(state) {
    return {
        serviceFour: state.serviceReducer.serviceFour,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getServiceFour: params => dispatch(fetchServiceFour(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
