import React, {Component, useEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {ApiParams} from "../../constants/apiParams";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import NewsList from './components/newsList'
import {fetchNews, fetchNewsList} from "../../redux/actions/news";
import BlurImg from "../../assets/images/blur.jpg";

const News = ({getNews, news, getNewsList, newsList}) =>{
    useEffect(() => {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'news'
        };
        getNews(mainParam);
    }, []);

    let pageId = news?.page_data?.id;

    useEffect(() => {
        let childParam = {
            [ApiParams.page_id]: pageId
        };
        getNewsList(childParam);
    }, [pageId]);

    let pageData = news ? news.page_data :'';
    let bannerImageData = news?.images?.list ? news?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;
    // let postList = news ? news.posts ? news.posts.list : [] : [];

    return(
        <>
            <InnerBanner image={bannerImagePath} text={pageData ? pageData.title:''} height={'382px'}/>
            <NewsList news={newsList} />
        </>
    )
};



function mapStateToProps(state) {
    return {
        news: state.newsReducer.news,
        newsList: state.newsReducer.newsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNews: params => dispatch(fetchNews(params)),
        getNewsList: params => dispatch(fetchNewsList(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(News);
