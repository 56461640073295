import React, {useEffect} from 'react';
import InnerBanner from "../../components/InnerBanner";
import {fetchProjectsMain} from "../../redux/actions/projects";
import {connect} from "react-redux";
import {ApiParams} from "../../constants/apiParams";
import ProjectsList from "./components/Projects";
import ProjectBannerImage from "../../assets/images/static/projects-banner-client.jpg"
import {
    useParams
} from "react-router-dom";


const Projects = ({getProjectMain, projectMain}) =>{
    useEffect(() => {
        window.scrollTo(0, 0);
        getProjectMain();
    }, []);

    let uriParams  = useParams();
    let projectLists = projectMain && projectMain.length > 0 ? projectMain : '';

    return(
        <>
            <InnerBanner image={ProjectBannerImage} text={'Projects'} height={'382px'}/>
            <ProjectsList uriParams={uriParams} data={projectLists}/>
        </>
    )
};
function mapStateToProps(state) {
    return {
        projectMain: state.projectsReducer.projectMain
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProjectMain: params => dispatch(fetchProjectsMain(params))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Projects);