import React, {useEffect} from 'react';
import InnerBanner from '../../components/InnerBanner';
import {ApiParams} from "../../constants/apiParams";
import {fetchLandowners} from "../../redux/actions/landowners";
import {connect} from "react-redux";
import {baseUrl} from "../../components/BaseUrl";
import {motion} from 'framer-motion';
import {PageAnimation} from "../../components/PageAnimation";
import styled from 'styled-components';
import Text from '../../components/TextSection';
import LandownersForm from './components/LandownersContactForm';
import NextArrowImage from "../../assets/images/_next-black.svg";
import htmlParser from "react-html-parser";
import TitleLeftDescRight from "../../components/TitleLeftDescRight";
import DevProcess from "../../components/DescriptionWithImage.js";
import JointBenture from "./components/JointVenture";
import BlurImg from "../../assets/images/blur.jpg";
import ReactHtmlParser from "react-html-parser";
import Title from "../../components/Title";

const DevelopmentProcess = ({data, limit}) => {
    return (
        <ul>
            {
                data.slice(0, limit ? limit : 100).map((item, key) => (

                    <li key={key}>{item.data.title}</li>
                ))
            }
        </ul>
    )
}

const DevelopmentProcessPopup = ({data}) => {
    return (
        <ul className="devProcess">
            {
                data.map((item, key) => (

                    <li key={key}>
                        <h4>{item.data.title}</h4>
                        <div className="devProcess__desc">
                            {ReactHtmlParser(item.data.description)}
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}


const Landowners = ({getLandowners, landowners}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        let mainParam = {
            [ApiParams.page_type]: 'landowners'
        };

        getLandowners(mainParam);
    }, [])

    let bannerImageData = landowners?.images?.list ? landowners?.images?.list.find(e => e.is_banner === 'on') : '';
    let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;

    let thumbImageData = landowners?.images?.list ? landowners?.images?.list.find(e => e.is_thumb === 'on') : '';
    let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;

    let DevelopmentProcessPost = landowners?.posts?.list ? landowners.posts.list.filter(f => f.data ? f.data.type === "36" : null) : null;
    let JointVenture = landowners?.posts?.list ? landowners.posts.list.filter(f => f.data ? f.data.type === "37" : null) : null;


    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <InnerBanner
                text={landowners.page_data ? landowners.page_data.title : ''}
                image={bannerImagePath}
                height={'382px'}
            />
            <TitleLeftDescRight
                title={"Fanam Group"}
                subtitle={landowners.page_data ? landowners.page_data.sub_title : ''}
                description={landowners.page_data ? landowners.page_data.description : ''}
                backgroundColor={'#fff'}
            />

            <StyledComponent className={`landownerDevProcess`}>
                <DevProcess
                    ImageUrl={thumbImagePath}
                    bgColor={'#FCFCFC'}
                    DescText={
                        <DevelopmentProcess
                            data={DevelopmentProcessPost ? DevelopmentProcessPost : []}
                            limit={3}
                        />
                    }
                    TitleTextComponent={
                        <Title
                            fontSize={60}
                            fontWeight={300}
                            fontFamily={"'DIN Next LT Pro', sans-serif"}
                            textTransform={'uppercase'}
                            color={'#000000'}
                            lineHeight={60}
                            letterSpacing={0.25}
                            margin={'0 0 30px 0'}>
                            {ReactHtmlParser('process of <br/> development')}
                        </Title>
                    }
                    popup={true}
                    ButtonText={'View All'}
                    PopupTitle={ReactHtmlParser('Process of <br/> Development')}
                    PopupDescText={
                        <DevelopmentProcessPopup data={DevelopmentProcessPost ? DevelopmentProcessPost : []}/>
                    }
                    ModalClass={'LandownerModal'}
                />
            </StyledComponent>
            <JointBenture data={JointVenture} />
            {/*/!*<Text justify={'justify'} DescText={pageData ? htmlParser(pageData.description) : ''}/>*!/*/}
            <LandownersForm img={thumbImagePath}/>

        </motion.div>
    )
}

const StyledComponent = styled.div`
  &.landownerDevProcess {
    .description-with-image {
      .container {
        > .row {
          flex-direction: row-reverse;
          justify-content: space-between;
        }
      }

      &__img {
        > .row {
          flex-direction: row-reverse;

        }
      }

      &__content {
        margin-left: 0;

        &__section {
          .texts {
            max-height: none;
          }

          ul {
            li {
              padding: 30px 100px 30px 30px;
              background-color: #F8F8F8;
              margin-bottom: 20px;
              color: #505050;
              position: relative;

              &:after {
                content: '';
                position: absolute;
                right: 40px;
                background-size: contain;
                overflow: hidden;
                width: 20px;
                height: 12px;
                background-image: url(${NextArrowImage});
              }

              &:hover {
                background-color: #F3F3F3;
              }
            }
          }
          
        }
      }

    }
     
     .description-with-image__img__section:before{
       right: 15px !important;
       left: unset !important;
     }

    .description-with-image .description-with-image__img__section{
      padding-right: 15px;
    }
  }
`;

function mapStateToProps(state) {
    return {
        landowners: state.landownersReducer.landowners,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLandowners: params => dispatch(fetchLandowners(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Landowners);
