import {createGlobalStyle,css } from 'styled-components';
import bg from './assets/images/error.jpg';

export default createGlobalStyle`
body{
  background-image: url(${bg});
  position: relative;
  height: 100vh;
  overflow: hidden;
  &:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0; 
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.70);
    //z-index: 2;
  }
}

`;