import React, {Component, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Col, Row} from "react-bootstrap";
import SubTitle from '../../../components/SubTitle';
import Text from '../../../components/Text';
import $ from "jquery";
import img from '../../../assets/images/dynamic/about.jpg'
import ReactHtmlParser from "react-html-parser";
import {baseUrl} from "../../../components/BaseUrl";

const MissionVision = ({post}) => {

    // let postData = post ? post.find(data=> data.data.slug === 'product-success'):'';
    let postData = post ? post[0]:'';
    let description = postData ? postData.data ? postData.data.description : '' : '';
    let title = postData ? postData.data ? postData.data.extra_description_one : '' : '';
    let image = postData ? postData.images ? postData.images[0] :'' :'';
    let imagePath = baseUrl + image.path + image.img_name;

    useEffect(() => {
        let getOffset = $('.container').offset().left + 15;
        if ($(window).width() > 992) {
            setTimeout(function () {
                $('.mission-vision-right').css('padding-right', getOffset);
            })
        }
    }, []);

    return (
        <StyledSuccess>
            <Container fluid>
                <Row>

                    <Col md={6} className="mission-vision-right">
                        <div className="mission-vision-right__inner">
                            <SubTitle text={title}/>
                            <Text>
                                {ReactHtmlParser(description)}
                            </Text>
                        </div>

                    </Col>

                    <Col md={6} className="mission-vision-left p-0">
                        <div className="mission-vision-left__img">
                            <img src={imagePath} alt=""/>
                        </div>

                    </Col>


                </Row>
            </Container>
        </StyledSuccess>
    )
};
const StyledSuccess = styled.section`
  .mission-vision-left {
    &__img{
      padding-top: 102.49%;
      position: relative;
      min-height: 100%;
       img{
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          object-fit:cover;
        }
    }
  }
  .mission-vision-right{
    background-color: #F8F8F8;
     padding-left: 85px;
     padding-top: 50px;
     padding-bottom: 50px;
     &__inner{
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
     }
     
     ul{
     //padding-left: 15px;
     margin-top: 20px;
      li{
       border-bottom:1px solid #DDD;
       padding-bottom: 10px;
       margin-bottom: 10px;
       &:nth-last-of-type(1){
        margin-bottom: 0;
       }
      }
     }
  }
  @media(max-width:991px){
    .mission-vision-right{
      padding-left: 35px;
    }
  }
  @media(max-width:767px){
    .mission-vision-right{
      padding-left: 15px;
    }
  }
`;

export default MissionVision;










