import React, {Component} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import InfoWindowEx from "./InfoWindowEx";
import mapStyle from '../../../components/MapStyle';
import {MAP_API_KEY} from '../../../config/index'
import SubTitle from '../../../components/SubTitle';
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube} from 'react-icons/fa';
import mapImg from '../../../assets/images/dynamic/specification.jpg';
import {Link} from 'react-router-dom'


// img
import leftBg from '../../../assets/images/contact-thumb.jpg';
import pointer from '../../../assets/images/map-pointer.svg';
import ReactHtmlParser from "react-html-parser";
import $ from "jquery";
import VisibilitySensor from "react-visibility-sensor";
import logo from '../../../assets/images/logo.svg';

const MapData = [{
    'id': 1,
    'lat': 23.79275609274159,
    'lng': 90.42427235434118,
    'info': `<div class="map-info"><div class="map-info__img"><img src=${mapImg} alt=""></div><div class="map-info__content"><h4>Fanam Group</h4> <p>Ahmed Tower (Level-23), 28-30, Kamal Atartuk Avenue, Banani, Dhaka, Bangladesh</p></div></div>`
}];

export class Maps extends Component {

    componentDidMount() {
        let getOffset = $('.container').offset().left + 15;
        if ($(window).width() > 992) {
            setTimeout(function () {
                $('.map-right').css('padding-left', getOffset);
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            currLatt: 0,
            currLng: 0,
            showHelpLink: false,
            showingInfoWindow: false,  //Hides or the shows the infoWindow
            activeMarker: {},          //Shows the active marker upon click
            selectedPlace: {},
            windowWidth: 0,
            windowHeight: 0,
            markerIcon: [
                pointer,
            ],
            selectedMarkers: '',
            clickedID: false,
            connectionId: ''
        };
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    onClose = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
                clickedID: false
            });
        }
    };

    render() {
        let zoom = 14;
        let center = {'lat': 23.79275609274159, 'lng': 90.42427235434118};

        return (
            <StyledMap>
                <Container fluid>
                    <Row>
                        {/*<Col>*/}
                        <div className="fullMap">
                            <Map
                                google={this.props.google}
                                zoom={zoom}
                                initialCenter={center}
                                onClick={this.onClose}
                                mapTypeControl={false}
                                styles={mapStyle}
                                fullscreenControl={false}
                            >
                                {MapData.map((data, idx) => {
                                    let pos = {'lat': data.lat, 'lng': data.lng}
                                    return (
                                        <Marker
                                            key={idx}
                                            position={pos}
                                            title={ReactHtmlParser(data.info)}
                                            formatted_address={data.id}
                                            name={ReactHtmlParser(data.info)}
                                            icon={{
                                                url: `${pointer}`
                                            }}
                                            onClick={this.onMarkerClick}
                                        />
                                    )
                                })}
                                <InfoWindowEx
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow}
                                    onClick={this.onClose}
                                >
                                    <div className="info-window">
                                        <div>{this.state.selectedPlace.name}</div>
                                    </div>
                                </InfoWindowEx>
                            </Map>
                        </div>
                        {/*</Col>*/}
                    </Row>
                </Container>
            </StyledMap>
        )
    }


};

const StyledMap = styled.section`
  //height: 650px;
  .fullMap {
    position: relative;
    padding-top: calc(585 / 1366 * 100%);
    overflow: hidden;
    width: 100%;

    > div {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
  }
  @media (max-width: 991px) {
    
  }

  @media (max-width: 767px) {
      .fullMap{
        padding-top: calc(1000 / 1366 * 100%);
      }

  }

`;

export default GoogleApiWrapper({
    apiKey: MAP_API_KEY
})(Maps);
