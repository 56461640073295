import { combineReducers } from 'redux'
import homeReducer from './home'
import aboutReducer from "./about";
import concernReducer from "./concern";
import projectsReducer from "./projects";
import divisionReducer from "./division";
import footerReducer from "./footer";
import serviceReducer from "./services";
import serviceTwoReducer from "./services/servicesTwo";
import careerReducer from "./career";
import mediaReducer from "./news";
import formReducer from './form'
import contactReducer from './contact'
import menuReducer from "./menu";
import landownersReducer from "./landowners";
import buyersReducer from "./buyers";
import newsReducer from "./news";
import partnersReducer from "./partners";


export default combineReducers({
  homeReducer,
  aboutReducer,
  concernReducer,
  projectsReducer,
  newsReducer,
  divisionReducer,
  footerReducer,
  serviceReducer,
  careerReducer,
  mediaReducer,
  formReducer,
  contactReducer,
  menuReducer,
  serviceTwoReducer,
  landownersReducer,
  buyersReducer,
  partnersReducer
})
