import {HOME} from "../../constants/home";

export function fetchHome(params) {
  return {
    type: HOME.FETCH_HOME.MAIN,
    params
  }
}

export function fetchfeatureData() {
  return {
    type: HOME.FETCH_FEATURED_PROJECT.MAIN,
  }
}

export function fetchHomeNews(params) {
  return {
    type: HOME.FETCH_HOME_NEWS.MAIN,
    params
  }
}
