import {PROJECTS} from "../../constants/projects";

export function fetchProjectsMain() {
    return {
        type: PROJECTS.FETCH_PROJECTS_PAGE.MAIN,
    }
}

export function fetchProjectsDetail(params) {
    return {
        type: PROJECTS.FETCH_PROJECTS_DETAIL_PAGE.MAIN,
        params
    }
}
