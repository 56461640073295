import ContentLoader from "react-content-loader";
import React from "react";

export const LoaderOne = <div className='content-loader'>
    <ContentLoader viewBox="0 0 100% 650" height={'100%'} width={"100%"}>
        <rect x="0" y="0" rx="5" ry="5" width="40%" height="20" />
        <rect x="0" y="42" rx="5" ry="5" width="100%" height="200" />
        <rect x="0" y="265" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="285" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="305" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="335" rx="5" ry="5" width="65%" height="10" />
        <rect x="75%" y="335" rx="5" ry="5" width="10%" height="10" />
        <rect x="0" y="355" rx="5" ry="5" width="65%" height="10" />
        <rect x="75%" y="355" rx="5" ry="5" width="30%" height="10" />
        <rect x="0" y="375" rx="5" ry="5" width="65%" height="10" />
        <rect x="75%" y="375" rx="5" ry="5" width="30%" height="10" />
        <rect x="0" y="395" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="395" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="415" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="415" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="445" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="445" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="465" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="465" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="485" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="485" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="505" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="505" rx="5" ry="5" width="30%" height="8" />
        <rect x="0" y="525" rx="5" ry="5" width="65%" height="8" />
        <rect x="75%" y="525" rx="5" ry="5" width="30%" height="8" />
        <rect x="75%" y="550" rx="5" ry="5" width="10%" height="10" />
        <circle cx="76.5%" cy="590" r="18" />
        <circle cx="80%" cy="590" r="18" />
        <circle cx="83.5%" cy="590" r="18" />
        <circle cx="87%" cy="590" r="18" />
        <circle cx="90.5%" cy="590" r="18" />
        <circle cx="94%" cy="590" r="18" />
    </ContentLoader>
</div>;


export const LoaderTwo = <div className='content-loader'>
    <ContentLoader
        width={800}
        height={575}
        viewBox="0 0 800 575"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"

    >
        <rect x="537" y="9" rx="2" ry="2" width="140" height="10" />
        <rect x="14" y="30" rx="2" ry="2" width="667" height="11" />
        <rect x="12" y="58" rx="2" ry="2" width="211" height="211" />
        <rect x="240" y="57" rx="2" ry="2" width="211" height="211" />
        <rect x="467" y="56" rx="2" ry="2" width="211" height="211" />
        <rect x="12" y="283" rx="2" ry="2" width="211" height="211" />
        <rect x="240" y="281" rx="2" ry="2" width="211" height="211" />
        <rect x="468" y="279" rx="2" ry="2" width="211" height="211" />
        <circle cx="286" cy="536" r="12" />
        <circle cx="319" cy="535" r="12" />
        <circle cx="353" cy="535" r="12" />
        <rect x="378" y="524" rx="0" ry="0" width="52" height="24" />
        <rect x="210" y="523" rx="0" ry="0" width="52" height="24" />
        <circle cx="210" cy="535" r="12" />
        <circle cx="428" cy="536" r="12" />
    </ContentLoader>
</div>;