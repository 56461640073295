import React, {Component, useEffect, useState} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {hover, darkGreen} from '../../../globalStyleVars';
import {baseUrl} from '../../../components/BaseUrl';
import {connect} from 'react-redux';
import {fetchHomeBannerSlider} from "../../../redux/actions/home";
import {ApiParams} from "../../../constants/apiParams";
import {LoaderOne} from '../../../components/Loader';

import bannerThumb from '../../../assets/images/dynamic/thumb-slider.jpg';


// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlurImg from "../../../assets/images/blur.jpg";
import arrowImg from "../../../assets/images/static/round-arrow-right.svg";

const sliderSpeed = 4500;

class BannerSlider extends Component {
    state = {
        leftOffset: 0,
        activeSlide: true
    };

    componentDidMount() {

    }

    render() {
        let {data} = this.props;


        let pageData = data ? data.page_data : '';
        let pagePostData = data ? data.posts?.list : '';

        let sliderArray = data?.images?.list ? data?.images?.list.filter(e => e.is_banner === 'on') : '';
        // console.log(bannerImageData, 'banner iamge');
        // let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;
        // let sliderArray = data ? data.images ? data.images.list : [] : [];


        let len = pagePostData.length;

        const settings = {
            dots: true,
            fade: true,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: sliderSpeed,
            autoplay: true,
            pauseOnHover: false,
            appendDots: dots => (
                <Container>
                    <ul>{dots}</ul>
                </Container>
            ),
            beforeChange: () => this.setState({activeSlide: false}),
            afterChange: () => this.setState({activeSlide: true})
            // nextArrow: <RightArrow/>,
            // prevArrow: <LeftArrow/>
        };


        return (
            <StyledSlide offset={this.state.leftOffset} className="banner-slider">
                {len && len === pagePostData.length ?
                    <Slick {...settings}>
                        {pagePostData ? pagePostData.map((item, key) => {
                            let bannerImageData = item?.images ? item?.images.find(e => e.is_banner === 'on') : '';
                            let bannerImagePath = bannerImageData?.path ? baseUrl + bannerImageData?.path + bannerImageData?.img_name : BlurImg;

                            let thumbImageData = item?.images ? item?.images.find(e => e.is_thumb === 'on') : '';
                            let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;

                            return (
                                <div key={item.id} className="banner-slider__single-item">
                                    {/*<img src={baseUrl + item.path + item.img_name} alt=""/>*/}

                                    <div className="banner-slider__single-item__bg"
                                         style={{backgroundImage: `url(${bannerImagePath})`}}></div>
                                    <Container>
                                        <div className="banner-slider__single-item__content">
                                            <div className="banner-slider__single-item__content__inner">
                                                <h4>{item.data.title}</h4>
                                                {/*<h4>{item.short_desc}</h4>*/}
                                                {/*<h2>{item.short_desc_2}</h2>*/}
                                                {/*<h2>{item.short_title}</h2>*/}
                                                {/*<p></p>*/}
                                            </div>
                                        </div>
                                    </Container>
                                    <div className="banner-slider__project">
                                        <div
                                            className={`banner-slider__progress ${this.state.activeSlide ? 'progress-active' : 'progress-inactive'}`}></div>
                                        <div className="banner-slider__project__content">
                                            <div className="banner-slider__project__content__data">
                                                <p>Projects</p>
                                                <h3>{item.data.extra_description_one}</h3>
                                                <a href={item.data.extra_description_two}>Read More
                                                    <span>
                                                        <img src={arrowImg} alt=""/>
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="banner-slider__project__content__img">
                                                <img src={thumbImagePath} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : ''}
                    </Slick>
                    : ''}

            </StyledSlide>
        )
    }
}

const StyledSlide = styled.section`
  &.banner-slider {
    height: 100vh;
    position: relative;
    overflow: hidden;

    .container {
      position: relative;
      height: 100%;
    }

    .banner-slider__single-item {
      height: 100vh;
      position: relative;
      z-index: 2;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
      }

      &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        transition: transform 30s linear;
      }


      &__content {
        position: absolute;
        margin: auto;
        left: 15px;
        right: 15px;
        bottom: 0;
        top: 90px;
        z-index: 25;
        height: auto;
        align-items: center;
        display: flex;
        overflow: hidden;

        &__inner {
          width: 30%;

          h4 {
            font-family: 'DIN Next LT Pro', sans-serif;
            font-size: 60px;
            line-height: 60px;
            margin: 0;
            color: rgba(255, 255, 255, 0.85);
            font-weight: normal;
            opacity: 0;
            transform: translateY(30px);
            transition: all .6s ease;

          }

          h2 {
            font-size: 72px;
            font-weight: bold;
            line-height: 48px;
            color: rgba(255, 255, 255, 0.65);
            margin: 0;
            opacity: 0;
            transform: translateY(30px);
            transition: all .6s ease;
            margin-top: 15px;
          }

        }

      }
    }

    .slick-current {
      //opacity: 1 !important;
      //transition-delay:0s !important;
      //transition: opacity 100ms ease 0s, visibility 100ms ease 0s !important;
    }

    .slick-current {
      .banner-slider__single-item__content {
        &:before {
          transition: 0.3s transform linear, 0.3s height linear 0.3s;
          transform: translateX(0);
          height: 100%;
          //border-color: rgba(65,168,67,0.80);
          border-color: rgba(255, 255, 255, 0.8);
        }

        &:after {
          transition: 0.2s transform linear 0.6s, 0.2s height linear 0.8s;
          transform: translateX(0);
          height: 100%;
          //border-color: rgba(65,168,67,0.80);
          border-color: rgba(255, 255, 255, 0.8);
        }

      }

      h2, h4 {
        transform: none;
        opacity: 1;
      }

      h4 {

        &:nth-of-type(1) {
          transition-delay: .3s;
        }

        &:nth-of-type(2) {
          transition-delay: .6s;
        }
      }

      h2 {
        transition-delay: .9s;
      }


      .banner-slider__single-item {
        &:nth-of-type(even) {
          .banner-slider__single-item__bg {
            transform: scale(1.5);

          }
        }

        &:nth-of-type(odd) {
          .banner-slider__single-item__bg {
            transform: scale(1.5);
          }
        }
      }
    }

    .slick-dots {
      //right: 0px;
      //text-align: center;
      margin: auto;
      bottom: 80px;
      //left: 0;
      ul {
        z-index: 5;
        position: absolute;
        left: 15px;
      }

      li {
        position: relative;
        width: 17px;
        height: 17px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        display: inline-flex;
        background: transparent;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;

        button {
          font-size: 0;
          line-height: 0;
          width: 6px;
          height: 6px;
          padding: 0;
          cursor: pointer;
          color: transparent;
          border: 0;
          outline: none;
          background: #FFFFFF;
          //  display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          opacity: 1;
          display: none;

          &:before {
            display: none;
          }
        }

        &:before {
          content: "";
          color: white;
          font-size: 0;
          width: 5px;
          height: 5px;
          background: white;
          border-radius: 50%;

        }

        &.slick-active {
          background: transparent;
          border: 1px solid #FFFFFF;

          button {
            opacity: 1;
          }
        }
      }
    }

    .banner-slider__project {
      position: absolute;
      right: 0;
      width: 60%;
      bottom: 0;
      background-color: #E6E2DD;
      z-index: 30;

      .banner-slider__progress {
        width: 100%;
        height: 5px;
        background-color: transparent;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 0;
          height: 100%;
          background-color: #F9C602;
        }

        &.progress-active {
          &:before {
            animation-name: progress;
            animation-duration: ${sliderSpeed - 1000}ms;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
          }
        }
      }

      &__content {
        padding-left: 80px;
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__data {
          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 1.5px;
            color: #342664;
            text-transform: uppercase;
          }

          h3 {
            font-family: 'DIN Next LT Pro', sans-serif;
            font-weight: 300;
            font-size: 24px;
            color: #000000;
          }

          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            letter-spacing: .5px;
            line-height: 23px;
            display: flex;

            span {
              height: 25px;
              width: 25px;
              z-index: 2;
              position: relative;

              img {
                margin-left: 20px;
                transition: 0.7s all ease;
              }
            }

            &:hover {
              span {
                img {
                  transform: translateX(20px);
                }
              }
            }
          }
        }

        &__img {
          width: 170px;
          height: 120px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    @media (max-width: 2560px) {
      .banner-slider__project {
        width: 40%;
      }
    }
    @media (max-width: 1920px) {
    }
    @media (max-width: 1440px) {
      .banner-slider__project {
        width: 50%;
      }
    }
    @media (max-width: 1366px) {

    }
    @media (max-width: 1280px) {

    }
    @media (max-width: 1024px) {
      .banner-slider__project {
        width: 60%;
      }
    }
    @media (max-width: 991px) {
      .banner-slider__project {
        width: 60%;
      }
    }
    @media (max-width: 768px) {
    }
    @media (max-width: 767px) {
      &.banner-slider {
        .banner-slider {
          &__single-item {
            &__content {
              &__inner{
                width: 100%;
              }
            }
          }
        }
      }
      .banner-slider__project {
        width: 100%;

        .banner-slider__project__content {
          padding: 15px;
          .banner-slider__project__content__data {
            h4 {
              font-size: 18px;
              color: #000000;
              white-space: initial;
            }
          }
        }
      }
    }

    @media (max-width: 600px) {
      .banner-slider__single-item__content {
        bottom: 100px;
        top: 120px;

        &__inner {
          width: calc(100% - 60px);

          h4 {
            font-size: 20px;
            line-height: 25px;
          }

          h2 {
            font-size: 24px;
            line-height: 34px;
          }

        }
      }

      .banner-social__inner ul {
        left: 22px;

        li {
          &:nth-of-type(2) {
            width: 25px;

          }

          a {
            font-size: 12px;
          }
        }
      }
    }
    @media (max-width: 576px) {
    }
    @media (max-width: 480px) {
    }
  }

  @keyframes progress {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }


  .banner-social {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;

    &__inner {
      position: absolute;
      margin: auto;
      display: flex;
      align-items: center;
      height: 100%;

      ul {
        transform-origin: left bottom;
        position: absolute;
        left: 28px;
        //transform: rotate(-90deg) translateX(-80px);
        display: inline-flex;
        //justify-content: center;
        align-items: center;
        transform: rotate(-90deg) translateX(-80px);

        li {
          a {
            color: #ffffff;
            font-size: 16px;
            font-weight: 500;
          }

          &:nth-of-type(2) {
            width: 35px;
            height: 2px;
            background-color: #ffffff;
            margin-right: 15px;
            margin-left: 15px;
            margin-top: 4px;
          }
        }
      }
    }
  }
`;


export default BannerSlider;
