import {all} from 'redux-saga/effects'
import homeSagas from './home'
import aboutSagas from "./about";
import concernSagas from "./concern";
import projectsSaga from "./projects";
import divisionSagas from "./division";
import footerSagas from "./footer";
import serviceSaga from "./services";
import serviceTwoSaga from "./services/servicesTwo";
import careerSaga from "./career";
import mediaSaga from "./news";
import formSaga from './form'
import contactSaga from './contact'
import menuSaga from "./menu";
import landownersSaga from "./landowners";
import buyersSaga from "./buyers";
import newsSaga from "./news";
import partnersSaga from "./partners";

function* rootSaga() {
  yield all([
    homeSagas(),
    aboutSagas(),
    concernSagas(),
    projectsSaga(),
    divisionSagas(),
    footerSagas(),
    serviceSaga(),
    careerSaga(),
    mediaSaga(),
    formSaga(),
    contactSaga(),
    menuSaga(),
    serviceTwoSaga(),
    landownersSaga(),
    buyersSaga(),
    newsSaga(),
    partnersSaga()
  ])
}

export default rootSaga
