import React, {Component, useEffect, useRef} from 'react';
import {Container, Row, Col, Form} from "react-bootstrap";
import styled from 'styled-components';
import imgs from '../../../assets/images/dynamic/about.jpg'
import SubTitle from '../../../components/SubTitle';
import Select from 'react-select';
import ButtonSubmit from '../../../components/ButtonSubmit';
import $ from "jquery";
import Title from "../../../components/Title";
import ReactHtmlParser from "react-html-parser";
import Text from "../../../components/Text";


const options = [
    {value: 'Washing', label: 'Chocolate'},
    {value: 'Knitting', label: 'Strawberry'},
    {value: 'Printing', label: 'Vanilla'}
];


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? '#41A843' : '#FFF',
        margin: 0
    }),
    menu: (provided, state) => ({
        ...provided,
        color: 'rgba(0,0,0,0.5)',
        backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin: 0
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
    }),

};


const ContactForm = () => {

    const containerRef = useRef(null);

    useEffect(() => {
        let getOffset = $('.container').offset().left + 15;
        if ($(window).width() > 992) {
            setTimeout(function () {
                $('.contact-from-left').css('padding-left', getOffset);
                $('.contact-from-right').css('padding-right', getOffset);
            })
        }
    }, []);

    return (
        <StyledContactForm>
            <Container ref={containerRef}/>
            <Container fluid className="careerFormWrapper">
                <Row>
                    <Col md={5} className='contact-from-left'>
                        <div className="contact-from-left__inner">
                            <Title fontSize={60} fontWeight={300} fontFamily={"'DIN Next LT Pro', sans-serif"}
                                   textTransform={'uppercase'} color={'#ffffff'}
                                   lineHeight={60} letterSpacing={0.25} margin={'0 0 45px 0'}>
                                {ReactHtmlParser('FILL THE <br />FORM')}
                            </Title>
                            <Text fontSize={'14'} fontWeight={'300'} lineHeight={'21'} color={'#ffffff'} letterSpacing={'0'}
                                  margin={'0 0 20px 0'}>
                                {ReactHtmlParser('<p>We are always looking for exceptional candidates who want to be part of our dynamic, fast-paced organization.</p>')}
                            </Text>
                        </div>
                    </Col>
                    <Col md={7} className='contact-from-right'>
                        <Form>
                            <Form.Group className="inline">
                                <div className="inline__inner">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Full name"/>
                                </div>

                                <div className="inline__inner">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="number" placeholder="Phone number"/>
                                </div>
                            </Form.Group>


                            <Form.Group className="inline">
                                <div className="inline__inner">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email address"/>
                                </div>

                                <div className="inline__inner">
                                    <Form.Label>Position</Form.Label>
                                    <Select styles={customStyles} className='select-here' placeholder='Select Position'
                                            options={options}/>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Cover Letter</Form.Label>
                                <Form.Control placeholder='Cover Letter' as="textarea" rows={3}/>
                            </Form.Group>

                            <Form.Group className='inline'>
                                <Form.File className='inline__inner'/>

                                <ButtonSubmit width={'100%'} text={'Apply'} fontSize={'16'}
                                              border={'1px solid #000000'} color={'#000000'}
                                              margin={'0'}/>
                            </Form.Group>

                        </Form>

                    </Col>
                </Row>
            </Container>
        </StyledContactForm>
    )
};
const StyledContactForm = styled.section`
  .careerFormWrapper {
    background-color: #342664;
  }

  .contact-from-right {
    background-color: #F8F8F8;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 100px;
    
    .inline {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;

      .inline__inner {
        width: calc(50% - 15px);
      }

    }

    .dc-btn{
      width: calc(50% - 15px);
    }

    .form-file input {
      position: relative;
      height: 50px;
      width: 100%;

      &:focus {
        outline: none;
      }

      &:after {
        font-family: "Poppins", 'Arial', SansSerif;
        content: 'Upload CV/Resume';
        height: 100%;
        width: 100%;
        border: 1px solid #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        background-color: #f8f8f8;
        cursor: pointer;
        color: #000000;
        font-size: 14px;
      }
    }

    .form-group {
      margin-bottom: 30px !important;
      label{
        font-family: "Poppins", 'Arial', SansSerif;
        font-size: 12px;
        font-weight: 300;
        line-height: 24px;
      }

      .select-here {
        font-family: "Poppins", 'Arial', SansSerif;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
      }
    }

    .form-control {
      height: 50px;
      line-height: 53px;
      color: #000000;
      font-weight: 300;
      border-radius: 0;
      border-color: #000000;
      background-color: transparent;
      padding-left: 20px;

      &::placeholder {
        font-family: "Poppins", 'Arial', SansSerif;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        color: #000000;
      }
    }

    textarea {
      max-width: 100%;
      padding-top: 15px;
      line-height: 21px !important;
      &.form-control{
        height: auto !important;
      }
    }
  }

  .contact-from-left {
    padding-top: 100px;
    padding-bottom: 100px;
    &__inner{
      padding-right: 150px;
      .texts{
        p{
          font-family: "Poppins", 'Arial', SansSerif;
        }
      }
    }
  }


  .css-yk16xz-control {
    background-color: transparent !important;
    color: #000000 !important;
    border-color: #000000 !important;

    .css-1uccc91-singleValue {
      color: #000000 !important;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder {
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #000000 !important;

  }

  @media (max-width: 768px) {
    .contact-from-left {
      min-width: 100%;
      padding-left: 35px;
      padding-right: 35px;
    }
    .contact-from-right{
      min-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (max-width: 767px) {
     .contact-from-right {
      background-color: #F8F8F8;
      padding-top: 75px;
      padding-bottom: 75px;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
     .contact-from-left__inner {
      padding-right: 15px;
      padding-top: 75px;
      padding-bottom: 75px;
    }
    
    .row {
      flex-direction: row-reverse;
    }

    .sub-title {
      text-align: center;
    }

    .contact-from-left {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .contact-from-left .form-group {
      margin-bottom: 16px !important;
    }
  }

`;

export default ContactForm;
