import React, {Component, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Col, Row} from "react-bootstrap";
import SubTitle from './SubTitle';
import Text from './Text';
import $ from "jquery";
import ReactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";


const TitleLeftDescRight = ({title, subtitle, description, backgroundColor}) => {

    return (
        <StyledWhoWeAre className="who-we-are" backgroundColor={backgroundColor}>
            <Container>
                <Row>
                    <Col md={5} className='who-we-are-left'>
                        <SubTitle color={'#000000'} fontSize={'24'} lineHeight={'36'} letterSpacing={'0'}
                                  fontWeight={'400'} margin={'0 0 60px 0'}
                                  text={title ? title : ''}/>

                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                            {({isVisible}) =>
                                <h2 className={`${isVisible ? 'anim-active' : ''} fade-up`}>
                                    {ReactHtmlParser(subtitle ? subtitle : '')}
                                </h2>
                            }
                        </VisibilitySensor>
                    </Col>

                    <Col md={7} className='who-we-are-right'>
                        <Text fontSize={'16'} fontWeight={'400'} lineHeight={'24'} color={'#505050'} letterSpacing={'0'}
                              margin={'0 0 20px 0'}>
                            {ReactHtmlParser(description ? description : '')}
                        </Text>
                    </Col>
                </Row>

            </Container>
        </StyledWhoWeAre>
    )
};
const StyledWhoWeAre = styled.section`
  &.who-we-are{
    padding-top: 150px;
    padding-bottom: 150px;
    background-color: ${props => props.backgroundColor || '#FFF'};;
  }
  .who-we-are-left {
    //padding-right: 85px;
    //padding-left: 85px;
    .sub-title {
      font-family: "Poppins", SansSerif;
      position: relative;
      margin-left: 100px;
      text-transform: initial;

      &:before {
        position: absolute;
        content: "";
        width: 0;
        height: 1px;
        background-color: #000000;
        left: -100px;
        top: 18px;
        transition: width 1s;

      }

      &.anim-active {
        &:before {
          transition-delay: .5s;
          width: 70px;
        }
      }
    }

    h2 {
      font-size: 60px;
      line-height: 60px;
      color: #000000;
      font-weight: 200;
      text-transform: uppercase;

      span {
        font-weight: bold;
      }
    }

  }

  .who-we-are-right {
    //padding-left: 85px;
    //padding-right: 90px;

    padding-top: 95px;
  }

  @media (max-width: 991px) {
    .who-we-are-left {
      min-width: 100%;
      //padding-right: 30px;
      //padding-left: 30px;
    }

    .who-we-are-right {
      min-width: 100%;
      //padding-right: 30px;
      //padding-left: 30px;
    }
  }
  @media (max-width: 767px) {
    .who-we-are-left {
      //padding-right: 15px;
      //padding-left: 15px;

      h2 {
        font-size: 30px;
        line-height: 40px;
      }
    }

    .who-we-are-right {
      padding-top: 0;
      //padding-right: 15px;
      //padding-left: 15px;
    }
  }

  .texts {
    p {
      text-align: justify;
    }
  }
  
  @media(max-width: 767px){
    &.who-we-are{
      padding: 70px 0px !important;
      .who-we-are-left h2{
        margin-bottom: 25px !important;
      }
    }
  }
`;
export default TitleLeftDescRight;
