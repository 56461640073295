import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";
import ReactHtmlParser from "react-html-parser";
import Title from "../../../components/Title";

const JointVenture = (post) => {
    let jointVentureData = post.data ? post.data[0] : '';
    let jointVentureImageData = jointVentureData.images ? jointVentureData.images : '';
    let thumbImageData = jointVentureImageData ? jointVentureImageData.find(e => e.is_thumb === 'on') : '';
    let thumbImagePath = thumbImageData?.path ? baseUrl + thumbImageData?.path + thumbImageData?.img_name : BlurImg;
    return (
        <StyledComponent className={'JointVenture'}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <Title
                            fontSize={30}
                            fontWeight={300}
                            fontFamily={"'DIN Next LT Pro', sans-serif"}
                            textTransform={'uppercase'}
                            color={'#000000'}
                            lineHeight={30}
                            letterSpacing={0.25}
                            margin={'0 0 50px 0'}>
                            {ReactHtmlParser(jointVentureData?.data ? jointVentureData.data.title : '')}
                        </Title>
                        <div className="JointVenture__Process">
                            <div className="JointVenture__Process__img">
                                <img src={thumbImagePath} alt=""/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  &.JointVenture {
    padding-top: 100px;
    padding-bottom: 100px;


    .title {
      text-align: center;
      //font-size: 50px;
    }

    .JointVenture__Process {
      padding: 0 50px;
      &__img{
        padding-top: calc(600 / 1034 * 100%);
        position: relative;

        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  
  @media(max-width: 767px){
    &.JointVenture{
      padding: 75px 0;
      .title{
        font-size: 30px;
        line-height: 35px;
      }
    }
  }
`;

export default JointVenture;