import React, {useEffect, useRef} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import SubTitle from '../../../components/SubTitle';
import Text from '../../../components/Text';
import htmlParser from "react-html-parser";
import {Link} from 'react-router-dom';

import Swiper from 'react-id-swiper';
import "swiper/swiper.scss";
import bgOverlay from '../../../assets/images/detail-overlay.png'
import Title from "../../../components/Title";
import ReactHtmlParser from "react-html-parser";
import {baseUrl} from "../../../components/BaseUrl";
import BlurImg from "../../../assets/images/blur.jpg";


const JobContent = ({list}) => {
    // const containerRef = useRef();

    // const swiperRef = useRef();
    // const goNext = () => {
    //     if (swiperRef.current && swiperRef.current.swiper) {
    //         swiperRef.current.swiper.slideNext();
    //     }
    // };
    // const goPrev = () => {
    //     if (swiperRef.current && swiperRef.current.swiper) {
    //         swiperRef.current.swiper.slidePrev();
    //     }
    // };


    const params = {
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 500,
        // centeredSlides: true,
        // loop: true,
        // containerClass: `abc`,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {

            768: {
                slidesPerView: 3,
                spaceBetween: 30,
                speed: 500,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                autoplay: {
                    disableOnInteraction: true
                },
            }
        }
        // autoplay: {
        //     disableOnInteraction: true
        // },
        // loop: true,
    };


    // let postData = posts ? posts.find(data => data.data.slug === 'why-join-us') : '';
    // let description = postData ? postData.data ? postData.data.description : '' : '';
    // let title = postData ? postData.data ? postData.data.title : '' : '';
    //
    // let jobList = posts ? posts.find(data => data.data.slug === 'recent-jobs') : '';
    // let jobListImage = jobList ? jobList.images ? jobList.images : '' : '';


    // useEffect(() => {
    //
    //     if (list) {
    //         document.querySelector('.swiper-container').style.paddingLeft = containerRef.current.offsetLeft + 15 + 'px';
    //     }
    //
    // }, [list])


    return (
        <StyledSlide className="job-slider pt-120 pb-120">
            <Container>
                <Row>
                    <Col sm={12}>
                        <SubTitle color={'#000000'} fontSize={'24'} lineHeight={'36'}
                                  letterSpacing={'0'}
                                  fontWeight={'400'} margin={'0 0 60px 0'}
                                  text={ReactHtmlParser('Join Us')}/>
                        <Title fontSize={60} fontWeight={300} fontFamily={"'DIN Next LT Pro', sans-serif"}
                               textTransform={'uppercase'} color={'#000000'}
                               lineHeight={60} letterSpacing={0.25} margin={'0 0 10px 0'}>
                            {ReactHtmlParser('Available jobs')}
                        </Title>
                    </Col>
                </Row>
            </Container>

            <Container> {/* ref={containerSetRef} */}
                <div className="jobSliderCol">
                    <div className="jobSlider">
                        <Row>

                            {list && list.length ?
                                list && list.map((item, key) => (
                                    <Col md={4} key={key} className="Slider__Single">
                                        <Link to={'/career/apply/' + item.page_data.slug}>
                                            <div className="Slider__Single__content">
                                                <h3>{item.page_data.title}</h3>
                                                <p>Dead line: <span>{item.page_data.sub_title}</span></p>
                                            </div>
                                        </Link>
                                    </Col>
                                ))
                                : ''
                            }
                        </Row>

                    </div>
                </div>
            </Container>
        </StyledSlide>

    )
}


const StyledSlide = styled.section`
  &.job-slider {
    padding-top: 100px !important;
    padding-bottom: 100px !important;

    .sub-title {
      font-family: "Poppins", SansSerif;
      position: relative;
      margin-left: 100px;
      text-transform: initial;

      &:before {
        position: absolute;
        content: "";
        width: 0;
        height: 1px;
        background-color: #000000;
        left: -100px;
        top: 18px;
        transition: width 1s;

      }

      &.anim-active {
        &:before {
          transition-delay: .5s;
          width: 70px;
        }
      }
    }

    .jobSliderCol {
      padding-right: 0;
    }

    .jobSlider {


      .Slider__Single {
        &__content {
          padding: 30px;
          background-color: #F6F6F6;

          h3 {
            font-family: "DIN Next LT Pro", 'Arial', SansSerif;
            font-size: 24px;
            font-weight: 400;
            line-height: 27px;
            color: #505050;
            margin-bottom: 45px;
            min-height: 60px;
          }

          p {
            font-family: "Poppins", 'Arial', SansSerif;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 0;

            span {
              color: #F9C602;
            }
          }
        }

        a {
          &:hover {
            .Slider__Single__img {
              > img {
                transform: scale(1.1);
              }

              &__logo {
                > img {
                  transition-delay: .1s;
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
    }

    .Slider__Nav {
      text-align: right;

      button {
        border: none;
        background-color: transparent;
        width: 30px;
        height: 12px;
        padding: 0;

        &.slidePrev {
          margin-right: 15px;
        }

        &.slideNext {
          margin-left: 15px;
        }

        svg {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .services-slider-single__inner__content {
      position: absolute;

      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      padding: 20px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

    }

    .go-left {
      left: -14px;

      img {
        height: 11px
      }
    }

    .go-right {
      right: -14px;

      img {
        height: 11px
      }
    }
  }
  @media (max-width: 767px) {
    .services-slider-single {
      &__inner {
        &__content {
          h4 {
            font-size: 20px;
            line-height: 23px;
          }
        }
      }
    }
    .Slider__Single{
      margin-bottom: 30px;
    }

  }
`;

export default JobContent;
