import React from 'react';
import {hover, darkGreen} from '../globalStyleVars';
import styled from 'styled-components';
import arrowImg from '../assets/images/btn-arrow-white.svg'
import {Link} from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

const Links = ({text, link, img, color, margin, letterSpace, fontSize, textTransform, fontWeight}) => {

    return (
        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) =>
                <StyledLink className={`${isVisible ? 'anim-active' : ''} fade-up title link`}
                            margin={margin} letterSpace={letterSpace} fontWeight={fontWeight}
                            textTransform={textTransform} fontSize={fontSize} color={color}>

                    <Link to={link || '/'}><span>{text}</span></Link>
                </StyledLink>
            }
        </VisibilitySensor>
    )
};

const StyledLink = styled.div`
    margin:${props => props.margin || '0'}px;
    a{
        margin: 0;
        display: inline-flex;
        color: ${props => props.color || '#FFF'};
        font-size:${props => props.fontSize || 12}px;
        font-weight:${props => props.fontWeight || 300};
        letter-spacing:${props => props.letterSpace || '2'}px;
        text-transform:${props => props.textTransform || 'uppercase'};
        line-height: 24px;
        transition:all .3s ease;
        border:1px solid #FFF;
        align-items: center;
        width: 155px;
        height: 50px;
        position: relative;
        &:after{
          height: 0;
          background-color: ${hover};
          top: 0;
          left: 0;
          width: 100%;
          content: '';
          position: absolute;
          transition:height .4s ease;
        }
        span {
            height: 100%;
            width: 100%;
            z-index: 2;
            position: relative;
            transition: all .3s ease !important;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color:${props=> props.color || `${hover}`};
        }
      &:hover{
            border-color: ${hover} !important;
             span {
             color: #ffffff;
              img{
                transform: translateX(5px);
              }
            }
            &:after {
              height:100%;
            }
          }  
  }
`;

export default Links;