import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import ReactHtmlParser from "react-html-parser";
import Swiper from 'react-id-swiper';
import "swiper/swiper.scss";

import SubTitle from "../../../components/SubTitle";
import Text from "../../../components/Text";
import Title from "../../../components/Title";

const DetailAmenities = ({post}) => {
    const containerRef = useRef(null);

    const swiperRef = useRef(null);
    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };
    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 500,
        // centeredSlides: true,
        // loop: true,
        // containerClass: `abc`,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        // autoplay: {
        //     disableOnInteraction: true
        // },
        // loop: true,

        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
                speed: 500,
                // centeredSlides: true,
                // loop: true,
                // containerClass: `abc`,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            }
        }
    };

    let getOffset = containerRef?.current?.offsetLeft + 15;


    return (
        <StyledComponent className="projectAmenities" containerOffset={getOffset}>
            <Container ref={containerRef}>
                <Row>
                    <Col sm={12}>
                        <div className="Slider__Nav">
                            <button className="slidePrev" onClick={goPrev}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="22.829"
                                     viewBox="0 0 42 22.829">
                                    <g id="Component_17_32" data-name="Component 17 – 32"
                                       transform="translate(1 1.414)">
                                        <line id="Line_55" data-name="Line 55" x1="40"
                                              transform="translate(40 10) rotate(180)" fill="none" stroke="#ffffff"
                                              stroke-linecap="round" stroke-width="2"/>
                                        <path id="Path_1164" data-name="Path 1164" d="M28.965,0l10,10-10,10"
                                              transform="translate(38.965 20) rotate(180)"
                                              fill="none" stroke="#ffffff" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </button>
                            <button className="slideNext" onClick={goNext}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="22.829"
                                     viewBox="0 0 42 22.829">
                                    <g id="Component_17_33" data-name="Component 17 – 33"
                                       transform="translate(1 1.414)">
                                        <line id="Line_55" data-name="Line 55" x1="40" transform="translate(0 10)"
                                              fill="none" stroke="#ffffff"
                                              stroke-linecap="round" stroke-width="2"/>
                                        <path id="Path_1164" data-name="Path 1164" d="M28.965,0l10,10-10,10"
                                              transform="translate(1.035)" fill="none"
                                              stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="2"/>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <div className="projectAmenitiesSliderCol">
                        <div className="projectAmenitiesSlider">
                            {
                                post && post.length !== 0 ?
                                    <Swiper {...params} ref={swiperRef}>
                                        {
                                            post.length && post.map((item, key) =>
                                                <div key={key}>
                                                    <div className="Slider__Single">
                                                        <SubTitle
                                                            text={ReactHtmlParser(item.data.title)}
                                                            color={'#FFFFFF'}
                                                            fontSize={'24'} fontWeight={'300'}
                                                            lineHeight={'32'}
                                                            margin={'0 0 30px 0'}
                                                        />
                                                        <Text
                                                            text={ReactHtmlParser(item.data.description)}
                                                            fontSize={'16'} fontWeight={'400'} lineHeight={'24'}
                                                            color={'#FFFFFF'}/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Swiper>
                                    : ''
                            }
                        </div>
                    </div>
                </Row>
            </Container>
        </StyledComponent>
    )
}

const StyledComponent = styled.section`
  background-color: #182058;
  padding-top: 120px;
  padding-bottom: 120px;

  .projectAmenitiesSlider {
    .swiper-container {
      padding-left: ${props => props.containerOffset || 170}px;
      padding-right: ${props => props.containerOffset || 170}px;
    }

    .Slider__Single {
      border: 2px solid #ffffff;
      padding: 70px;

      .texts {
        font-family: "Poppins", SansSerif;
        height: 70px;
        overflow: hidden;

        p {
          font-family: "Poppins", SansSerif;
        }
      }
    }
  }

  .Slider__Nav {
    text-align: right;
    margin-bottom: 20px;
    button {
      border: none;
      background-color: transparent;
      width: 30px;
      height: 12px;
      padding: 0;

      &.slidePrev {
        margin-right: 6px;
      }

      &.slideNext {
        margin-left: 6px;
      }

      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  @media (min-width: 991px) and (max-width: 1300px) {
  }
  @media (max-width: 990px) {
  }
  @media(max-width: 767px){
    .swiper-container{
      padding-left: 15px !important;
      padding-right: 0 !important;
    }
  }

`;

export default DetailAmenities;

