import React, {Component, useEffect, useState} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {baseUrl} from "../../../components/BaseUrl";
import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";

// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RightIcon from "../../../assets/images/next-black.svg";
import LeftIcon from "../../../assets/images/prev-black.svg";
import VisibilitySensor from "react-visibility-sensor";


function RightArrow(props) {
    const {className, style, onClick} = props;
    return (
        <li className='go-right' onClick={onClick}><img src={RightIcon} alt=""/></li>
    );
}

function LeftArrow(props) {
    const {className, style, onClick} = props;
    return (
        <li className='go-left' onClick={onClick}><img src={LeftIcon} alt=""/></li>
    );
}

const PhotoItem = ({image, thumb, group}) => (
    <LightgalleryItem group={group} src={image} thumb={thumb}>
        <img src={image} style={{width: "100%"}}/>
    </LightgalleryItem>

);


const Gallery = ({post}) => {
    let postData = post ? post.find(data => data.data.slug === 'gallery') : '';
    let image = postData ? postData.images : '';

    const settings = {
        dots: false,
        // fade: true,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplaySpeed: 7500,
        autoplay: false,
        arrows: true,
        pauseOnHover: false,
        nextArrow: <RightArrow/>,
        prevArrow: <LeftArrow/>,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4500
                }
            },
        ]
    };


    return (
        <StyledGalery className="team-slider pt-120 pb-120">
            <Container>
                <Row>
                    <div className="team-slider__inner">

                        <LightgalleryProvider
                            lightgallerySettings={
                                {
                                    download: false,
                                    thumbnail: false,
                                    fullScreen: false,
                                    share: false
                                }
                            }
                        >

                            {image ? image.map(image => (

                                <Col key={image.id} md={4}>
                                    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                                        {({isVisible}) =>
                                            <div className={`${isVisible ? 'anim-active' : ''} fade-up team-slider-single`}>
                                                <div className="team-slider-single__inner">
                                                    <PhotoItem image={baseUrl + image.path + image.img_name}
                                                               thumb={baseUrl + image.path + image.img_name}
                                                               group="group1"/>
                                                    {/*<img src={g1} alt=""/>*/}
                                                   <div className="text-wrap">
                                                       <p>{image.short_title}</p>
                                                       {image.short_title ? <h4>{image.short_desc}</h4> :null }
                                                   </div>
                                                </div>
                                            </div>
                                        }
                                    </VisibilitySensor>
                                </Col>
                            )) : ''}


                        </LightgalleryProvider>


                    </div>
                </Row>
            </Container>
        </StyledGalery>
    )
};

const StyledGalery = styled.section`
  .sub-title{
    text-align: center;
  }
  
  .team-slider{
  &__inner{
   display: flex;
   flex-wrap: wrap;
   width: 100%;
  }
 
    &-single{
    display: flex;
    margin-bottom: 30px;
      &__inner{
      
        padding-top: 121.62%;
        position: relative;
        width: 100%;
        overflow: hidden;
        .react_lightgallery_item{
           position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
        }
        img{
         width: 100%;
         height: 100%;
          object-fit: cover;
          cursor: pointer;
          transform: scale(1.01);
          transition:transform 1s ease;
        }
        .text-wrap{
           position: absolute;
            bottom: 0;
            width: 100%;
            margin: 0;
            padding: 10px;
            background: rgba(0,0,0,0.73);
            p{
             color: #FFF;
             margin-bottom: 0px;
             font-size: 15px;
             line-height: 17px;
            }
            h4{
              color: #FFF;
              font-size: 13px;
              margin: 5px 0 0 0;
            }
        }
        &:hover{
          img{
            transform: scale(1.04);
          }
        }
        
      }
      
    }
  }
 
 .go-left{
    position: absolute;
    left: -45px;
    bottom: 0;
    height: fit-content;
    margin: auto;
    top: 0;
    cursor: pointer;
    img{
      height: 30px;
    }
 }
 .go-right{
    position: absolute;
    right: -45px;
    bottom: 0;
    height: fit-content;
    margin: auto;
    top: 0;
    cursor: pointer;
    img{
      height: 30px;
    }
 }
 .slick-list> div {
  margin-left:0;
 }
 @media(max-width:768px){
  .go-left{
    left: -14px;
    img{
      height: 11px
    }
  }
  .go-right{
    right: -14px;
    img{
      height: 11px
    }
  }
 }
 @media(max-width:767px){
  
    .team-slider__inner{
    
       .col-md-4{
          max-width: 50%;
          &:nth-child(odd){
            padding-right: 8px;
          }
          &:nth-child(even){
            padding-left: 8px;
          }
        }
    }
   .team-slider-single{
    margin-bottom: 16px;
   }
   
 }
`;

export default Gallery;
