import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import SubTitle from "./SubTitle";
import {Link} from "react-router-dom";

const Footer1 = () => {
    const FooterLogo = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 40.718">
            <g id="Group_1704" data-name="Group 1704" transform="translate(-60.22 -57.673)">
                <g id="Group_1702" data-name="Group 1702" transform="translate(60.22 57.673)">
                    <path id="Path_1275" data-name="Path 1275"
                          d="M78.042,57.673H75.523a15.3,15.3,0,0,0-15.3,15.3V82.43A15.849,15.849,0,0,1,76.07,66.582h1.972v-.074h15.54c.069,0,.139,0,.205,0a6.862,6.862,0,0,0,6.66-6.866V57.673Z"
                          transform="translate(-60.22 -57.673)" fill="#fc0"/>
                    <path id="Path_1276" data-name="Path 1276"
                          d="M78.042,88.149H75.523a15.3,15.3,0,0,0-15.3,15.3v9.453A15.848,15.848,0,0,1,76.07,97.058h1.972v-.073h3.207c.069,0,.138,0,.205,0a6.87,6.87,0,0,0,6.66-6.866V88.149Z"
                          transform="translate(-60.22 -72.188)" fill="#fc0"/>
                </g>
                <g id="Group_1703" data-name="Group 1703" transform="translate(105.98 61.025)">
                    <path id="Path_1277" data-name="Path 1277"
                          d="M161.761,72.207h-9.2V69.2h10.371V64.644h-15.34V83.849h4.97v-7.09h9.2Z"
                          transform="translate(-147.593 -64.345)" fill="#342664"/>
                    <path id="Path_1278" data-name="Path 1278"
                          d="M181.154,64.407l-8.845,19.329h5.259l1.876-4.2H186.9l1.85,4.2h5.367l-8.844-19.329Zm3.772,10.622h-3.507l1.747-3.89Z"
                          transform="translate(-159.365 -64.233)" fill="#342664"/>
                    <path id="Path_1279" data-name="Path 1279"
                          d="M228.266,75.051l-8.428-10.407h-4.287V83.849h4.97V73.093l8.715,10.756h4V64.644h-4.971Z"
                          transform="translate(-179.96 -64.345)" fill="#342664"/>
                    <path id="Path_1280" data-name="Path 1280"
                          d="M259.758,64.407l-8.845,19.329h5.259l1.877-4.2h7.459l1.849,4.2h5.368L263.88,64.407Zm3.773,10.622h-3.508l1.748-3.89Z"
                          transform="translate(-196.801 -64.233)" fill="#342664"/>
                    <path id="Path_1281" data-name="Path 1281"
                          d="M304.013,72.172l-5.075-7.528h-4.784V83.849h4.97V73.359l4.343,6.212H304.5l4.4-6.268V83.849h4.971V64.644h-4.784Z"
                          transform="translate(-217.396 -64.345)" fill="#342664"/>
                    <path id="Path_1282" data-name="Path 1282"
                          d="M360.021,76.513h4.663v1.9a6.653,6.653,0,0,1-1.343.594,6.755,6.755,0,0,1-4.359-.085,5.054,5.054,0,0,1-1.66-1.109,4.87,4.87,0,0,1-1.067-1.678,6,6,0,0,1-.383-2.17,5.479,5.479,0,0,1,1.462-3.749,4.968,4.968,0,0,1,1.592-1.13A5.961,5.961,0,0,1,362.3,68.8a6.578,6.578,0,0,1,1.156.326,5.928,5.928,0,0,1,.995.509,10.333,10.333,0,0,1,.983.709l.668.544,3.217-3.66-.723-.587a12.42,12.42,0,0,0-1.559-1.086,10.353,10.353,0,0,0-1.726-.8,11.408,11.408,0,0,0-1.967-.5,14.311,14.311,0,0,0-2.323-.174,10.68,10.68,0,0,0-4.134.791,10.208,10.208,0,0,0-3.268,2.141,9.675,9.675,0,0,0-2.139,3.164,9.818,9.818,0,0,0-.761,3.83,10.14,10.14,0,0,0,.747,3.9,9.346,9.346,0,0,0,2.117,3.149,9.729,9.729,0,0,0,3.282,2.082,11.48,11.48,0,0,0,4.21.746,13.016,13.016,0,0,0,2.462-.228,12.462,12.462,0,0,0,2.2-.631,12.319,12.319,0,0,0,1.907-.937,14.7,14.7,0,0,0,1.6-1.114l.332-.269V72.06h-9.556Z"
                          transform="translate(-244.335 -64.074)" fill="#342664"/>
                    <path id="Path_1283" data-name="Path 1283"
                          d="M404.923,76.427a6.309,6.309,0,0,0,1.6-1.307,5.93,5.93,0,0,0,1.058-1.786,6.81,6.81,0,0,0-.166-4.941,5.607,5.607,0,0,0-1.592-2.068,7.166,7.166,0,0,0-2.44-1.257,10.636,10.636,0,0,0-3.087-.424h-9.025V83.849h4.97v-6.07h2.678l4.494,6.07h6.017l-5.26-7.041A7.333,7.333,0,0,0,404.923,76.427Zm-2.01-5.2a1.7,1.7,0,0,1-.677,1.444,3.492,3.492,0,0,1-2.192.608h-3.809V69.2h3.782a3.8,3.8,0,0,1,2.264.56A1.637,1.637,0,0,1,402.913,71.225Z"
                          transform="translate(-263.647 -64.345)" fill="#342664"/>
                    <path id="Path_1284" data-name="Path 1284"
                          d="M443.628,66.969a10.118,10.118,0,0,0-3.284-2.116,11.7,11.7,0,0,0-8.391.015A10.473,10.473,0,0,0,428.65,67a9.8,9.8,0,0,0-2.183,10.837,9.74,9.74,0,0,0,2.153,3.15,10.127,10.127,0,0,0,3.284,2.116,11.1,11.1,0,0,0,4.195.774,10.944,10.944,0,0,0,4.2-.789,10.51,10.51,0,0,0,3.3-2.137,9.765,9.765,0,0,0,.028-13.985ZM441.4,74a5.506,5.506,0,0,1-.392,2.066,5.166,5.166,0,0,1-1.077,1.677,5.031,5.031,0,0,1-1.65,1.118,5.729,5.729,0,0,1-4.28-.01,5.082,5.082,0,0,1-2.769-2.834,5.644,5.644,0,0,1,0-4.132,5.218,5.218,0,0,1,1.074-1.677,5.047,5.047,0,0,1,1.653-1.119,5.685,5.685,0,0,1,4.279.01,5.08,5.08,0,0,1,2.771,2.835A5.511,5.511,0,0,1,441.4,74Z"
                          transform="translate(-280.043 -64.078)" fill="#342664"/>
                    <path id="Path_1285" data-name="Path 1285"
                          d="M481.4,75.565a4.086,4.086,0,0,1-.966,3,3.71,3.71,0,0,1-2.75.959,3.643,3.643,0,0,1-2.764-1,4.29,4.29,0,0,1-.978-3.083v-10.8h-4.971V75.565a10.2,10.2,0,0,0,.607,3.64,7.185,7.185,0,0,0,4.562,4.385,10.88,10.88,0,0,0,3.492.533,11.091,11.091,0,0,0,3.527-.532,7.327,7.327,0,0,0,2.8-1.673,7.548,7.548,0,0,0,1.79-2.774,10.433,10.433,0,0,0,.62-3.728V64.644H481.4Z"
                          transform="translate(-300.656 -64.345)" fill="#342664"/>
                    <path id="Path_1286" data-name="Path 1286"
                          d="M522.533,68.6a6.173,6.173,0,0,0-1.615-2.153,7.115,7.115,0,0,0-2.5-1.355,10.365,10.365,0,0,0-3.151-.453h-8.087V83.849h4.97v-5.6h2.736a11.144,11.144,0,0,0,3.095-.421,8.04,8.04,0,0,0,2.615-1.277,6.279,6.279,0,0,0,1.824-2.171h0a6.5,6.5,0,0,0,.672-3A6.762,6.762,0,0,0,522.533,68.6Zm-10.384.592h2.844a3.924,3.924,0,0,1,2.346.613,1.818,1.818,0,0,1,.7,1.638,1.9,1.9,0,0,1-.728,1.59,3.619,3.619,0,0,1-2.323.661h-2.844Z"
                          transform="translate(-318.853 -64.345)" fill="#342664"/>
                </g>
            </g>
        </svg>
    );
    const SubmitButtonArrow = (
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 42 22.829">
            <g id="Component_17_33" data-name="Component 17 – 33"
               transform="translate(1 1.414)">
                <line id="Line_55" data-name="Line 55" x1="40" transform="translate(0 10)"
                      fill="none" stroke="#000000"
                      stroke-linecap="round" stroke-width="2"/>
                <path id="Path_1164" data-name="Path 1164" d="M28.965,0l10,10-10,10"
                      transform="translate(1.035)" fill="none"
                      stroke="#000000" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"/>
            </g>
        </svg>
    )
    return (
        <StyledComponents>
            <Container>
                <Row>
                    <Col md={4}>
                        <div className="addressWrapper">
                            <div className="footerLogo">
                                <Link to={'#'}>
                                    {FooterLogo}
                                </Link>
                            </div>
                            <div className="footerAddress">
                                <p>Ahmed Tower (Level-23), 28-30, Kamal Atartuk Avenue, Banani, Dhaka, Bangladesh</p>
                                <p><a href="tel:+8802222274358">+8802222274358</a></p>
                                <p><a href="mailto:info@fanamgroup.com">info@fanamgroup.com</a></p>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="footerMenu">
                            <SubTitle
                                text={ReactHtmlParser('Important links')}
                                color={'#000000'}
                                fontSize={'24'} fontWeight={'300'}
                                lineHeight={'24'}
                                margin={'0 0 20px 0'}
                            />
                            <ul>
                                <li>
                                    <Link to={'/'}>Home</Link>
                                </li>
                                <li>
                                    <Link to={'/about'}>About Us</Link>
                                </li>
                                <li>
                                    <Link to={'/concern'}>Concern</Link>
                                </li>
                                <li>
                                    <Link to={'/projects/ongoing'}>Projects</Link>
                                </li>
                                <li>
                                    <Link to={'/landowner'}>Landowner</Link>
                                </li>
                                <li>
                                    <Link to={'/news'}>News & Events</Link>
                                </li>
                                <li>
                                    <Link to={'/career'}>Career</Link>
                                </li>
                                <li>
                                    <Link to={'/contact'}>Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="footerSubscription">
                            <SubTitle
                                text={ReactHtmlParser('Let\'s stay in touch.')}
                                color={'#000000'}
                                fontSize={'24'} fontWeight={'300'}
                                lineHeight={'24'}
                                margin={'0 0 20px 0'}
                            />
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="Enter email"/>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    {SubmitButtonArrow}
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="gap100"></div>
            <Container>
                <Row className="copyrightRow">
                    <Col md={6}>
                        <div className="copyright">
                            <p>© {new Date().getFullYear()} Fanam Group | <a href="https://dcastalia.com" target="_blank">Designed & Developed by Dcastalia</a></p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/Fanam-Group-105781418412935" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                            <path id="Icon_ionic-logo-facebook" data-name="Icon ionic-logo-facebook" d="M32.844,4.5H6.156A1.657,1.657,0,0,0,4.5,6.156V32.844A1.657,1.657,0,0,0,6.156,34.5H19.5V22.625H15.914V18.25H19.5V15.016c0-3.875,2.688-5.984,6.148-5.984,1.656,0,3.438.125,3.852.18v4.047H26.742c-1.883,0-2.242.891-2.242,2.2V18.25h4.484L28.4,22.625H24.5V34.5h8.344A1.657,1.657,0,0,0,34.5,32.844V6.156A1.657,1.657,0,0,0,32.844,4.5Z" transform="translate(-4.5 -4.5)" fill="#4267b2"/>
                                        </svg>
                                    </a>
                                </li>
                                {/*<li>*/}
                                {/*    <a href="#">*/}
                                {/*        */}

                                {/*    </a>*/}
                                {/*</li>*/}
                                <li>
                                    <a href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                            <path id="Icon_simple-linkedin" data-name="Icon simple-linkedin" d="M25.559,25.565H21.116V18.6c0-1.66-.034-3.8-2.315-3.8-2.316,0-2.67,1.806-2.67,3.674v7.084H11.689V11.25h4.267V13.2h.057a4.682,4.682,0,0,1,4.213-2.313c4.5,0,5.334,2.963,5.334,6.819v7.858ZM6.671,9.291A2.58,2.58,0,1,1,9.251,6.71,2.577,2.577,0,0,1,6.671,9.291ZM8.9,25.565H4.444V11.25H8.9ZM27.781,0H2.214A2.187,2.187,0,0,0,0,2.161V27.839A2.186,2.186,0,0,0,2.214,30H27.778A2.195,2.195,0,0,0,30,27.839V2.161A2.2,2.2,0,0,0,27.778,0Z" fill="#0077b5"/>
                                        </svg>

                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20.967" viewBox="0 0 30 20.967">
                                            <path id="Icon_simple-youtube" data-name="Icon simple-youtube" d="M29.381,8.661A3.76,3.76,0,0,0,26.77,6.05c-2.338-.626-11.749-.626-11.749-.626S5.633,5.411,3.271,6.05A3.76,3.76,0,0,0,.66,8.661,39.073,39.073,0,0,0,.007,15.92,39.073,39.073,0,0,0,.66,23.152a3.76,3.76,0,0,0,2.611,2.611c2.336.628,11.749.628,11.749.628s9.386,0,11.749-.628a3.76,3.76,0,0,0,2.611-2.611,39.073,39.073,0,0,0,.625-7.231,39.073,39.073,0,0,0-.625-7.259ZM12.017,20.411V11.416l7.833,4.5Z" transform="translate(-0.007 -5.424)" fill="red"/>
                                        </svg>

                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponents>
    );
};

const StyledComponents = styled.section`
  border-top: 5px solid #F9C602;
  padding-top: 100px;
  //margin-top: 100px;

  .footerLogo {
    margin-bottom: 50px;
  }

  .addressWrapper {
    padding-right: 150px;

    p {
      font-family: "Poppins", 'DIN Next LT Pro', SansSerif;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      margin-bottom: 25px;

      a {
        font-family: "Poppins", 'DIN Next LT Pro', SansSerif;
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
      }
    }
  }

  .footerMenu, .footerSubscription {
    .sub-title {
      text-transform: initial;
    }

    ul {
      li {
        a {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          font-family: "Poppins", 'Arial', SansSerif;
        }

        padding-bottom: 15px;
      }
    }
  }

  .footerSubscription {
    form {
      position: relative;

      .form-control {
        border: 1px solid #000000;
        border-radius: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        padding: 20px 25px;
        height: auto;
        color: #000000;

        &::placeholder {
          color: #000000;
          font-size: 14px;
          line-height: 24px;
        }
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px 25px;
        height: auto;
        background-color: transparent;
        border: none;

        svg {
          width: 25px;
        }
      }
    }
  }

  .gap100 {
    padding-bottom: 100px;
  }
  .copyright{
    p{
      font-family: "Poppins", 'DIN Next LT Pro', SansSerif;
      font-size: 14px;
      line-height: 21px;
      a{
        font-family: "Poppins", 'DIN Next LT Pro', SansSerif;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .social{
    ul{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-items: center;
      li{
        &:not(:first-child){
          margin-left: 10px;
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }


  @media (max-width: 767px) {
    .footerLogo{
      svg{
        height: 50px;
      }
    }
    .gap100{
      display: none;
    }
    .copyrightRow{
      flex-direction: column-reverse;
    }
    .social{
      margin-top: 30px;
      margin-bottom: 50px;
      ul{
        justify-content: flex-start;
      }
    }
  }
`;

export default Footer1;