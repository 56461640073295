import React, {Component, useEffect, useState} from 'react';
import Slick from "react-slick";
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import imgs from '../../../assets/images/dynamic/services.jpg'
import {hover} from "../../../globalStyleVars";
import VisibilitySensor from "react-visibility-sensor";
import htmlParser from 'react-html-parser';


const NewsDescription = ({data}) => {
    return (
        <StyledDescription className='news-details pt-120 pb-120'>
            <Container>
                <Row>
                    <Col md={8}>
                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                            {({isVisible}) =>
                                <h2 className={`${isVisible ? 'anim-active' : ''} fade-right`}>{data.page_data ? data.page_data.short_desc.split("").map(function (char, index) {
                                    return <span aria-hidden="true" key={index}>{char}</span>;
                                }) : ''}</h2>
                            }
                        </VisibilitySensor>
                        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
                            {({isVisible}) =>
                                <div  className={`${isVisible ? 'anim-active' : ''} fade-up`}>
                                    {data.page_data ? htmlParser(data.page_data.description) : ''}
                                </div>
                            }
                        </VisibilitySensor>

                    </Col>
                </Row>
            </Container>

        </StyledDescription>
    )
};
const StyledDescription = styled.section`
    h2{
      margin: 0 0 30px 0;
      font-weight: 300;
      line-height: 42px;
    }
    p{
      font-family: "Poppins", SansSerif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 30px;
      &:nth-last-of-type(1){
        margin-bottom: 0; 
      }
    }
  img{
    width: 100% !important;
    height: auto !important;
  }
`;

export default NewsDescription;