import React from 'react';
import styled from 'styled-components';
import DescriptionWithImage from '../../../components/DescriptionWithImage';
import ReactHtmlParser from "react-html-parser";
import {ApiParams} from "../../../constants/apiParams";
import {connect} from 'react-redux';
import {fetchHomeAboutUsData} from "../../../redux/actions/home";
import Title from "../../../components/Title";
import WhoWeAreImg from "../../../assets/images/dynamic/home-who-we-are.jpg"


const AboutUs = (data) => {
    let aboutData = data?.data?.page_data ? data.data.page_data : '';
    return (
        <StyledAbout>

            <DescriptionWithImage className="about-us" ImageUrl={WhoWeAreImg} Links={'about'}
                                  TitleTextComponent={
                                      <Title fontSize={60} fontWeight={300} fontFamily={"'DIN Next LT Pro', sans-serif"}
                                             textTransform={'uppercase'} color={'#000000'}
                                             lineHeight={60} letterSpacing={0.25} margin={'0 0 30px 0'}>
                                          {ReactHtmlParser(aboutData.sub_title)}
                                      </Title>
                                  }
                                  DescText={ReactHtmlParser(aboutData.short_desc)}/>
        </StyledAbout>
    );
};


const StyledAbout = styled.div`

  h3 {
    margin-bottom: 20px;
  }

  .desc {
    margin-top: 20px;

    p {
      text-align: justify;
    }
  }

  .dc-btn {
    margin: 40px auto 0;
  }
`;


export default AboutUs;




