export const SERVICES = {
  FETCH_SERVICES_PAGE: {
    MAIN: 'FETCH_SERVICES_PAGE',
    SUCCESS: 'FETCH_SERVICES_PAGE_SUCCESS',
    FAILURE: 'FETCH_SERVICES_PAGE_FAILURE'
  },
  FETCH_SERVICES_LIST: {
    MAIN: 'FETCH_SERVICES_LIST',
    SUCCESS: 'FETCH_SERVICES_LIST_SUCCESS',
    FAILURE: 'FETCH_SERVICES_LIST_FAILURE'
  },
  FETCH_SERVICES_CHILD: {
    MAIN: 'FETCH_SERVICES_CHILD',
    SUCCESS: 'FETCH_SERVICES_CHILD_SUCCESS',
    FAILURE: 'FETCH_SERVICES_CHILD_FAILURE'
  },

  FETCH_SERVICES_ONE: {
    MAIN: 'FETCH_SERVICES_ONE',
    SUCCESS: 'FETCH_SERVICES_ONE_SUCCESS',
    FAILURE: 'FETCH_SERVICES_ONE_FAILURE'
  },

  FETCH_SERVICES_THREE: {
    MAIN: 'FETCH_SERVICES_THREE',
    SUCCESS: 'FETCH_SERVICES_THREE_SUCCESS',
    FAILURE: 'FETCH_SERVICES_THREE_FAILURE'
  },

  FETCH_SERVICES_FOUR: {
    MAIN: 'FETCH_SERVICES_FOUR',
    SUCCESS: 'FETCH_SERVICES_FOUR_SUCCESS',
    FAILURE: 'FETCH_SERVICES_FOUR_FAILURE'
  }
};
